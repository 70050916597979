export function ContrasenaField({ id, label, value, setValue, isError }: {
    id: string, label: string,
    value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, isError?:boolean
}) {
    return (
        <div className='textFieldBox'>
            <label htmlFor={id}>{label}</label>
            <input id={id} type={'password'} className={'passwordField ' + (isError ? 'errorField' : '')} value={value ? value : ''} disabled={setValue ? false : true}
                onChange={setValue ? (e) => setValue(() => e.target.value) : undefined} />
        </div>
    )
}
import React from 'react';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';
import { useAuth } from '../../hooks/useAuth';
import { RolEnum, SizeEnum } from '../../types/types';
import CSS from 'csstype';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export function TextField({ id, label, value, setValue, setFilterPropValue, size, isUpper, formatError, helpLink, isPass, isError}: {
    id: string, label: string,
    value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, size?:SizeEnum, isUpper?:boolean, 
    formatError?:boolean, helpLink?:string, isPass?:boolean, isError?:boolean}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const { rol } = useAuth()

    const getSize = ()=> {
        switch (size) {
            case SizeEnum.VB:
                return '100%'                
            case SizeEnum.B:
                return '70%'                
            case SizeEnum.M:
                return '40%'                
            case SizeEnum.S:
                return '20%'                
            default:
                return '70%'                
        }
    }


    return (
        <div className={'textFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={'fieldAndBut' + (formatError ? ' fieldAndButError' : '')}>
                <input style={{width: getSize()}} id={id} type={isPass ? 'password' : 'text'} value={value ? value : ''} 
                    disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className={(isError ? ' errorField' : '')}
                    onChange={setValue ? (e) => setValue(() => isUpper? e.target.value.toUpperCase() : e.target.value ) : setFilterPropValue ?
                        (e) => setFilterPropValue(e.target.value, id) : undefined} />
                {formatError? <div className='formatErrorMsg'>{'Formato erróneo'}</div> : undefined}
                {helpLink ? <a className='helpLink'  href={helpLink} target={'_blank'} rel={'noreferrer'}><AiOutlineQuestionCircle/></a>:<></>}
            </div>
        </div>
    )
}


import React, { useEffect, useState } from 'react';
import { ConvocatoriaCard } from '../components/molecule/ConvocatoriaCard';
import { Paginacion } from '../components/molecule/Paginacion';
import { useApi } from '../hooks/useApi';
import { ConvocatoriaConSolicitud, Usuario } from '../types/entities';
import {reloadConvocatorias, userInfo} from '../atoms/mainAtoms'
import { useRecoilState, useSetRecoilState } from 'recoil';
import {conf} from '../conf'
import { useAuth } from '../hooks/useAuth';

export function ConvocatoriasGestor ({}:{}) {

    const [convocatoriasList, setConvocatoriasList] = useState<ConvocatoriaConSolicitud[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const {getConvocatoriasUsuario, getUserInfo} = useApi()
    const setUserInfo = useSetRecoilState<Usuario | undefined>(userInfo)
    const [convocatoriasReload, setConvocatoriasReload] = useRecoilState<boolean>(reloadConvocatorias)
    const {id} = useAuth()


    useEffect(() => {
        onGetConvocatorias()
    }, [page, convocatoriasReload])
 
    const onGetConvocatorias = async()=> {
        if (id) {
            const convocatoriasList = await getConvocatoriasUsuario(page)
            setTotalPages(()=>convocatoriasList.paginasTotales)
            setConvocatoriasList(()=>convocatoriasList.content)    
        }
    }    

    return (
        <>
            <div id='convocatoriaList'>
                {convocatoriasList.map((convocatoria)=>
                    <ConvocatoriaCard convocatoriaConSol={convocatoria}/>
                )}
            </div>
            <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'4%'}/> 
        </>           
)
}

import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { csvModal, CsvModalProps, isLoading, mailModal, MailModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineClose } from 'react-icons/ai'
import { FaFileDownload } from 'react-icons/fa'
import { useApi } from '../../hooks/useApi';
import { TextAreaField } from '../atom/TextAreaField';
import { SizeEnum } from '../../types/types';
import { CSVLink } from 'react-csv';
import { SolicitudCsvInfo } from '../../types/entities';
import { CsvField } from '../atom/CsvField';
import { stylesVars } from '../../utils/stylesVars';
import { utilMethods } from '../../utils/utilMethods';


export function CsvModal() {

    const defaultFields = ['id', 'dni', 'dniAnonimizado', 'violencia_genero']

    const [modalInfo, setModalInfo] = useRecoilState<CsvModalProps>(csvModal)

    const [csvInfo, setCsvInfo] = useState<SolicitudCsvInfo[]>([])
    const [csvFields, setCsvFields] = useState<string[]>(defaultFields)
    const [csvHeaders, setCsvHeaders] = useState<any[]>([])
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [goDownload, setGoDownload] = useState<boolean>(false)
    const csvLink = useRef<any>();


    const { getRemesaCsv } = useApi()


    useEffect(() => {
        if (goDownload && csvLink?.current?.link) {
            csvLink.current.link.click()
            onClear()
            setModalInfo(() => { return { visible: false } })
        }
    }, [goDownload])

    const formatCsvInfo = (solicitudesCsvInfo: SolicitudCsvInfo[]): any[] => {
        let formattedCsvInfo: any[] = []
        solicitudesCsvInfo.forEach((solicitudCsvInfo) => {
            const formattedCsvInfoElement: any = {}
            csvFields.forEach((csvField) => { formattedCsvInfoElement[csvField] = solicitudCsvInfo[csvField as keyof SolicitudCsvInfo] })
            formattedCsvInfo.push(formattedCsvInfoElement)
        })
        return formattedCsvInfo
    }


    const getHeaders = () => {
        let headersResult: any = []
        csvFields.map((field) => headersResult.push({ label: utilMethods.getCsvFieldLabel(field), key: field }))
        return headersResult
    }

    const onDownloadCsv = async () => {
        setIsLoadingState(() => true)
        if (modalInfo.remesaId) {
            try {
                const solicitudesCsvInfo = await getRemesaCsv(modalInfo.remesaId, csvFields)
                setCsvHeaders(() => getHeaders())
                setCsvInfo(() => formatCsvInfo(solicitudesCsvInfo))
            } catch (e) {
            }
            finally {
                setIsLoadingState(() => false)
                setGoDownload(() => true)
            }
        }
    }


    const onClear = () => {
        setCsvFields(() => defaultFields)
        setCsvInfo(() => [])
        setCsvHeaders(() => [])
        setGoDownload(() => false)
    }


    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Generar CSV'}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear(); setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='csvFieldsBox'>
                        <CsvField id='centroEstudios' label='Centro estudios' fieldList={csvFields} setFieldList={setCsvFields} />
                        <CsvField id='tipoEstudios' label='Tipo estudios' fieldList={csvFields} setFieldList={setCsvFields} />
                        <CsvField id='estado' label='Estado' fieldList={csvFields} setFieldList={setCsvFields} />
                        <CsvField id='cuantia' label='Cuantia' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='curso' label='Curso' fieldList={csvFields} setFieldList={setCsvFields} />
                        <CsvField id='nota' label='Nota' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='creditosMatriculados' label='Creditos matriculados' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='creditosSuperados' label='Creditos superados' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='emancipado' label='Emancipado' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='familiaNumerosa' label='Familia numerosa' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='violenciaGenero' label='Victima de violencia de genero' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='orfandadSimple' label='Orfandad simple' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='orfandadAbsoluta' label='Orfandad absoluta' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='numeroCial' label='Numero CIAL' fieldList={csvFields} setFieldList={setCsvFields}/>
                        <CsvField id='puntuacion' label='Puntuacion' fieldList={csvFields} setFieldList={setCsvFields}/>
                    </div>
                    <div id='downloadButModal'>
                        <button onClick={onDownloadCsv}>
                            <FaFileDownload />{'Descargar'}
                        </button>
                        <CSVLink data={csvInfo} headers={csvHeaders} filename={`remesa_${modalInfo.remesaId}`} ref={csvLink} />
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React, { useState } from 'react';
import Select from 'react-select'

import { SelectOption } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { consultaModo } from '../../atoms/mainAtoms';
import { useRecoilState } from 'recoil';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export function SiNoField({ id, label, value, setValue, disabled, tooltipText }: {
    id: string, label: string, value: boolean,
    setValue?: React.Dispatch<React.SetStateAction<boolean>>, disabled?: boolean, tooltipText?: string
}) {

    const options: SelectOption[] = [{ label: 'Sí', value: true }, { label: 'No', value: false }]
    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const [showToolTip, setShowToolTip] = useState<boolean>(false)


    return (
        <>
            <div className={'selectFieldBox'}>
                <label>{label}</label>
                <div className='fieldAndBut'>
                    <select style={{ width: '30%' }} disabled={modoConsulta || !setValue ? true : false} className='selectField' value={utilMethods.getSiNo(value)}
                        onChange={setValue ? (e) => setValue(utilMethods.getSiNoBool(e?.target.value)) : undefined}>
                        <option selected={true} value={'No'}>{'No'}</option>
                        <option value={'Sí'}>{'Si'}</option>
                    </select>
                    {tooltipText ? <span className='helpLink'>
                                        <AiOutlineQuestionCircle style={{cursor:'pointer'}} onMouseEnter={()=>setShowToolTip(()=>true)} 
                                            onMouseLeave={()=>setShowToolTip(()=>false)}/>

                                    </span> : undefined}
                </div>
            </div>
            {showToolTip ?
                <div className='fieldTooltip'>{tooltipText}</div>
                :
                undefined
            }
        </>
    )
}
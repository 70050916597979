import { RemesaList } from '../components/organism/RemesaList';
import { CsvModal } from '../components/organism/CsvModal';
import { useAuth } from '../hooks/useAuth';
import { RolEnum } from '../types/types';

export function RemesasScreen() {

    const { rol } = useAuth()

    return (
        (rol && rol.nombre === RolEnum.ADMIN) ?
            <div id='remesasBox'>
                <div id='remesaHeader'>
                    <h1>
                        {'Remesas'}
                    </h1>
                </div>
                <div id='remesasContent'>
                    <RemesaList esLocal={true} esConcedida={true} />
                    <RemesaList esLocal={true} esConcedida={false} />
                    <RemesaList esLocal={false} esConcedida={true} />
                    <RemesaList esLocal={false} esConcedida={false} />
                </div>
                <CsvModal />
            </div>
            :
            <></>
    )
}
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isLoading, reloadUserAdmin, usuarioModal, UsuarioModalProps } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Usuario } from '../../types/entities';
import './../../index.css';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';


export function UsuarioAdminCard({ usuario }: { usuario: Usuario }) {
    const {updateUsuario, deleteUsuario } = useApi()
    const [reloadUserAdminReload, setReloadUserAdminReload] = useRecoilState<boolean>(reloadUserAdmin)
    const [usuarioModalInfo, setUsuarioModalInfo] = useRecoilState<UsuarioModalProps>(usuarioModal)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const onReloadUsuarios = () => {
        setReloadUserAdminReload((old) => !old)
    }

    const onEditUsuario = () => {
        setUsuarioModalInfo(() => { return { visible: true, usuario: usuario } })
    }

    const onDeleteUsuario = async() => {
        setIsLoadingState(() => true)
        try {
            if (usuario.id) {
                await deleteUsuario(usuario.id)
            }
        } catch { }
        finally {
            setIsLoadingState(() => false)
            onReloadUsuarios()
        }
    }

    return (
        <div className='convoAdminCard'>
            <div className='convoAdminCard_beca'>{usuario?.nombre}</div>
            <div className='convoAdminCard_nombre'>{usuario?.nombre} {usuario?.apellido1} {usuario?.apellido2}</div>
            <div className='convoAdminCard_buttonBox'>
                <button className='convoAdminCard_editBut' onClick={onEditUsuario}><MdOutlineModeEditOutline /></button>
                <button className='convoAdminCard_deleteBut' onClick={onDeleteUsuario}><MdDeleteOutline /></button>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { SelectOption, EstadoCivilEnum, TipoDocumentoEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';

export function TipoDocumentoField({ id, label, value, setValue, setFilterPropValue, isError}: { id: string, label: string, value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, 
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, isError?:boolean }) {


    const getOptionFromValue = (value: string): SelectOption => {
        return { value: value, label: value }
    }
    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    const getOptions = () => {
        const options: SelectOption[] = []
        Object.keys(TipoDocumentoEnum).forEach((option) => {
            options.push(getOptionFromValue(option))
        })
        return options
    }

    const options = getOptions()


    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{width:'30%'}} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className={`selectField ${isError ? 'errorField' : ''}`} value= {value} 
                    onChange={setValue ? (e) => setValue(e?.target.value) : setFilterPropValue ? (e) => setFilterPropValue(e?.target.value, id) : undefined} >
                    {options.map((option)=>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue(undefined, id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}
export interface Page<T> {
  pagina: number;
  paginasTotales: number;
  content: T[];
}

export enum SolicitudStep {
    PERSONALES, ACADEMICOS, ECONOMICOS, DECLARACIONES, AUTORIZACIONES, DOCUMENTOS, DEDUCCIONES,  
}

export enum NotificacionType {
  CONCESION = 1,
  DENEGACION,
  FIRMA,
  REQUERIMIENTO
}

export enum EstadoCivilEnum {
  SOLTERO = 'soltero',
  CASADO = "casado",
  DIVORCIADO = 'divorciado',
  VIUDO = 'viudo',
}

export enum ParentescoEnum {
  PADRE = "padre",
  HERMANO = "hermano",
  ASCENDIENTE = "ascendiente",
  HIJO = "hijo",
  PAREJA  = "pareja"
}

export enum FamiliaNumerosaEnum {
  NO = "no",
  GENERAL = "general",
  ESPECIAL = "especial",
}


export enum TipoEstudiosEnum {
  ESO = "eso",
  BACH = "bach",
  FP = "fp",
  GRADO = "grado",
  POSGRADO = "posgrado",
  ACCESO_FP = "acceso fp",
  ACCESO_UNI = "acceso uni"
}


export enum TipoDocumentoEnum {
  DNI = "DNI",
  NIE = "NIE"
}

export interface SelectOption {
  label: string | undefined;
  value: any;
}

export enum EstadoSolicitud {
  BORRADOR = "borrador",
  ENVIADA = "enviada",
  SUBSANACION = "subsanacion",
  COMPROBADA = "comprobada",
  CORRECTA = "correcta",
  ERRONEA = "erronea",
  PENDIENTE_ENVIO = "pendiente_envio",
  APROBADA = "aprobada",
  PENDIENTE_DESCARTAR="pendiente_descartar",
  DESCARTADA = "descartada",
  RENUNCIADA = "renunciada",
}

export enum RolEnum {
  USUARIO = "usuario",
  ADMIN = "admin",
  GESTOR = "gestor",
}

export enum EstadoRemesa {
  ABIERTA = "abierta",
  CERRADA = "cerrada",
}

export enum EstadoConvocatoria {
  ABIERTA = "abierta",
  CERRADA = "cerrada",
}


export interface EstadoStyle {
  icono: any;
  name: string;
  mensaje: string;
}

export enum PresencialidadEnum {
  PRESENCIAL = "presencial",
  SEMIPRESENCIAL = "semipresencial",
  DISTANCIA = "distancia",
}

export enum SizeEnum {
  XS, S, M, B, VB
}

export enum TipoCentroEstudiosEnum {
  UNIVERSIDAD = "uni",
  INSTITUTO = "insti",
}
import { useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';

import { SiNoField } from '../atom/SiNoField'
import { DiscapacidadField } from '../atom/DiscapacidadField';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Solicitud } from '../../types/entities';
import { consultaModo, familiarModal, FamiliarModalProps, isLoading, solicitudInfo } from '../../atoms/mainAtoms';
import { FamiliarModal } from './FamiliarModal';
import { FamiliarList } from '../molecule/FamiliarList';
import { useAuth } from '../../hooks/useAuth';
import { RolEnum, TipoEstudiosEnum } from '../../types/types';
import { useApi } from '../../hooks/useApi';
import { utilMethods } from '../../utils/utilMethods';
import { FamiliaNumerosaField } from '../atom/FamiliaNumerosaField';
import { NumericField } from '../atom/NumericField';
import { CheckBoxField } from '../atom/CheckBoxField';
import { TextField } from '../atom/TextField';

export function SolicitudStepEconomicos() {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)

    const [emancipado, setEmancipado] = useState<boolean>(solicitud?.emancipado ? solicitud?.emancipado : false);
    const [solicitanteDiscapacidad, setSolicitanteDiscapacidad] = useState<number>(solicitud?.solicitanteDiscapacidad ? solicitud?.solicitanteDiscapacidad : 0);
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [orfandadSimple, setOrfandadSimple] = useState<boolean>(solicitud?.orfandadSimple ? solicitud?.orfandadSimple : false);
    const [orfandadAbsoluta, setOrfandadAbsoluta] = useState<boolean>(solicitud?.orfandadAbsoluta ? solicitud.orfandadAbsoluta : false);
    const [familiaNumerosa, setFamiliaNumerosa] = useState<string | undefined>(solicitud?.familiaNumerosa);
    const [padresDivorciados50, setPadresDivorciados50] = useState<boolean>(solicitud?.padresDivorciados50 ? solicitud?.padresDivorciados50 : false);
    const [isHospitalizado, setIsHospitalizado] = useState<boolean>(solicitud?.isHospitalizado ? solicitud?.isHospitalizado : false);
    const [isFederado, setIsFederado] = useState<boolean>(solicitud?.isFederado ? solicitud?.isFederado : false);
    const [renta, setRenta] = useState<number | undefined>(solicitud?.renta)
    const [altaSegSocial, setAltaSegSocial] = useState<boolean>(solicitud?.altaSegSocial ? solicitud?.altaSegSocial : false)
    const [numSegSocial, setNumSegSocial] = useState<string | undefined>(solicitud?.numSegSocial)
    const [carneConducir, setCarneConducir] = useState<boolean>(solicitud?.carneConducir ? solicitud?.carneConducir : false)
    const modoConsulta = useRecoilValue<boolean>(consultaModo)
    const isPresent = utilMethods.isPresent

    const { rol } = useAuth()

    const { updateSolicitud } = useApi()

    const onSave = async () => {
        if (solicitud) {
            setIsLoadingState(() => true)
            const newSolicitud: Solicitud = { ...solicitud }
            newSolicitud.orfandadSimple = orfandadSimple
            newSolicitud.orfandadAbsoluta = orfandadAbsoluta
            newSolicitud.familiaNumerosa = familiaNumerosa
            newSolicitud.solicitanteDiscapacidad = solicitanteDiscapacidad
            newSolicitud.emancipado = emancipado
            newSolicitud.padresDivorciados50 = padresDivorciados50
            newSolicitud.isHospitalizado = isHospitalizado
            newSolicitud.isFederado = isFederado
            newSolicitud.renta = renta;
            newSolicitud.altaSegSocial = altaSegSocial
            newSolicitud.numSegSocial = numSegSocial
            newSolicitud.carneConducir = carneConducir
            try {
                const updatedSolicitud = await updateSolicitud(newSolicitud)
                setSolicitud(() => updatedSolicitud)
            } catch { }
            finally {
                setIsLoadingState(() => false)
            }
        }
    }

    return (
        solicitud !== undefined ?
            <div id='stepBox'>
                <div id='stepHeader'>
                    <h1>{'Datos económicos'}</h1>
                </div>
                <div className='formBox'>
                    {isPresent('emancipado', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='emancipado' label='Solicitante económicamente independiente' value={emancipado ? emancipado : false} setValue={setEmancipado} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('orfandadSimple', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='orfandadSimple' label='Orfandad simple' value={orfandadSimple ? orfandadSimple : false} setValue={setOrfandadSimple} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('orfandadAbsoluta', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='orfandadAbsoluta' label='Orfandad absoluta' value={orfandadAbsoluta ? orfandadAbsoluta : false} setValue={setOrfandadAbsoluta} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('familiaNumerosa', solicitud) ?
                        <div className='formElement'>
                            <FamiliaNumerosaField id={'familiaNumerosa'} label={'Familia numerosa'} value={familiaNumerosa} setValue={setFamiliaNumerosa} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('solicitanteDiscapacidad', solicitud) ?
                        <div className='formElement'>
                            <DiscapacidadField id='solicitanteDiscapacidad' label='Grado de discapacidad del solicitante' value={solicitanteDiscapacidad} setValue={setSolicitanteDiscapacidad} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('padresDivorciados50', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='padresDivorciados50' label='Mis padres están divorciados y convivo con cada uno por tiempos análogos' value={padresDivorciados50 ? padresDivorciados50 : false}
                                setValue={setPadresDivorciados50} tooltipText={'En el caso de convivencia por tiempos análogos con ambos progenitores, se tomará el 50% de la renta de los dos' + 
                                ' y ambos tendrán la condición de miembro computable de la unidad familiar'} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('isHospitalizado', solicitud) && (solicitud.tipoEstudios === TipoEstudiosEnum.ESO || solicitud.tipoEstudios === TipoEstudiosEnum.BACH) ?
                        <div className='formElement'>
                            <SiNoField id='isHospitalizado' label='Me encuentro hospitalizado o recibimiento tratamiento sanitario' value={isHospitalizado ? isHospitalizado : false}
                                setValue={setIsHospitalizado} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('isFederado', solicitud) && (solicitud.tipoEstudios === TipoEstudiosEnum.ESO || solicitud.tipoEstudios === TipoEstudiosEnum.BACH) ?
                        <div className='formElement'>
                            <SiNoField id='isFederado' label='Estoy federado en un club deportivo con sede fuera de la isla' value={isFederado ? isFederado : false}
                                setValue={setIsFederado} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('altaSegSocial', solicitud) ?
                        <div className='formElement'>
                            <CheckBoxField id="altaSegSocial" label="¿Ha estado dado de alta en la seguridad social alguna vez?"
                                value={altaSegSocial} setValue={setAltaSegSocial}/>
                        </div>
                        : undefined
                    }
                    {isPresent('numSegSocial', solicitud) ?
                        <div className='formElement'>
                            <TextField id='numSegSocial' label='NAF- Número de afiliación a la Seguridad Social' 
                                value={numSegSocial} setValue={setNumSegSocial} />
                        </div>
                        : undefined
                    }
                    {isPresent('carneConducir', solicitud) ?
                        <div className='formElement'>
                            <CheckBoxField id="carneConducir" label="¿Tienes carné de conducir?"
                                value={carneConducir} setValue={setCarneConducir} />
                        </div>
                        : undefined
                    }


                    {rol?.nombre !== RolEnum.USUARIO ?
                        <div className='formElement'>
                            <NumericField id='renta' label='Renta' value={renta}
                                setValue={setRenta} />
                        </div>
                        :
                        undefined
                    }

                </div>
                <div className='formBoxFamiliar'>
                    <div className='leftForm'>
                        <p>{'Se deben añadir los siguientes familiares:'}</p>
                        <ul>
                            <li>{'Progenitores/tutor legal'}</li>
                            <li>{'Hermanos solteros menores de 25 años que convivan en el domicilio'}</li>
                            <li>{'Hermanos mayores de 25 años que esten dados de alta como demandantes de empleo y no hayan cobrado la prestación el mes anterior'}</li>
                            <li>{'Hermanos mayores de 25 años con alguna discapacidad física, psíquica o sensorial'}</li>
                            <li>{'Ascendientes de los padres del solicitante, siempre que estos tengan la tutela de abuelos'}</li>
                            <li>{'En caso de padres divorciados o separados, el nuevo cónyuge en caso de matrimonio/y los hijos en común si existieran'}</li>
                            <li>{'Si el solicitante constituye una unidad familiar, el cónyuge y los hijos si existieran'}</li>
                        </ul>
                    </div>
                    <div className='rightForm'>
                        <FamiliarList />
                    </div>
                </div>
                {!modoConsulta ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}
                <FamiliarModal />
            </div>
            :
            <div id='stepBox'></div>
    )
}

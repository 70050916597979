import { LegacyRef, useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Solicitud } from '../../types/entities';
import { TextField } from '../atom/TextField';
import { consultaModo, isLoading, reloadFiles, solicitudInfo } from '../../atoms/mainAtoms'
import { TipoEstudiosField } from '../atom/TipoEstudiosField';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { RolEnum, SizeEnum, TipoCentroEstudiosEnum, TipoEstudiosEnum } from '../../types/types';
import { CentroEstudiosField } from '../atom/CentroEstudiosField';
import { MdErrorOutline } from 'react-icons/md';
import { NumericField } from '../atom/NumericField';
import { TextAreaField } from '../atom/TextAreaField';
import { SiNoField } from '../atom/SiNoField';
import { utilMethods } from '../../utils/utilMethods';
import { PresencialidadField } from '../atom/PresencialidadField';

export function SolicitudStepAcademicos({ }: {}) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)

    const [centroEstudios, setCentroEstudios] = useState<string | undefined>(undefined)
    const [otroCentroEstudios, setOtroCentroEstudios] = useState<string | undefined>(undefined)
    const [estudiosCanarias, setEstudiosCanarias] = useState<boolean>(solicitud?.estudiosCanarias ? solicitud.estudiosCanarias : false)
    const [tipoEstudios, setTipoEstudios] = useState<string | undefined>(solicitud?.tipoEstudios)
    const [estudios, setEstudios] = useState<string | undefined>(solicitud?.estudios)
    const [presencialidad, setPresencialidad] = useState<string | undefined>(solicitud?.presencialidad)
    const [numeroCial, setNumeroCial] = useState<string | undefined>(solicitud?.numeroCial)
    const [nota, setNota] = useState<number | undefined>(solicitud?.nota)
    const [notaFct, setNotaFct] = useState<number | undefined>(solicitud?.notaFct)
    const [cursoSuperado, setCursoSuperado] = useState<boolean>(solicitud?.cursoSuperado ? solicitud.cursoSuperado : false)
    const [primerAno, setPrimerAno] = useState<boolean>(solicitud?.primerAno ? solicitud.primerAno : false)
    const [creditosMatriculados, setCreditosMatriculados] = useState<number | undefined>(solicitud?.creditosMatriculados)
    const [creditosTotales, setCreditosTotales] = useState<number | undefined>(solicitud?.creditosTotales)
    const [creditosMatriculadosPasado, setCreditosMatriculadosPasado] = useState<number | undefined>(solicitud?.creditosMatriculadosPasado)
    const [creditosSuperados, setCreditosSuperados] = useState<number | undefined>(solicitud?.creditosSuperados)
    const [capacidades, setCapacidades] = useState<string | undefined>(solicitud?.capacidades)
    const [formacionComplementaria, setFormacionComplementaria] = useState<string | undefined>(solicitud?.formacionComplementaria)
    const [preferenciaPracticas, setPreferenciaPracticas] = useState<string | undefined>(solicitud?.preferenciaPracticas)
    const [puedeFueraMunicipio, setPuedeFueraMunicipio] = useState<boolean>(solicitud?.puedeFueraMunicipio ? solicitud.puedeFueraMunicipio : false)
    const [puedeTeletrabajo, setPuedeTeletrabajo] = useState<boolean>(solicitud?.puedeTeletrabajo ? solicitud.puedeTeletrabajo : false)
    const [isFpNoImpartido, setIsFpNoImpartido] = useState<boolean>(solicitud?.isFpNoImpartido ? solicitud.isFpNoImpartido : false)


    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [filesReload, setFilesReload] = useRecoilState<boolean>(reloadFiles)

    const [isNumeroCialError, setIsNumeroCialError] = useState<boolean>(false)

    const [warning, setWarning] = useState<string | undefined>(undefined)


    const modoConsulta = useRecoilValue<boolean>(consultaModo)
    const { rol } = useAuth()
    const isPresent = utilMethods.isPresent


    const { updateSolicitud, getCentrosEstudiosByTipo } = useApi()

    const checkIsOtroCentro = async () => {
        let centrosEstudios = []
        if (solicitud?.tipoEstudios === TipoEstudiosEnum.GRADO || solicitud?.tipoEstudios === TipoEstudiosEnum.POSGRADO
            || solicitud?.tipoEstudios === TipoEstudiosEnum.ACCESO_UNI) {
            centrosEstudios = await getCentrosEstudiosByTipo(TipoCentroEstudiosEnum.UNIVERSIDAD)
        } else {
            centrosEstudios = await getCentrosEstudiosByTipo(TipoCentroEstudiosEnum.INSTITUTO)
        }
        if (solicitud?.centroEstudios) {
            if (centrosEstudios.find((centro) => centro.nombre === solicitud?.centroEstudios)) {
                setCentroEstudios(() => solicitud?.centroEstudios)
            } else {
                setCentroEstudios(() => 'Otro')
                setOtroCentroEstudios(() => solicitud?.centroEstudios)
            }
        } else {
            setCentroEstudios(() => '0')
        }
    }

    const checkFormat = (finalCheck?: boolean): boolean => {

        const cialRegex = /^[0-9A-Z]{10}$/i

        let result = true

        if (!numeroCial) {
            setIsNumeroCialError(() => false)
        } else {
            if (cialRegex.test(numeroCial)) {
                setIsNumeroCialError(() => false)
            } else {
                setIsNumeroCialError(() => true)
                result = false;
            }
        }
        if (finalCheck && !result) setWarning(() => 'Hay campos con formato erróneo')
        return result
    }

    useEffect(() => {
        checkIsOtroCentro()
    }, [])

    useEffect(() => {
        setOtroCentroEstudios(()=>undefined)
    }, [centroEstudios])




    useEffect(() => {
        checkFormat()
    }, [numeroCial])


    useEffect(() => {
        if (tipoEstudios !== TipoEstudiosEnum.GRADO) {
            setCreditosMatriculadosPasado(() => undefined)
            setCreditosMatriculados(() => undefined)
            setCreditosTotales(() => undefined)
            setCreditosSuperados(() => undefined)
            setPrimerAno(() => false)
        }
        if (tipoEstudios !== TipoEstudiosEnum.FP) {
            setNotaFct(() => undefined)
            setCapacidades(() => undefined)
            setFormacionComplementaria(() => undefined)
            setPreferenciaPracticas(() => undefined)
            setPuedeFueraMunicipio(() => false)
            setPuedeTeletrabajo(() => false)
        }
    }, [tipoEstudios])


    const onSave = async () => {
        setIsLoadingState(() => true)
        setWarning(() => undefined)
        if (!checkFormat(true)) {
            setIsLoadingState(() => false)
            return
        }
        if (solicitud) {
            setIsLoadingState(() => true)
            const newSolicitud: Solicitud = { ...solicitud }
            newSolicitud.estudiosCanarias = estudiosCanarias
            newSolicitud.centroEstudios = centroEstudios === 'Otro' ? otroCentroEstudios : centroEstudios
            newSolicitud.tipoEstudios = tipoEstudios
            newSolicitud.presencialidad = presencialidad
            newSolicitud.estudios = estudios
            newSolicitud.numeroCial = numeroCial
            newSolicitud.notaFct = notaFct
            newSolicitud.nota = nota
            newSolicitud.cursoSuperado = cursoSuperado
            newSolicitud.primerAno = primerAno
            newSolicitud.creditosTotales = creditosTotales
            newSolicitud.creditosMatriculados = creditosMatriculados
            newSolicitud.creditosMatriculadosPasado = creditosMatriculadosPasado
            newSolicitud.creditosSuperados = creditosSuperados
            newSolicitud.capacidades = capacidades
            newSolicitud.formacionComplementaria = formacionComplementaria
            newSolicitud.preferenciaPracticas = preferenciaPracticas
            newSolicitud.puedeFueraMunicipio = puedeFueraMunicipio
            newSolicitud.puedeTeletrabajo = puedeTeletrabajo
            newSolicitud.isFpNoImpartido = isFpNoImpartido

            try {
                const updatedSolicitud = await updateSolicitud(newSolicitud)
                setSolicitud(() => updatedSolicitud)
            } catch { }
            finally {
                setIsLoadingState(() => false)
            }
        }
    }

    return (
        solicitud !== undefined ?
            <div id='stepBox'>
                <div id='stepHeader'>
                    <h1>{'Datos académicos'}</h1>
                </div>
                <div className='formBox'>
                    {isPresent('estudiosCanarias', solicitud) && tipoEstudios !== TipoEstudiosEnum.GRADO &&
                        tipoEstudios !== TipoEstudiosEnum.POSGRADO ?
                        <div className='formElement'>
                            <SiNoField id='estudiosCanarias' label='¿Realiza sus estudios en Canarias?' value={estudiosCanarias}
                                setValue={setEstudiosCanarias} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('presencialidad', solicitud) ?
                        <div className='formElement'>
                            <PresencialidadField id='presencialidad' label='Presencialidad de los estudios' value={presencialidad}
                                setValue={setPresencialidad} />
                        </div>
                        :
                        undefined
                    }

                    <div className='formElement'>
                        <TipoEstudiosField id='tipoEstudios' label='Tipo de estudios' value={tipoEstudios} setValue={setTipoEstudios} />
                    </div>
                    <div className='formElement'>
                        <CentroEstudiosField id='centroEstudios' label='Centro de estudios' value={centroEstudios} setValue={setCentroEstudios}
                            tipoEstudios={tipoEstudios} />
                    </div>

                    {centroEstudios === 'Otro' ?
                        <div className='formElement'>
                            <TextField id='otroCentroEstudios' label='Otro centro de estudios' value={otroCentroEstudios} setValue={setOtroCentroEstudios}
                                size={SizeEnum.VB} />
                        </div>
                        :
                        undefined
                    }

                    {tipoEstudios === TipoEstudiosEnum.FP && isPresent('notaFct', solicitud) ?
                        <div className='formElement'>
                            <NumericField id='notaFct' label='Nota media final de acceso a FCT' value={notaFct} setValue={setNotaFct} isNota={true} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('primerAno', solicitud) && tipoEstudios === TipoEstudiosEnum.GRADO ?
                        <div className='formElement'>
                            <SiNoField id='primerAno' label='¿Es el primer año del grado?' value={primerAno}
                                setValue={setPrimerAno} />
                        </div>
                        :
                        undefined
                    }


                    {isPresent('cursoSuperado', solicitud) && (tipoEstudios === TipoEstudiosEnum.ESO || tipoEstudios === TipoEstudiosEnum.BACH
                        || (tipoEstudios === TipoEstudiosEnum.FP)) ?
                        <div className='formElement'>
                            <SiNoField id='cursoSuperado' label='¿Ha pasado de curso el último año?' value={cursoSuperado}
                                setValue={setCursoSuperado} />
                        </div>
                        :
                        undefined
                    }

                    {tipoEstudios === TipoEstudiosEnum.GRADO ?
                        <>
                            {isPresent('creditosMatriculadosPasado', solicitud) ?
                                <div className='formElement'>
                                    <NumericField id='creditosMatriculadosPasado' label='Créditos matriculados curso pasado'
                                        value={creditosMatriculadosPasado} setValue={setCreditosMatriculadosPasado} />
                                </div>
                                :
                                undefined
                            }

                            {isPresent('creditosSuperados', solicitud) ?
                                <div className='formElement'>
                                    <NumericField id='creditosSuperados' label='Créditos superados el curso pasado' value={creditosSuperados} setValue={setCreditosSuperados} />
                                </div>
                                :
                                undefined
                            }

                            {isPresent('creditosTotales', solicitud) ?
                                <div className='formElement'>
                                    <NumericField id='creditosTotales' label='Créditos totales del curso del año actual'
                                        value={creditosTotales} setValue={setCreditosTotales} />
                                </div>
                                :
                                undefined
                            }

                            {isPresent('creditosMatriculados', solicitud) ?
                                <div className='formElement'>
                                    <NumericField id='creditosMatriculados' label='Créditos matriculados en el año actual' value={creditosMatriculados} setValue={setCreditosMatriculados} />
                                </div>
                                :
                                undefined
                            }

                        </>
                        : undefined
                    }
                    {isPresent('nota', solicitud) && (tipoEstudios === TipoEstudiosEnum.ESO || tipoEstudios === TipoEstudiosEnum.BACH || tipoEstudios === TipoEstudiosEnum.FP || 
                        (tipoEstudios === TipoEstudiosEnum.GRADO && primerAno)) ?
                        <div className='formElement'>
                            <NumericField id='nota' label='Nota media del último curso realizado' value={nota} setValue={setNota} isNota={true} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('cial', solicitud) && solicitud.estudiosCanarias ?
                        <div className='formElement'>
                            <TextField id='numeroCial' label='Nº CIAL' value={numeroCial} setValue={setNumeroCial} size={SizeEnum.M} isUpper={true} formatError={isNumeroCialError} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('capacidades', solicitud) ?
                        <div className='formElementTextArea'>
                            <TextAreaField id='capacidades' label='Define tus capacidades y actitudes' value={capacidades} setValue={setCapacidades} size={SizeEnum.VB} maxCar={200} />
                        </div>
                        :
                        undefined
                    }

                    {isPresent('formacionComplementaria', solicitud) ?
                        <div className='formElementTextArea'>
                            <TextAreaField id='formacionComplementaria' label='Formación complementaria a los estudios que cursas' value={formacionComplementaria} maxCar={200} setValue={setFormacionComplementaria} size={SizeEnum.VB} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('preferenciaPracticas', solicitud) ?
                        <div className='formElementTextArea'>
                            <TextAreaField id='preferenciaPracticas' label='¿Dónde te gustaría hacer las prácticas? Entidad, sector, etc.?' value={preferenciaPracticas} maxCar={200} setValue={setPreferenciaPracticas} size={SizeEnum.VB} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('puedeFueraMunicipio', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='puedeFueraMunicipio' label='¿Estarías dispuesto a realizar las prácticas fuera de tu municipio?' value={puedeFueraMunicipio} setValue={setPuedeFueraMunicipio} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('puedeTeletrabajo', solicitud) ?
                        <div className='formElement'>
                            <SiNoField id='puedeTeletrabajo' label='En caso de que fuera necesario, ¿tienes medios para teletrabajar?' value={puedeTeletrabajo} setValue={setPuedeTeletrabajo} />
                        </div>
                        :
                        undefined
                    }
                    {isPresent('isFpNoImpartido', solicitud) && tipoEstudios === TipoEstudiosEnum.FP ?
                        <div className='formElement'>
                            <SiNoField id='isFpNoImpartido' label='Curso un FP no contemplado en la oferta de la isla, o no obtuve plaza en la misma' value={isFpNoImpartido} setValue={setIsFpNoImpartido} />
                        </div>
                        :
                        undefined
                    }

                </div>
                {
                    warning ?
                        <p className='warningMsg'><MdErrorOutline /> {warning}</p>
                        :
                        undefined
                }

                {!modoConsulta ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}
            </div >
            :
            <div id='stepBox'></div>
    )
}
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { consultaModo, familiarModal, FamiliarModalProps, isLoading, reloadFamiliares, solicitudInfo } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineClose, AiOutlineSave } from 'react-icons/ai'
import { DateField } from '../atom/DateField';
import { NumericField } from '../atom/NumericField';
import { useApi } from '../../hooks/useApi';
import { Familiar } from '../../types/entities';
import { TipoDocumentoField } from '../atom/TipoDocumentoField';
import { ParentescoEnum, RolEnum, SizeEnum, TipoDocumentoEnum, TipoEstudiosEnum } from '../../types/types';
import { MdErrorOutline } from 'react-icons/md';
import { ParentescoField } from '../atom/ParentescoField';
import { SiNoField } from '../atom/SiNoField';
import { DiscapacidadField } from '../atom/DiscapacidadField';
import { utilMethods } from '../../utils/utilMethods';
import { useAuth } from '../../hooks/useAuth';


export function FamiliarModal() {

    const [modalInfo, setModalInfo] = useRecoilState<FamiliarModalProps>(familiarModal)
    const { rol } = useAuth()

    const [parentesco, setParentesco] = useState<string | undefined>(undefined)
    const [nombre, setNombre] = useState<string | undefined>(undefined)
    const [apellido1, setApellido1] = useState<string | undefined>(undefined)
    const [apellido2, setApellido2] = useState<string | undefined>(undefined)
    const [fechaNacimiento, setFechaNacimiento] = useState<Date>()
    const [tipoDocumento, setTipoDocumento] = useState<string | undefined>(TipoDocumentoEnum.DNI)
    const [numeroDocumento, setNumeroDocumento] = useState<string | undefined>(undefined)
    const [numeroSoporteDocumento, setNumeroSoporteDocumento] = useState<string | undefined>(undefined)
    const [renta, setRenta] = useState<number | undefined>(undefined)
    const [violenciaGenero, setViolenciaGenero] = useState<boolean>(false)
    const [gradoDiscapacidad, setGradoDiscapacidad] = useState<number>(0)
    const [sinPrestacionDesempleo, setSinPrestacionDesempleo] = useState<boolean>(false)
    const [isHospitalizado, setIsHospitalizado] = useState<boolean>(false)
    const [isUniversitario, setIsUniversitario] = useState<boolean>(false)

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const [familiaresReload, setFamiliaresReload] = useRecoilState<boolean>(reloadFamiliares);
    const [warning, setWarning] = useState<string | undefined>(undefined)

    const [errorFields, setErrorFields] = useState<string[]>([])

    const [isNumeroDocumentoError, setIsNumeroDocumentoError] = useState<boolean>(false)
    const [isNumeroSoporteError, setIsNumeroSoporteError] = useState<boolean>(false)


    const [solicitud, setSolicitud] = useRecoilState(solicitudInfo);

    const modoConsulta = useRecoilValue<boolean>(consultaModo)

    const isPresent = utilMethods.isPresent

    const { createFamiliar, getFamiliar, updateFamiliar } = useApi()

    useEffect(() => {
        if (modalInfo.familiarId) {
            onGetFamiliar(modalInfo.familiarId)
        }

    }, [modalInfo])

    const checkFormat = (finalCheck?: boolean): boolean => {

        const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
        const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

        const soporteDniRegex = /^[A-Z]{3}[0-9]{6}$/i
        const soporteNieRegex = /^[E][0-9]{8}$/i;

        let result = true

        if (!numeroDocumento) {
            setIsNumeroDocumentoError(() => false)
        } else {
            if (tipoDocumento === TipoDocumentoEnum.DNI) {
                if (dniRegex.test(numeroDocumento)) {
                    if (utilMethods.checkDniLetter(numeroDocumento)) {
                        setIsNumeroDocumentoError(() => false)
                    } else {
                        setIsNumeroDocumentoError(() => true)
                        result = false;
                    }
                } else {
                    setIsNumeroDocumentoError(() => true)
                    result = false;
                }
            } else {
                if (nieRegex.test(numeroDocumento)) {
                    setIsNumeroDocumentoError(() => false)
                } else {
                    setIsNumeroDocumentoError(() => true)
                    result = false;
                }
            }
        }

        if (!numeroSoporteDocumento) {
            setIsNumeroSoporteError(() => false)
        } else {
            if (tipoDocumento === TipoDocumentoEnum.DNI) {
                if (soporteDniRegex.test(numeroSoporteDocumento)) {
                    setIsNumeroSoporteError(() => false)
                } else {
                    setIsNumeroSoporteError(() => true)
                    result = false;
                }
            } else {
                if (soporteNieRegex.test(numeroSoporteDocumento)) {
                    setIsNumeroSoporteError(() => false)
                } else {
                    setIsNumeroSoporteError(() => true)
                    result = false;
                }
            }
        }

        if (finalCheck && !result) setWarning(() => 'Hay campos con formato erróneo')
        return result
    }

    useEffect(() => {
        checkFormat()
    }, [numeroDocumento, tipoDocumento, numeroSoporteDocumento])

    const isDiscapacidadOn = () => {
        if (parentesco === ParentescoEnum.HIJO || parentesco === ParentescoEnum.HERMANO) {
            return true;
        }
        return false;
    }

    const isSinPrestacionDesempleoOn = () => {
        const age = utilMethods.calculateAge(fechaNacimiento)
        if (parentesco === ParentescoEnum.HERMANO && age && age > 24) {
            return true;
        }
        return false;
    }

    const isUniversitarioOn = () => {
        const age = utilMethods.calculateAge(fechaNacimiento)
        if ((parentesco === ParentescoEnum.HERMANO || parentesco === ParentescoEnum.HIJO) && age !== undefined && age < 25) {
            return true;
        }
        return false;
    }

    const isHospitalizadoOn = () => {
        if ((solicitud?.tipoEstudios === TipoEstudiosEnum.ESO || solicitud?.tipoEstudios === TipoEstudiosEnum.BACH)
            && (parentesco === ParentescoEnum.HERMANO || parentesco === ParentescoEnum.PADRE)) {
            return true;
        }
        return false;
    }


    useEffect(() => {
        setGradoDiscapacidad(() => 0)
        if (!isHospitalizadoOn()) {
            setIsHospitalizado(() => false)
        }
        if (parentesco !== ParentescoEnum.HERMANO) {
            setFechaNacimiento(() => undefined)
        }
    }, [parentesco])

    useEffect(() => {
        if (!isSinPrestacionDesempleoOn()) {
            setSinPrestacionDesempleo(() => false)
        }
        if (!isUniversitarioOn()) {
            setIsUniversitario(() => false)
        }
    }, [parentesco, fechaNacimiento])


    const checkFields = () => {
        const errorFields: string[] = []
        let parentescoFinal = (parentesco === '0' ? undefined : parentesco)
        if (!parentescoFinal) {
            errorFields.push('parentesco')
        }
        if (!nombre) {
            errorFields.push('nombre')
        }
        if (!apellido1) {
            errorFields.push('apellido1')
        }
        if (!apellido2) {
            errorFields.push('apellido2')
        }
        if (parentescoFinal === ParentescoEnum.HERMANO) {
            if (!fechaNacimiento) {
                errorFields.push('fechaNacimiento')

            } else {
                const fechaNacimientoDate = new Date(fechaNacimiento)
                if ((isNaN(fechaNacimientoDate.getTime()))) {
                    errorFields.push('fechaNacimiento')
                }
            }
        }

        if (!tipoDocumento) {
            errorFields.push('tipoDocumento')
        }
        if (!numeroDocumento) {
            errorFields.push('numeroDocumento')
        }
        if (!numeroSoporteDocumento) {
            errorFields.push('numeroSoporteDocumento')
        }
        setErrorFields(() => errorFields)
        return errorFields.length === 0
    }

    const isError = (id: string) => {
        if (errorFields.find((field) => field === id)) {
            return true;
        } else {
            return false;
        }
    }



    const onSave = async () => {
        setIsLoadingState(() => true)
        setWarning(() => undefined)
        let parentescoFinal = (parentesco === '0' ? undefined : parentesco)
        if (solicitud?.id !== undefined) {
            if (checkFields()) {
                if (!checkFormat(true)) {
                    setIsLoadingState(() => false)
                    return
                }
                if (modalInfo.familiarId) {
                    const updatedFamiliar: Familiar = {
                        id: modalInfo.familiarId, parentesco: parentescoFinal, nombre: nombre, apellido1: apellido1, apellido2: apellido2, fechaNacimiento: fechaNacimiento,
                        tipoDocumento: tipoDocumento, numeroDocumento: numeroDocumento, numeroSoporteDocumento: numeroSoporteDocumento, violenciaGenero: violenciaGenero, gradoDiscapacidad: gradoDiscapacidad,
                        isHospitalizado: isHospitalizado, isUniversitario: isUniversitario
                    }
                    const familiar = await updateFamiliar(updatedFamiliar)

                } else {
                    const newFamiliar: Familiar = {
                        parentesco: parentesco, nombre: nombre, apellido1: apellido1, apellido2: apellido2, fechaNacimiento: fechaNacimiento,
                        tipoDocumento: tipoDocumento, numeroDocumento: numeroDocumento, numeroSoporteDocumento: numeroSoporteDocumento, violenciaGenero: violenciaGenero, gradoDiscapacidad: gradoDiscapacidad,
                        isHospitalizado: isHospitalizado, isUniversitario: isUniversitario
                    }
                    const familiar = await createFamiliar(solicitud.id, newFamiliar)
                }
                setFamiliaresReload((old) => !old)
                onClear()
                setModalInfo(() => { return { visible: false } })
            } else {
                setWarning(() => 'Faltan datos por rellenar')
            }
        }
        setIsLoadingState(() => false)
    }

    const onGetFamiliar = async (familiarId: string) => {
        const familiar = await getFamiliar(familiarId)
        setParentesco(() => familiar.parentesco)
        setNombre(() => familiar.nombre)
        setApellido1(() => familiar.apellido1)
        setApellido2(() => familiar.apellido2)
        setFechaNacimiento(() => familiar.fechaNacimiento)
        setTipoDocumento(() => familiar.tipoDocumento)
        setNumeroDocumento(() => familiar.numeroDocumento)
        setNumeroSoporteDocumento(() => familiar.numeroSoporteDocumento)
        setRenta(() => familiar.renta)
        setViolenciaGenero(() => familiar.violenciaGenero ? familiar.violenciaGenero : false)
        setGradoDiscapacidad(() => familiar.gradoDiscapacidad ? familiar.gradoDiscapacidad : 0)
        setSinPrestacionDesempleo(() => familiar.sinPrestacionDesempleo ? familiar.sinPrestacionDesempleo : false)
        setIsHospitalizado(() => familiar.isHospitalizado ? familiar.isHospitalizado : false)
        setIsUniversitario(() => familiar.isUniversitario ? familiar.isUniversitario : false)
    }

    const onClear = () => {
        setParentesco(() => undefined)
        setNombre(() => undefined)
        setApellido1(() => undefined)
        setApellido2(() => undefined)
        setFechaNacimiento(() => undefined)
        setTipoDocumento(() => TipoDocumentoEnum.DNI)
        setNumeroDocumento(() => undefined)
        setNumeroSoporteDocumento(() => undefined)
        setRenta(() => undefined)
        setViolenciaGenero(() => false)
        setGradoDiscapacidad(() => 0)
        setSinPrestacionDesempleo(() => false)
        setIsHospitalizado(() => false)
        setIsUniversitario(() => false)
        setWarning(() => undefined)
        setErrorFields(() => [])
    }


    return (
        modalInfo.visible && solicitud !== undefined ?
            <ModalBase>
                <>
                    <div id='modalHeader'>
                        <h1>{modalInfo.familiarId ? 'Editar familiar' : 'Añadir familiar'}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear(); setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalForm'>
                        <div className='formBoxModal'>
                            <div className='leftForm'>
                                <ParentescoField id='parentesco' label='Parentesco' value={parentesco} setValue={setParentesco} reduced={true} isError={isError('parentesco')} />
                                <TipoDocumentoField id='tipoDocumento' label='Tipo documento' value={tipoDocumento} setValue={modoConsulta ? undefined : setTipoDocumento}
                                    isError={isError('tipoDocumento')}></TipoDocumentoField>
                                <TextField id='numeroDocumento' label='Número documento' value={numeroDocumento} setValue={setNumeroDocumento} size={SizeEnum.M} isUpper={true}
                                    formatError={isNumeroDocumentoError} isError={isError('numeroDocumento')}></TextField>
                                <TextField id='numeroSoporteDocumento' label='Número soporte documento' value={numeroSoporteDocumento} setValue={setNumeroSoporteDocumento} size={SizeEnum.S} isUpper={true}
                                    formatError={isNumeroSoporteError} isError={isError('numeroSoporteDocumento')} helpLink={'https://sede.educacion.gob.es/dam/jcr:328d4bc3-ea5c-4d78-99a1-93ab4c2d669a/ayuda_identidad.pdf'}></TextField>
                                <TextField id='nombre' label='Nombre' value={nombre} setValue={setNombre} isError={isError('nombre')}></TextField>
                                <TextField id='apellido1' label='Apellido 1' value={apellido1} setValue={setApellido1} isError={isError('apellido1')}></TextField>
                                {isDiscapacidadOn() ?
                                    <DiscapacidadField id='gradoDiscapacidad' label='Grado de discapacidad' value={gradoDiscapacidad} setValue={setGradoDiscapacidad}
                                        disabled={false} />
                                    : undefined
                                }
                                {isPresent('isHospitalizado', solicitud) && isHospitalizadoOn() ?
                                    <SiNoField id='isHospitalizado' label='Se encuentra hospitalizado o recibiendo tratamiento sanitario'
                                        value={isHospitalizado} setValue={setIsHospitalizado} />
                                    : undefined
                                }

                            </div>
                            <div className='rightForm'>
                                <TextField id='apellido2' label='Apellido 2' value={apellido2} setValue={setApellido2} isError={isError('apellido2')}></TextField>
                                {parentesco === ParentescoEnum.HERMANO ?
                                    <DateField id='fechaNacimiento' label='Fecha de nacimiento' value={fechaNacimiento} setValue={setFechaNacimiento} isError={isError('fechaNacimiento')}></DateField>
                                    :
                                    undefined
                                }
                                <SiNoField id='violenciaGenero' label='Víctima de violencia de género' value={violenciaGenero} setValue={setViolenciaGenero} />
                                {isSinPrestacionDesempleoOn() ?
                                    <SiNoField id='sinPrestacionDesempleo' label='Es demandante de empleo y no ha cobrado prestación por desempleo el mes anterior al de la convocatoria'
                                        value={sinPrestacionDesempleo} setValue={setSinPrestacionDesempleo} />
                                    : undefined
                                }
                                {isPresent('isUniversitario', solicitud) && isUniversitarioOn() ?
                                    <SiNoField id='isUniversitario' label='No reside en el domicilo y está estudiando en una universidad'
                                        value={isUniversitario} setValue={setIsUniversitario} />
                                    : undefined
                                }
                                {rol?.nombre !== RolEnum.USUARIO ?
                                    <NumericField id='renta' label='Renta' value={renta} setValue={setRenta}></NumericField>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        {warning ?
                            <p className='warningMsg'><MdErrorOutline /> {warning}</p>
                            :
                            undefined
                        }
                        {modoConsulta ?
                            <></>
                            :
                            <div id='saveButModal'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div>
                        }
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React from 'react';
import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';
import { useAuth } from '../../hooks/useAuth';
import { RolEnum, SizeEnum } from '../../types/types';

export function TextAreaField({ id, label, value, setValue, setFilterPropValue, size, cols, maxCar}: {
    id: string, label: string,
    value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, size?:SizeEnum, cols?:number, maxCar?: number}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const { rol } = useAuth()

    const getSize = ()=> {
        switch (size) {
            case SizeEnum.VB:
                return '100%'                
            case SizeEnum.B:
                return '70%'                
            case SizeEnum.M:
                return '40%'                
            case SizeEnum.S:
                return '20%'                
            default:
                return '70%'                
        }
    }


    return (
        <div className={'textAreaFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className='fieldAndBut'>
                <textarea style={{width: getSize()}} id={id} value={value ? value : ''} rows={cols?cols:4}
                    disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false}
                    onChange={setValue ? (e) => {if (maxCar && e.target.value.length <= maxCar) setValue(() => e.target.value)} : setFilterPropValue ?
                        (e) => setFilterPropValue(e.target.value, id) : undefined} />
            </div>
        </div>
    )
}


import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoading, confirmationModal, ConfirmationModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { TextAreaField } from '../atom/TextAreaField';
import { SizeEnum } from '../../types/types';


export function ConfirmationModal() {

    const [modalInfo, setModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)

    const [confirmationText, setConfirmationText] = useState<string | undefined>(undefined)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)


    const onConfirm = async () => {
        setIsLoadingState(() => true)
        const goFunction = modalInfo.goFunction
        try {
            onClear()
            await goFunction()
        } catch (e) {
        }
        finally {
            setIsLoadingState(() => false)
        }
    }


    const onClear = () => {
        setModalInfo(() => { return { visible: false, goFunction: () => false, text: '' } })
    }

    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.XS}>
                <>
                    <div id='modalHeader'>
                        <h1>{modalInfo.text}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear() }}><AiOutlineClose /></button>
                    </div>
                    <div className='choiceButBox'>
                        <button onClick={onConfirm}><AiOutlineSend />{'Sí'}</button>
                        <button onClick={onClear}><AiOutlineSend />{'No'}</button>
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
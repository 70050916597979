import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoading, solicitudInfo } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { RequiredDocs, Solicitud } from '../../types/entities';
import './../../index.css';
import { EstadoSolicitud, RolEnum } from '../../types/types';
import { useAuth } from '../../hooks/useAuth';
import { CheckBoxField } from '../atom/CheckBoxField';
import { AiOutlineSave } from 'react-icons/ai';
import { NumericField } from '../atom/NumericField';

export function InfoChecker({ requiredDocs }: { requiredDocs?: RequiredDocs }) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const { updateSolicitud } = useApi()
    const { id, rol } = useAuth()
    const [checkDiscapacidad, setCheckDiscapacidad] = useState(solicitud?.checkDiscapacidad ? solicitud?.checkDiscapacidad : false)
    const [checkEmpadronamiento, setCheckEmpadronamiento] = useState(solicitud?.checkEmpadronamiento ? solicitud?.checkEmpadronamiento : false)
    const [checkFamiliaNumerosa, setCheckFamiliaNumerosa] = useState(solicitud?.checkFamiliaNumerosa ? solicitud?.checkFamiliaNumerosa : false)
    const [checkIdentidad, setCheckIdentidad] = useState(solicitud?.checkIdentidad ? solicitud?.checkIdentidad : false)
    const [checkMatricula, setCheckMatricula] = useState(solicitud?.checkMatricula ? solicitud?.checkMatricula : false)
    const [checkMatriculaUniversidad, setCheckMatriculaUniversidad] = useState(solicitud?.checkMatriculaUniversidad ? solicitud?.checkMatriculaUniversidad : false)
    const [checkPrestaciones, setCheckPrestaciones] = useState(solicitud?.checkPrestaciones ? solicitud?.checkPrestaciones : false)
    const [checkRenta, setCheckRenta] = useState(solicitud?.checkRenta ? solicitud?.checkRenta : false)
    const [checkTitulos, setCheckTitulos] = useState(solicitud?.checkTitulos ? solicitud?.checkTitulos : false)
    const [checkViolencia, setCheckViolencia] = useState(solicitud?.checkViolencia ? solicitud?.checkViolencia : false)
    const [checkHospitalizado, setCheckHospitalizado] = useState(solicitud?.checkHospitalizado ? solicitud?.checkHospitalizado : false)
    const [checkFederado, setCheckFederado] = useState(solicitud?.checkFederado ? solicitud?.checkFederado : false)
    const [checkFpNoImpartido, setCheckFpNoImpartido] = useState(solicitud?.checkFpNoImpartido ? solicitud?.checkFpNoImpartido : false)
    const [checkEmancipado, setCheckEmancipado] = useState(solicitud?.checkEmancipado ? solicitud?.checkEmancipado : false)
    const [checkUniversitario, setCheckUniversitario] = useState(solicitud?.checkUniversitario ? solicitud?.checkUniversitario : false)
    const [checkAbuelos, setCheckAbuelos] = useState(solicitud?.checkAbuelos ? solicitud?.checkAbuelos : false)


    const onSave = async () => {
        setIsLoadingState(() => true)
        const updatedSolicitud: Solicitud = { ...solicitud }
        updatedSolicitud.checkDiscapacidad = requiredDocs?.discapacidad ? checkDiscapacidad : undefined;
        updatedSolicitud.checkEmpadronamiento = checkEmpadronamiento;
        updatedSolicitud.checkFamiliaNumerosa = requiredDocs?.familiaNumerosa ? checkFamiliaNumerosa : undefined;
        updatedSolicitud.checkIdentidad = checkIdentidad;
        updatedSolicitud.checkMatricula = requiredDocs?.matriculaCalificaciones ? checkMatricula : undefined;
        updatedSolicitud.checkMatriculaUniversidad = requiredDocs?.matriculaUniversitaria ? checkMatriculaUniversidad : undefined;
        updatedSolicitud.checkPrestaciones = requiredDocs?.prestaciones ? checkPrestaciones : undefined;
        updatedSolicitud.checkRenta = checkRenta;
        updatedSolicitud.checkTitulos = requiredDocs?.titulos ? checkTitulos : undefined;
        updatedSolicitud.checkViolencia = requiredDocs?.violenciaGenero ? checkViolencia : undefined;
        updatedSolicitud.checkHospitalizado = requiredDocs?.hospitalizado ? checkHospitalizado : undefined;
        updatedSolicitud.checkFederado = requiredDocs?.federado ? checkFederado : undefined;
        updatedSolicitud.checkFpNoImpartido = requiredDocs?.fpNoImpartido ? checkFpNoImpartido : undefined;
        updatedSolicitud.checkEmancipado = requiredDocs?.emancipado ? checkEmancipado : undefined;
        updatedSolicitud.checkUniversitario = requiredDocs?.familiarUniversitario ? checkUniversitario : undefined;
        updatedSolicitud.checkAbuelos = requiredDocs?.abuelos ? checkAbuelos : undefined;

        try {
            const newSolicitud = await updateSolicitud(updatedSolicitud)
            setSolicitud(() => newSolicitud)
        } catch { }
        finally {
            setIsLoadingState(() => false)
        }
    }

    const isDisabled = rol && (rol.nombre === RolEnum.USUARIO || (solicitud?.estado !== EstadoSolicitud.BORRADOR &&
        solicitud?.estado !== EstadoSolicitud.ENVIADA && solicitud?.estado !== EstadoSolicitud.SUBSANACION &&
        solicitud?.estado !== EstadoSolicitud.PENDIENTE_ENVIO))

    return (
        <div id='infoChecker'>
            {solicitud?.estado === EstadoSolicitud.SUBSANACION ?
                < p className='warningMsg'>{'La solicitud se encuentra en subsanación'}</p>
                :
                undefined
            }
            <h2>{'Información comprobada'}</h2>
            <CheckBoxField id='checkEmpadronamiento' label='Consulta sobre datos de empadronamiento al INE' value={checkEmpadronamiento} setValue={!isDisabled ? setCheckEmpadronamiento : undefined} isCheckField={true} />
            <CheckBoxField id='checkIdentidad' label='Consulta sobre datos de identidad a la Dirección General de Policía' value={checkIdentidad} setValue={!isDisabled ? setCheckIdentidad : undefined} isCheckField={true} />
            <CheckBoxField id='checkRenta' label='Consulta sobre nivel de renta para becas a la Agencia Tributaria' value={checkRenta} setValue={!isDisabled ? setCheckRenta : undefined} isCheckField={true} />

            {
                requiredDocs?.discapacidad ?
                    <CheckBoxField id='checkDiscapacidad' label='Consulta sobre datos discapacidad, al MINHAP' value={checkDiscapacidad} setValue={!isDisabled ? setCheckDiscapacidad : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.familiaNumerosa ?
                    <CheckBoxField id='checkFamiliaNumerosa' label='Consulta sobre datos de familia numerosa, al MINHAP' value={checkFamiliaNumerosa} setValue={!isDisabled ? setCheckFamiliaNumerosa : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.matriculaCalificaciones ?
                    <CheckBoxField id='checkMatricula' label='Consulta de datos de matrícula y calificaciones, al Gobierno de Canarias' value={checkMatricula} setValue={!isDisabled ? setCheckMatricula : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.matriculaUniversitaria ?
                    <CheckBoxField id='checkMatriculaUniversidad' label='Consulta de datos matrícula universitaria, a la CRUE' value={checkMatriculaUniversidad} setValue={!isDisabled ? setCheckMatriculaUniversidad : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.prestaciones ?
                    <CheckBoxField id='checkPrestaciones' label='Consulta sobre datos de prestaciones, al SEPE' value={checkPrestaciones} setValue={!isDisabled ? setCheckPrestaciones : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.titulos ?
                    <CheckBoxField id='checkTitulos' label='Consulta sobre datos de títulos universitarios y no universitarios, al Ministerio de Educación' value={checkTitulos} setValue={!isDisabled ? setCheckTitulos : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.violenciaGenero ?
                    <CheckBoxField id='checkViolencia' label='Consulta beneficiario de derechos por ser víctima de violencia de género' value={checkViolencia} setValue={!isDisabled ? setCheckViolencia : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.hospitalizado ?
                    <CheckBoxField id='checkHospitalizado' label='Documento justificativo de la hospitalización del solicitante o uno de sus familiares' value={checkHospitalizado} setValue={!isDisabled ? setCheckHospitalizado : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.federado ?
                    <CheckBoxField id='checkFederado' label='Documento justificativo de si el solicitante está federado en algún club deportivo con sede fuera de la isla' value={checkFederado} setValue={!isDisabled ? setCheckFederado : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.fpNoImpartido ?
                    <CheckBoxField id='checkFpNoImpartido' label='Documento justificativo de si el FP requerido por el solicitante no se imparte en la isla o no se ha obtenido plaza en el impartido' value={checkFpNoImpartido} setValue={!isDisabled ? setCheckFpNoImpartido : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.emancipado ?
                    <CheckBoxField id='checkEmancipado' label='Documento justificativo de la independencia económica del solicitante' value={checkEmancipado} setValue={!isDisabled ? setCheckEmancipado : undefined} isCheckField={true} />
                    :
                    undefined
            }
            {
                requiredDocs?.familiarUniversitario ?
                    <CheckBoxField id='checkUniversitario' label='Documento justificativo de familiares estudiando en la universidad' value={checkUniversitario} setValue={!isDisabled ? setCheckUniversitario : undefined} isCheckField={true} />
                    :
                    undefined
            }

            {
                requiredDocs?.abuelos ?
                    <CheckBoxField id='checkAbuelos' label='Acreditación de la tutela de abuelos' value={checkAbuelos} setValue={!isDisabled ? setCheckAbuelos : undefined} isCheckField={true} />
                    :
                    undefined
            }


            {!isDisabled ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}
        </div >
    )
}

import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isLoading, reloadRemesas } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Remesa } from '../../types/entities';
import { Paginacion } from '../molecule/Paginacion';
import { RemesaCard } from '../molecule/RemesaCard';

  
export function RemesaList ({esLocal, esConcedida}:{esLocal:boolean, esConcedida:boolean}) {

    const [remesaAbierta, setRemesaAbierta] = useState<Remesa|undefined>(undefined)
    const [remesasCerradaList, setRemesasCerradaList] = useState<Remesa[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const {getRemesaAbierta, getRemesasCerradas} = useApi()
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const reloadRemesasState = useRecoilValue<boolean>(reloadRemesas)


    useEffect(() => {
        onGetRemesaAbierta()
      }, [reloadRemesasState])
  
    useEffect(() => {
        onGetRemesasCerradas()
    }, [page, reloadRemesasState])

   const onGetRemesaAbierta = async () => {
        const remesa = await getRemesaAbierta(esLocal, esConcedida)
        setRemesaAbierta(()=>remesa)
   }

   const onGetRemesasCerradas = async () => {
    setIsLoadingState(()=>true)
    const remesasPage = await getRemesasCerradas(esLocal, esConcedida, page)
    setPage(()=>remesasPage.pagina)
    setTotalPages(()=>remesasPage.paginasTotales)
    setRemesasCerradaList(()=>remesasPage.content)
    setIsLoadingState(()=>false)

}
    return (
        <div className='remesaList'>
            <p className='remesaTitle'>{esLocal ? 'Remesas locales' : 'Remesas foráneas'}{esConcedida ? ' concedidas' : ' denegadas'}</p>
            <div className='currentRemesa'>
                <p className='remesaLabel'>Remesa abierta</p>
                {remesaAbierta !== undefined ?
                    <RemesaCard remesa={remesaAbierta}/>
                :
                    <></>
                }
            </div>
            <p className='remesaLabel'>Remesas cerradas</p>
            <div className='oldRemesas'>
                {remesasCerradaList.map((remesa)=> <RemesaCard remesa={remesa}/>)}
            </div>
            <Paginacion marginTop='5vh' page={page} setPage={setPage} totalPages={totalPages}/>
        </div>
)
}
import React from 'react';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

export function Paginacion ({page, setPage, totalPages, marginTop='0'}:{page:number, setPage:React.Dispatch<React.SetStateAction<number>>, totalPages:number, marginTop?:string}) {

    const onChangePage = (isNext:boolean)=> {
        if (isNext) {
            if (totalPages > page + 1) {
                setPage((oldPage)=>++oldPage)
            }
        } else {
            if (page > 0) { 
                setPage((oldPage)=>--oldPage)
            }
        }
    }



    return (
        <div id='paginacionBox' style={{marginTop: marginTop}}>
            <div id='paginacionContent'>
                <button disabled={page > 0 ? false : true} onClick={()=>onChangePage(false)}><AiOutlineCaretLeft/></button>
                <div id='paginacionNumber'>{page + 1}</div>
                <button disabled={totalPages > page + 1 ? false : true} onClick={()=>onChangePage(true)}><AiOutlineCaretRight/></button>
            </div>
        </div>
)
}
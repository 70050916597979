import { EstadoCivilEnum, EstadoSolicitud, EstadoStyle, FamiliaNumerosaEnum, ParentescoEnum, PresencialidadEnum, SelectOption, TipoEstudiosEnum } from "../types/types"
import { FiCheckSquare, FiFileText, FiSend, FiFilePlus } from 'react-icons/fi'
import { ImCancelCircle } from "react-icons/im"
import moment from "moment"
import { conf } from "../conf"
import { MdErrorOutline } from "react-icons/md"
import { BsCheck2Circle } from "react-icons/bs"
import { Solicitud } from "../types/entities"



const getSelectedOption = (option: any, optionList: SelectOption[]): SelectOption | undefined => {
    const optionSelected = optionList.filter((opt) => opt.value === option)
    return optionSelected.pop()
}

const getIcon = (estado: string | undefined): EstadoStyle | undefined => {
    switch (estado) {
        case EstadoSolicitud.BORRADOR:
            return { icono: <FiFileText />, mensaje: 'Su solicitud está en borradores', name: 'Borrador' }
        case EstadoSolicitud.PENDIENTE_ENVIO:
            return { icono: <BsCheck2Circle />, mensaje: 'Su solicitud está pendiente de envío', name: 'Pendiente de envío' }
        case EstadoSolicitud.ENVIADA:
            return { icono: <FiSend />, mensaje: 'Su solicitud ha sido enviada', name: 'Enviada' }
        case EstadoSolicitud.SUBSANACION:
            return { icono: <MdErrorOutline />, mensaje: 'Su solicitud está en subsanación', name: 'Subsanación' }
        case EstadoSolicitud.COMPROBADA:
            return { icono: <BsCheck2Circle />, mensaje: 'Su solicitud ha sido comprobada', name: 'Comprobada' }
        case EstadoSolicitud.CORRECTA:
            return { icono: <BsCheck2Circle />, mensaje: 'Su solicitud es correcta', name: 'Correcta' }
        case EstadoSolicitud.APROBADA:
            return { icono: <FiCheckSquare />, mensaje: 'Su solicitud ha sido aprobada', name: 'Aprobada' }
        case EstadoSolicitud.ERRONEA:
            return { icono: <ImCancelCircle />, mensaje: 'Su solicitud es errónea ', name: 'Errónea' }
        case EstadoSolicitud.PENDIENTE_DESCARTAR:
            return { icono: <ImCancelCircle />, mensaje: 'Su solicitud ha sido comprobada ', name: 'Pend. descarte' }
        case EstadoSolicitud.DESCARTADA:
            return { icono: <ImCancelCircle />, mensaje: 'Su solicitud ha sido descartada', name: 'Descartada' }
        case EstadoSolicitud.RENUNCIADA:
            return { icono: <ImCancelCircle />, mensaje: 'Ha renunciado a su solicitud', name: 'Renunciada' }
        default:
            return (undefined)
    }
}

const getStringFromDate = (date: Date) => {
    return moment(date).format(conf.dateTimeFormat)
}

const isModificable = (estado: string | undefined, id: string, subsanacionList: string[] | undefined) => {
    if (estado === EstadoSolicitud.BORRADOR) {
        return true;
    }
    if (subsanacionList) {
        if (estado === EstadoSolicitud.SUBSANACION && subsanacionList.includes(id)) {
            return true;
        }
    }
    return false;
}

const tagToReadableFormat = (tag: string | undefined): string => {
    if (tag) {
        const whiteSpaceStr = tag.replaceAll('_', ' ')
        const result = `${whiteSpaceStr.charAt(0).toUpperCase()}${whiteSpaceStr.substring(1).toLowerCase()}`
        return result
    }
    return ''
}

const getParentescoLabel = (parentesco: string): string => {
    switch (parentesco) {
        case ParentescoEnum.ASCENDIENTE:
            return 'Ascendiente de padre/madre'
        case ParentescoEnum.HERMANO:
            return 'Hermano/a'
        case ParentescoEnum.PADRE:
            return 'Padre/Madre/Tutor legal/Cónyuge de padre o madre'
        case ParentescoEnum.HIJO:
            return 'Hijo/a'
        case ParentescoEnum.PAREJA:
            return 'Pareja'
        default:
            return ''
    }
}

const getEstadoCivilLabel = (estadoCivil: string): string => {
    switch (estadoCivil) {
        case EstadoCivilEnum.SOLTERO:
            return 'Soltero/a'
        case EstadoCivilEnum.CASADO:
            return 'Casado/a'
        case EstadoCivilEnum.DIVORCIADO:
            return 'Divorciado/a'
        case EstadoCivilEnum.VIUDO:
            return 'Viudo/a'
        default:
            return ''
    }
}

const getPresencialidadLabel = (presencialidad: string): string => {
    switch (presencialidad) {
        case PresencialidadEnum.DISTANCIA:
            return 'A distancia'
        case PresencialidadEnum.PRESENCIAL:
            return 'Presencial'
        case PresencialidadEnum.SEMIPRESENCIAL:
            return 'Semipresencial'
        default:
            return ''
    }
}


const getFamiliaNumerosaLabel = (familiaNumerosa: string): string => {
    switch (familiaNumerosa) {
        case FamiliaNumerosaEnum.NO:
            return 'No'
        case FamiliaNumerosaEnum.GENERAL:
            return 'General'
        case FamiliaNumerosaEnum.ESPECIAL:
            return 'Especial'
        default:
            return ''
    }
}

const getTipoEstudiosLabel = (tipoEstudios: string | undefined): string => {
    switch (tipoEstudios) {
        case TipoEstudiosEnum.BACH:
            return 'Bachillerato'
        case TipoEstudiosEnum.ESO:
            return 'ESO'
        case TipoEstudiosEnum.FP:
            return 'Formación profesional'
        case TipoEstudiosEnum.GRADO:
            return 'Grado universitario'
        case TipoEstudiosEnum.POSGRADO:
            return 'Posgrado'
        case TipoEstudiosEnum.ACCESO_FP:
            return 'Cursos de acceso a FP'
        case TipoEstudiosEnum.ACCESO_UNI:
            return 'Cursos de acceso a Universidad'
        default:
            return ''
    }
}

const getCsvFieldLabel = (csvField: string): string => {
    switch (csvField) {
        case 'id':
            return 'Id'
        case 'dni':
            return 'Núm. documento'
        case 'dniAnonimizado':
            return 'Núm. documento anonim'
        case 'violencia_genero':
            return 'Violencia género'
        case 'tipoEstudios':
            return 'Tipo estudios'
        case 'centroEstudios':
            return 'Centro estudios'
        case 'familiaNumerosa':
            return 'Familia numerosa'
        case 'estado':
            return 'Estado'
        case 'cuantia':
            return 'Cuantía'
        case 'curso':
            return 'Curso'
        case 'nota':
            return 'Nota'
        case 'creditosMatriculados':
            return 'Créditos matric.'
        case 'creditosSuperados':
            return 'Créditos super.'
        case 'emancipado':
            return 'Emancipado'
        case 'orfandadSimple':
            return 'Orfandad simple'
        case 'orfandadAbsoluta':
            return 'Orfandad absoluta'
        case 'numeroCial':
            return 'Número cial'
        case 'puntuación':
            return 'Puntuación'
        default:
            return ''
    }
}

const getSiNo = (value: boolean): string => {
    if (value) {
        return 'Sí'
    }
    return 'No'
}

const getSiNoWithUndefined = (value: boolean | undefined | null): string => {
    if (value === undefined || value === null) {
        return '0'
    }
    if (value) {
        return 'Sí'
    }
    return 'No'
}


const getSiNoBool = (value: string): boolean => {
    if (value === 'Sí') {
        return true
    }
    return false
}

const getSiNoBoolWithUndefined = (value: string): boolean | undefined => {
    if (value === '0') {
        return undefined
    }
    if (value === 'Sí') {
        return true
    }
    return false
}

const isPresent = (dato: string, solicitud: Solicitud): boolean => {
    if (solicitud?.convocatoria?.beca?.datos) {
        const arrayAtributos: string[] = JSON.parse(solicitud?.convocatoria?.beca?.datos)
        return arrayAtributos.includes(dato)
    }
    return false
}

const calculateAge = (birthdate: Date | undefined) => {
    if (birthdate) {
        const birthdateObj = new Date(birthdate)
        const birthYear = birthdateObj.getFullYear()
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        let age = currentYear - birthYear
        let birthDateCurrentYear = birthdateObj
        birthDateCurrentYear.setFullYear(currentYear)
        if (currentDate < birthDateCurrentYear) {
            age = age - 1
        }
        return age
    } else {
        return undefined
    }
}

const calculateDniLetter = (dni: string) => {
    const dniNumber = parseInt(dni, 10)
    const dniResto = dniNumber % 23;
    switch (dniResto) {
        case 0:
            return 'T';
        case 1:
            return 'R';
        case 2:
            return 'W';
        case 3:
            return 'A';
        case 4:
            return 'G';
        case 5:
            return 'M';
        case 6:
            return 'Y';
        case 7:
            return 'F';
        case 8:
            return 'P';
        case 9:
            return 'D';
        case 10:
            return 'X';
        case 11:
            return 'B';
        case 12:
            return 'N';
        case 13:
            return 'J';
        case 14:
            return 'Z';
        case 15:
            return 'S';
        case 16:
            return 'Q';
        case 17:
            return 'V';
        case 18:
            return 'H';
        case 19:
            return 'L';
        case 20:
            return 'C';
        case 21:
            return 'K';
        case 22:
            return 'E';
        default:
            return undefined;
    }
}

const checkDniLetter = (dni: string) => {
    const dniNumber = dni.slice(0, dni.length)
    const dniLetter = dni.slice(dni.length - 1, dni.length)
    const correctLetter = calculateDniLetter(dniNumber)
    if (correctLetter === dniLetter) {
        return true
    } else {
        return false
    }
}

const getValidationFieldLabel = (field: string): string => {
    switch (field) {
        case 'id':
            return 'Id'
        case 'dni':
            return 'Núm. documento'
        case 'violencia_genero':
            return 'Violencia género'
        case 'tipoEstudios':
            return 'Tipo estudios'
        case 'centroEstudios':
            return 'Centro estudios'
        case 'otroCentroEstudios':
            return 'Otro centro de estudios'
        case 'notaFct':
            return 'Nota FTC'
        case 'primerAno':
            return 'Primer año grado'
        case 'cursoSuperado':
            return 'Curso superado'
        case 'creditosMatriculadosPasado':
            return 'Créditos matriculados en el curso pasado'
        case 'creditosSuperados':
            return 'Créditos superados'
        case 'creditosTotales':
            return 'Créditos totales'
        case 'creditosMatriculados':
            return 'Créditos matriculados'
        case 'capacidades':
            return 'Capacidades'
        case 'formacionComplementaria':
            return 'Formación complementaria'
        case 'preferenciaPracticas':
            return 'Preferencia de prácticas'
        case 'puedeFueraMunicipio':
            return 'Puede fuera del municipio'
        case 'puedeTeletrabajo':
            return 'Puede teletrabajo'
        case 'emancipado':
            return 'Emancipado'
        case 'orfandadSimple':
            return 'Orfandad simple'
        case 'orfandadAbsoluta':
            return 'Orfandad absoluta'
        case 'familiaNumerosa':
            return 'Familia numerosa';
        case 'solicitanteDiscapacidad':
            return 'Solicitante discapacidad';
        case 'padresDivorciados50':
            return 'Padres divorciados al 50';
        case 'isHospitalizado':
            return 'Hospitalizado';
        case 'isFederado':
            return 'Federaddo';
        case 'altaSegSocial':
            return 'Alta en la seguridad social';
        case 'consultaMatricula':
            return 'Consulta de Matrícula'
        case 'consultaMatriculaUniversidad':
            return 'Consulta de Matrícula Universidad'
        case 'titulos':
            return 'Consulta títulos'
        case 'consultaDiscapacidad':
            return 'Consulta discapacidad'
        case 'consultaIdentidad':
            return 'Consulta identidad'
        case 'violencia':
            return 'Violencia de género'
        case 'abuelos':
            return 'Abuelos'
        case 'discapacidad':
            return 'Discapacidad'
        case 'carneConducir':
            return 'Carnet conducir'
        case 'prestaciones':
            return 'Prestaciones'
        case 'familiaNumerosa':
            return 'Familia numerosa'
        case 'estado':
            return 'Estado'
        case 'cuantia':
            return 'Cuantía'
        case 'curso':
            return 'Curso'
        case 'nota':
            return 'Nota'
        case 'creditosMatriculados':
            return 'Créditos matric.'
        case 'creditosSuperados':
            return 'Créditos super.'
        case 'emancipado':
            return 'Emancipado'
        case 'orfandadSimple':
            return 'Orfandad simple'
        case 'orfandadAbsoluta':
            return 'Orfandad absoluta'
        case 'numeroCial':
            return 'Número cial'
        case 'puntuación':
            return 'Puntuación'
        case 'presencialidad':
            return 'Presencialidad'
        default:
            return ''
    }
}



export const utilMethods = {
    getSelectedOption, getIcon, getStringFromDate, isModificable, tagToReadableFormat, getParentescoLabel, getCsvFieldLabel, getSiNo, getSiNoBool,
    getTipoEstudiosLabel, getFamiliaNumerosaLabel, getPresencialidadLabel, isPresent, getEstadoCivilLabel, calculateAge, checkDniLetter, getSiNoWithUndefined,
    getSiNoBoolWithUndefined, getValidationFieldLabel
}

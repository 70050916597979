import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isLoading, ConvocatoriaModalProps, convocatoriaModal, reloadConvoAdmin, SendRentasModalProps, sendRentasModal, calculationResultModal, CalculationResultModalProps, ConfirmationModalProps, confirmationModal } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Convocatoria } from '../../types/entities';
import './../../index.css';
import { EstadoConvocatoria, EstadoRemesa } from '../../types/types';
import { MdDeleteOutline, MdFilePresent, MdOutlineModeEditOutline, MdPlayArrow } from 'react-icons/md';
import { FaLock, FaLockOpen } from 'react-icons/fa';


export function ConvocatoriaAdminCard({ convocatoria }: { convocatoria: Convocatoria }) {
    const navigate = useNavigate()
    const { updateConvocatoria, deleteConvocatoria, calcularConvocatoria } = useApi()
    const [convoAdminReload, setConvoAdminReload] = useRecoilState<boolean>(reloadConvoAdmin)
    const [convocatoriaModalInfo, setConvocatoriaModalInfo] = useRecoilState<ConvocatoriaModalProps>(convocatoriaModal)
    const [calculationResultModalInfo, setCalculationResultModalInfo] = useRecoilState<CalculationResultModalProps>(calculationResultModal)
    const [sendRentasModalInfo, setSendRentasModalInfo] = useRecoilState<SendRentasModalProps>(sendRentasModal)
    const [confirmationModalInfo, setConfirmationModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const onReloadConvocatorias = () => {
        setConvoAdminReload((old) => !old)
    }

    const onCambiarEstadoConvocatoria = async () => {
        if (convocatoria?.id) {
            setIsLoadingState(() => true)
            try {
                const changedConvocatoria = convocatoria
                if (changedConvocatoria.estado === EstadoConvocatoria.ABIERTA) {
                    changedConvocatoria.estado = EstadoConvocatoria.CERRADA
                } else {
                    changedConvocatoria.estado = EstadoConvocatoria.ABIERTA
                }
                await updateConvocatoria(changedConvocatoria)
            } catch (e) { }
            finally {
                onReloadConvocatorias()
                setIsLoadingState(() => false)
            }
        }
    }

    const onEditConvocatoria = () => {
        setConvocatoriaModalInfo(() => { return { visible: true, convocatoriaId: convocatoria.id } })
    }

    const onDeleteConvocatoria = async () => {
        setIsLoadingState(() => true)
        try {
            if (convocatoria.id) {
                await deleteConvocatoria(convocatoria.id)
            }
        } catch { }
        finally {
            setIsLoadingState(() => false)
            onReloadConvocatorias()
        }
    }

    const onOpenRentasModal = () => {
        setSendRentasModalInfo(() => { return { visible: true, convocatoriaId: convocatoria.id } })
    }

    const onCalculateConvocatoria = async() => {
        if (convocatoria.id) {
            const calcResult = await calcularConvocatoria(convocatoria.id)
            setCalculationResultModalInfo(() => { return { visible: true, calcuResult: calcResult }})    
        }
    }

    const onConfirmCalculateConvocatoria = ()=> {
        setConfirmationModalInfo(()=>{return {visible:true, goFunction: onCalculateConvocatoria, 
            text:'Se procederá al cálculo de las solicitudes de la convocatoria, ¿lo confirma?'}})
    }


    return (
        <div className='convoAdminCard'>
            <div className='convoAdminCard_beca'>{convocatoria?.beca?.nombre}</div>
            <div className='convoAdminCard_nombre'>{convocatoria?.nombre}</div>
            <div className='convoAdminCard_descripcion'>{convocatoria?.descripcion}</div>
            <div className='convoAdminCard_estado'>
                <button onClick={onCambiarEstadoConvocatoria}>
                    {convocatoria?.estado === EstadoRemesa.ABIERTA ?
                        <>{'Abierta'}<FaLockOpen /></>
                        :
                        <>{'Cerrada'}<FaLock /></>}
                </button>
            </div>
            <div className='convoAdminCard_buttonBox'>
                <button className='convoAdminCard_editBut' onClick={onEditConvocatoria}><MdOutlineModeEditOutline /></button>
                <button className='convoAdminCard_deleteBut' onClick={onDeleteConvocatoria}><MdDeleteOutline /></button>
                <button className='convoAdminCard_editBut' onClick={onConfirmCalculateConvocatoria}><MdPlayArrow/></button>
                <button className='convoAdminCard_editBut' onClick={onOpenRentasModal}><MdFilePresent /></button>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';

export function NumericField ({id, label, value, setValue, isNota}:{id:string, label:string, value:number|undefined, setValue?:React.Dispatch<React.SetStateAction<number|undefined>>, isNota?:boolean}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    return(
        <div className={'numericFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className='fieldAndBut'>
                <input id={id} type={'number'} step={isNota ? 0.01 : 1} value={value?value:0} disabled={modoConsulta || !setValue ? true : false} onChange={setValue ? (e)=>setValue(()=>parseFloat(e.target.value)):undefined}/>
            </div>
        </div>
    )

}
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SolicitudStepAcademicos } from '../components/organism/SolicitudStepAcademicos';
import { SolicitudStepEconomicos } from '../components/organism/SolicitudStepEconomicos';
import { SolicitudStepPersonales } from '../components/organism/SolicitudStepPersonales';
import { useApi } from '../hooks/useApi';
import { Solicitud } from '../types/entities';
import { EstadoSolicitud, RolEnum, SolicitudStep } from '../types/types';
import { consultaModo, isEditAtom, isLoading, isOriginalAtom, reloadSolicitud, solicitudInfo } from '../atoms/mainAtoms'
import { StepControl } from '../components/molecule/StepControl';
import { Link, useParams } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useAuth } from '../hooks/useAuth';
import { IoMdArrowRoundBack, IoMdSend } from 'react-icons/io'
import { SolicitudStepDeclaracion } from '../components/organism/SolicitudStepDeclaracion';
import { SolicitudStepAutorizaciones } from '../components/organism/SolicitudStepAutorizaciones';
import { DescargarCSV } from '../components/atom/DescargarCsv';
import { SolicitudStepDocumentos } from '../components/organism/SolicitudStepDocumentos';

export function CreadorSolicitud({ }: {}) {

    const [step, setStep] = useState<SolicitudStep>(SolicitudStep.PERSONALES)
    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)
    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const [isEdit, setIsEdit] = useRecoilState<boolean>(isEditAtom)
    const [isOriginal, setIsOriginal] = useRecoilState<boolean>(isOriginalAtom)

    const { getConvocatorias, getSolicitud } = useApi()
    const solicitudId = useParams().solicitudId
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const { rol, completedLoad } = useAuth()
    const [solicitudReload, setSolicitudReload] = useRecoilState<boolean>(reloadSolicitud)


    useEffect(() => {
        if (completedLoad) {
            onGetSolicitud()
            return () => {
                setModoConsulta(() => false)
                setIsEdit(()=>false)
                setIsOriginal(()=>false)
            }
        }
    }, [solicitudId, solicitudReload, completedLoad])

    const isConsulta = (solicitud: Solicitud) => {
        if (isEdit) {
            return false;
        }
        if (solicitud.estado !== EstadoSolicitud.SUBSANACION) {
            if (rol?.nombre === RolEnum.USUARIO) {
                if (solicitud.estado !== EstadoSolicitud.BORRADOR) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return true;
    }

    const onGetSolicitud = async () => {
        setIsLoadingState(() => true)
        if (solicitudId) {
            const solicitud = await getSolicitud(solicitudId)
            setSolicitud(() => solicitud)
            setModoConsulta(() => isConsulta(solicitud))
        } else {
            setSolicitud(() => undefined)
        }
        setIsLoadingState(() => false)
    }



    const getCurrentStep = () => {
        switch (step) {
            case SolicitudStep.PERSONALES:
                return <SolicitudStepPersonales />
            case SolicitudStep.ACADEMICOS:
                return <SolicitudStepAcademicos />
            case SolicitudStep.ECONOMICOS:
                return <SolicitudStepEconomicos />
            case SolicitudStep.DECLARACIONES:
                return <SolicitudStepDeclaracion />
            case SolicitudStep.AUTORIZACIONES:
                return <SolicitudStepAutorizaciones />
            case SolicitudStep.DOCUMENTOS:
                return <SolicitudStepDocumentos />
            default:
                return <SolicitudStepPersonales />
        }
    }

    return (
        completedLoad ?
            <div id='creadorSolicitudBox'>
                <div id='creadorSolicitudHeader'>
                    <h1>
                        {solicitud?.convocatoria?.nombre}
                    </h1>
                    <Link className='backLink' to={'/..'}><IoMdArrowRoundBack />{'Volver'}</Link>
                </div>
                {getCurrentStep()}
                <StepControl step={step} setStep={setStep} />
            </div> : <></>
    )
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import cabildoLogo from './../../resources/cabildo_logo.jpg';
import { FaUserAlt } from 'react-icons/fa'
import { FiLogOut, FiSettings } from 'react-icons/fi'
import { RolEnum } from '../../types/types';
import { useRecoilState } from 'recoil';
import { changePasswordModal, ChangePasswordModalProps, isLoading, usuarioModal, UsuarioModalProps } from '../../atoms/mainAtoms';
import { UsuarioModal } from './UsuarioModal';
import { ChangePasswordModal } from './ChangePasswordModal';

export function Header() {

    const navigate = useNavigate()
    const authInfo = useAuth()
    const { login, logout, getUsuario, getFamiliar, firmaDocumento } = useApi()
    const [mail, setMail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [advert, setAdvert] = useState<boolean>(false)
    const [usuarioModalInfo, setUsuarioModalInfo] = useRecoilState<UsuarioModalProps>(usuarioModal)
    const [changePasswordModalInfo, setChangePasswordModalInfo] = useRecoilState<ChangePasswordModalProps>(changePasswordModal)


    const [file, setFile] = useState(null);

    const onEditUsuario = async () => {
        if (authInfo.id) {
            const user = await getUsuario(authInfo.id)
            setUsuarioModalInfo(() => { return { visible: true, usuario: user, isMyUser: true } })
        }
    }


    const onLogin = async () => {
        if (mail !== '' && password !== '') {
            setAdvert(false)
            setIsLoadingState(() => true)
            try {
                await login(mail, password)
                authInfo.setIsAuth(() => true)
                navigate('/')
            } catch (e) {
                setAdvert(true)
            }
            setIsLoadingState(() => false)
        }
    }

    const onLogout = async () => {
        setIsLoadingState(() => true)
        try {
            await logout()
            authInfo.setIsAuth(() => false)
            navigate('/')
        } catch (e) {
        }
        setIsLoadingState(() => false)
    }



    return (
        <div id='header'>
            <img src={cabildoLogo} alt='logo cabildo fuerteventura' style={{ cursor: 'pointer' }} onClick={() => navigate('/')}></img>
            {authInfo.rol?.nombre !== undefined && authInfo.rol?.nombre === RolEnum.ADMIN ?
                <div id='menuBox'>
                    <button onClick={() => navigate('/remesas')}>Remesas</button>
                    <button onClick={() => navigate('/convocatoriasAdmin')}>Convocatorias</button>
                    <button onClick={() => navigate('/usuariosAdmin')}>Usuarios</button>
                </div>
                :
                undefined
            }
            <div className='loginOptions'>
                {authInfo.rol?.nombre === undefined ?
                    <>
                        <div className='loginWarning'>
                            {advert ?
                                "Error al iniciar sesión, ¿ha validado su cuenta?"
                                :
                                ""
                            }
                        </div>
                        <button onClick={() => setChangePasswordModalInfo(() => { return { visible: true } })}>
                            {'Cambiar contraseña'}
                        </button>
                    </>
                    :
                    undefined
                }
            </div>


            {authInfo.isAuth ?
                <div id='userInfo'>
                    <FaUserAlt />
                    <p>{authInfo.dni}</p>
                    <button className='headerBut' onClick={onEditUsuario}><FiSettings /></button>
                    <button className='headerBut' onClick={onLogout}><FiLogOut /></button>
                </div>
                :
                <div id='loginBox'>
                    <input type='text' placeholder='Email' id='mail' value={mail} onChange={(e) => setMail(e.target.value)} />
                    <input type='password' placeholder='Contraseña' id='password' value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                    <button id='loginBut' onClick={onLogin}>{'Acceder'}</button>
                </div>

            }
            <UsuarioModal />
            <ChangePasswordModal />
        </div>
    )
}
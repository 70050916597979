import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { SelectOption, EstadoCivilEnum, EstadoSolicitud } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';

export function EstadoField({ id, label, value, setValue, setFilterPropValue, reduced}: 
    { id: string, label: string, value: string | undefined, 
    setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, 
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, reduced?:boolean}) {

    const getOptions = ()=> {
        const options:SelectOption[] = []
        const labels = Object.values(EstadoSolicitud)
        Object.keys(EstadoSolicitud).forEach((label, index) =>{
            const key:EstadoSolicitud = labels[index]
            options.push({label: utilMethods.tagToReadableFormat(label), value: key})
        })
        return options
    }

    const options = getOptions()

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    

    
    return (
        <div className='selectFieldBox'>
            <label>{label}</label>
            <div className='filterAndBut'>
            <select style={{ width: reduced ? '60%' : '100%' }} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className='selectField' value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e?.target.value) : 
                    setFilterPropValue ? (e) => setFilterPropValue(e?.target.value === '0' ? undefined : e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar estado'}</option>
                    {options.map((option) =>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue("0", id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}
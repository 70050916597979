import React, { } from 'react';
import Select from 'react-select'

import { SelectOption } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { consultaModo } from '../../atoms/mainAtoms';
import { useRecoilState } from 'recoil';

export function SiNoNeutroField({ id, label, value, setValue, setFilterPropValue, disabled }: { 
    id: string,
    label: string, 
    value: boolean|undefined, 
    setValue?: React.Dispatch<React.SetStateAction<boolean|undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, 
    disabled?: boolean }) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='fieldAndBut'>
                <select style={{ width: '30%' }} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className='selectField' 
                    value={utilMethods.getSiNoWithUndefined(value)} onChange={setValue ? (e) => setValue(utilMethods.getSiNoBoolWithUndefined(e?.target.value)) 
                    : setFilterPropValue ? (e) => setFilterPropValue(utilMethods.getSiNoBoolWithUndefined(e?.target.value), id) : undefined}>
                    <option value={'0'}>{'Seleccione una respuesta'}</option>
                    <option selected={true} value={'No'}>{'No'}</option>
                    <option value={'Sí'}>{'Sí'}</option>
                </select>
            </div>
        </div>
    )
}
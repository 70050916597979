import { atom } from "recoil";
import {
  CalculoResultado,
  Convocatoria,
  ConvocatoriaConSolicitud,
  Solicitud,
  Usuario,
} from "../types/entities";

export const convocatoriaConSolSelected = atom<
  ConvocatoriaConSolicitud | undefined
>({
  key: "convocatoriaSelected",
  default: undefined,
});

export const userInfo = atom<Usuario | undefined>({
  key: "userInfo",
  default: undefined,
});

export const solicitudInfo = atom<Solicitud | undefined>({
  key: "solicitudInfo",
  default: undefined,
});

export const tipoEstudios = atom<Solicitud | undefined>({
  key: "tipoEstudios",
  default: undefined,
});

export const isLoading = atom<boolean>({
  key: "isLoading",
  default: false,
});

export interface FamiliarModalProps {
  visible: boolean;
  familiarId?: string | undefined;
}
export const familiarModal = atom<FamiliarModalProps>({
  key: "familiarModal",
  default: { visible: false },
});

export interface CalculationResultModalProps {
  visible: boolean;
  calcuResult?: CalculoResultado[];
}
export const calculationResultModal = atom<CalculationResultModalProps>({
  key: "calculationResultModal",
  default: { visible: false, calcuResult: undefined },
});

export interface ValidationErrorModalProps {
  visible: boolean;
  validationErrors?: string[];
}
export const validationErrorModal = atom<ValidationErrorModalProps>({
  key: "validationErrorModal",
  default: { visible: false, validationErrors: undefined },
});


export interface SendRentasModalProps {
  visible: boolean;
  convocatoriaId?: string | undefined;
}

export const sendRentasModal = atom<SendRentasModalProps>({
  key: "sendRentasModal",
  default: { visible: false },
});

export interface ConfirmationModalProps {
  visible: boolean;
  goFunction: any; 
  text:string;
}

export const confirmationModal = atom<ConfirmationModalProps>({
  key: "confirmationModal",
  default: { visible: false, text:'', goFunction:()=>true},
});
export interface MailModalProps {
  visible: boolean;
  solicitudId?: string | undefined;
}

export const mailModal = atom<MailModalProps>({
  key: "mailModal",
  default: { visible: false },
});

export interface ChangePasswordModalProps {
  visible: boolean;
}

export const changePasswordModal = atom<ChangePasswordModalProps>({
  key: "changePasswordModal",
  default: { visible: false },
});

export interface DenegacionModalProps {
  visible: boolean;
  solicitudId?: string | undefined;
}

export const denegacionModal = atom<DenegacionModalProps>({
  key: "denegacionModal",
  default: { visible: false },
});

export interface AutorizacionDatosModalProps {
  visible: boolean;
}

export const autorizacionDatosModal = atom<AutorizacionDatosModalProps>({
  key: "autorizacionDatosModal",
  default: { visible: false },
});

export interface ConvocatoriaModalProps {
  visible: boolean;
  convocatoriaId?: string | undefined;
}
export const convocatoriaModal = atom<ConvocatoriaModalProps>({
  key: "convocatoriaModal",
  default: { visible: false },
});

export interface UsuarioModalProps {
  visible: boolean;
  isMyUser?: boolean;
  usuario?: Usuario | undefined;
}
export const usuarioModal = atom<UsuarioModalProps>({
  key: "usuarioModal",
  default: { visible: false },
});

export interface CsvModalProps {
  visible: boolean;
  remesaId?: string | undefined;
}

export const csvModal = atom<CsvModalProps>({
  key: "csvModal",
  default: { visible: false },
});

export const reloadRemesas = atom<boolean>({
  key: "reloadRemesas",
  default: false,
});

export const consultaModo = atom<boolean>({
  key: "consultaModo",
  default: false,
});

export const isEditAtom = atom<boolean>({
  key: "isEditAtom",
  default: false,
});

export const isOriginalAtom = atom<boolean>({
  key: "isOriginalAtom",
  default: false,
});

export const reloadCuadroMandos = atom<boolean>({
  key: "reloadCuadroMandos",
  default: false,
});

export const reloadFamiliares = atom<boolean>({
  key: "reloadFamiliares",
  default: false,
});

export const reloadSolicitud = atom<boolean>({
  key: "reloadSolicitud",
  default: false,
});

export const reloadConvocatorias = atom<boolean>({
  key: "reloadConvocatorias",
  default: false,
});

export const reloadConvoAdmin = atom<boolean>({
  key: "reloadConvoAdmin",
  default: false,
});

export const reloadUserAdmin = atom<boolean>({
  key: "reloadUserAdmin",
  default: false,
});

export const reloadFiles = atom<boolean>({
  key: "reloadFiles",
  default: false,
});

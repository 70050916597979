import React from 'react';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';
import { useAuth } from '../../hooks/useAuth';
import { RolEnum, SizeEnum } from '../../types/types';
import CSS from 'csstype';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export function PhoneField({ id, label, value, setValue, size, isError}: {
    id: string, label: string,
    value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, size?:SizeEnum
    isError?:boolean}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const { rol } = useAuth()

    const getSize = ()=> {
        switch (size) {
            case SizeEnum.VB:
                return '100%'                
            case SizeEnum.B:
                return '70%'                
            case SizeEnum.M:
                return '40%'                
            case SizeEnum.S:
                return '20%'                
            default:
                return '70%'                
        }
    }


    return (
        <div className={'textFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={'fieldAndBut'}>
                <span className='phonePrefix'>{'+34'}</span>
                <input style={{width: getSize()}} id={id} type={'text'} value={value ? value : ''} 
                    disabled={modoConsulta || (!setValue) ? true : false} className={(isError ? ' errorField' : '')}
                    onChange={setValue ? (e) => {if (e.target.value.length > 9) {return;} else {setValue(() => e.target.value)}} : undefined} />
            </div>
        </div>
    )
}


import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { userInfo, reloadConvocatorias } from '../atoms/mainAtoms';
import { Paginacion } from '../components/molecule/Paginacion';
import { PublicConvocatoriaCard } from '../components/molecule/PublicConvocatoriaCard';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { Convocatoria, Usuario } from '../types/entities';
import cabildoLogo from './../resources/cabildo_logo.jpg';

export function Inicio() {
    const navigate = useNavigate()
    const [convocatoriasList, setConvocatoriasList] = useState<Convocatoria[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const { getConvocatoriasAbiertas } = useApi()


    useEffect(() => {
        onGetConvocatoriasAbiertas()
    }, [page])

    const onGetConvocatoriasAbiertas = async () => {
        const convocatoriasList = await getConvocatoriasAbiertas(page)
        setTotalPages(() => convocatoriasList.paginasTotales)
        setConvocatoriasList(() => convocatoriasList.content)
    }



    return (
        <>
            <div id='goLoginMessage'>
                <p>{'Para poder acceder a las becas, debe estar registrado.'}</p>
                <button onClick={() => navigate(`/registro`)}>
                    {'Registrarse'}
                </button>
            </div>
            <div id='publicConvocatoriaList'>
                {convocatoriasList.map((convocatoria) =>
                    <PublicConvocatoriaCard convocatoria={convocatoria} />
                )}
            </div>
            <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'3vh'} />
        </>
    )
}

import { useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { consultaModo, isLoading, solicitudInfo} from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { Solicitud } from '../../types/entities';
import { RolEnum } from '../../types/types';

export function SolicitudStepDeclaracion({ }: {}) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const [declaracionJurada, setDeclaracionJurada] = useState<boolean | undefined>(solicitud?.declaracion);
    const modoConsulta = useRecoilValue<boolean>(consultaModo)
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    const { rol } = useAuth()
    const { updateSolicitud, getSolicitud } = useApi()


    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false)
        } else {
            onSave()
        }
    }, [declaracionJurada])


    const onSave = async () => {

        if (solicitud) {
            setIsLoadingState(() => true)
            const newSolicitud: Solicitud = { ...solicitud }
            newSolicitud.declaracion = declaracionJurada
            try {
                const updatedSolicitud = await updateSolicitud(newSolicitud)
                setSolicitud(() => updatedSolicitud)
            } catch {
                
            }
            setIsLoadingState(() => false)
        }
    }


    return (
        <div id='stepBox'>
            <div id='stepHeader'>
                <h1>{'Declaración'}</h1>
            </div>
            <div id='centralForm'>
                <div id="declaracionContent">
                    <p>He leído y acepto las bases por las que se rige la presente convocatoria y declaro responsablemente: </p>
                    <ul>
                        <li>Todos los datos incorporados a la presente solicitud se ajustan a la realidad y que, de resultar probado, que no son ciertas las circunstancias declaradas, podré incurrir en responsabilidad por falsedad u ocultación.</li>
                        <li>Conozco que todos los documentos referentes a esta convocatoria (subsanación de documentos, resoluciones provisionales y definitivas) se publicarán en la página web del Cabildo Insular de Fuerteventura en el apartado: Becas, ayudas y subvenciones y en los Tablones de Anuncios Oficiales del Cabildo Insular.</li>
                        <li>Acepto la beca que se me pueda conceder, una vez dictada la resolución y acepto el abono de esta.</li>
                        <li>Comunicar, al Servicio de Educación del Cabildo de Fuerteventura, cualquier incidencia o eventualidad que pudiera producirse durante el transcurso de la actividad becada.</li>
                        <li>Facilitar toda la información que le sea requería por el Servicio de Educación del Cabildo de Fuerteventura y someterse a las actuaciones de comprobación y control que, con relación a la beca concedida, se practique por las entidades que correspondan y, en particular, por la Intervención General del Cabildo Insular de Fuerteventura, la Audiencia de Cuentas de Canarias o el Tribunal de Cuentas.</li>
                    </ul>
                </div>
                <div id='aceptoBox'>
                    <input
                        id='declaracionJurada'
                        type="checkbox"
                        disabled={modoConsulta}
                        checked={declaracionJurada}
                        onChange={(e) => setDeclaracionJurada(e.target.checked)} />
                    <label htmlFor='declaracionJurada'>Acepto las bases</label>

                </div>
            </div>
            {!modoConsulta  ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}

        </div>
    )
}

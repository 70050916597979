import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Rol, Usuario } from '../types/entities';
import { RolEnum } from '../types/types';
import { useApi } from './useApi';
import { useNavigate } from 'react-router-dom';

export interface AuthContext {
  isAuth: boolean
  dni?: string
  id?: string,
  rol?: Rol
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>,
  completedLoad: boolean
}

const AuthContext = createContext<AuthContext>({} as any)

export const useAuth = () => {
  const ctx = useContext(AuthContext)
  if (ctx === null) {
    throw new Error('useAuth() can only be used on the descendants of AuthProvider')
  } else {
    return ctx
  }
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  const [isAuth, setIsAuth] = useState(false)
  const [rol, setRol] = useState<Rol | undefined>(undefined)
  const [id, setId] = useState<string | undefined>(undefined)
  const [dni, setDni] = useState<string | undefined>(undefined)
  const [completedLoad, setCompletedLoad] = useState<boolean>(false)

  const { getUserInfo } = useApi()


  useEffect(() => {
    reloadUserInfo()
  }, [isAuth])
  

  const reloadUserInfo = async () => {
    setCompletedLoad(()=>false)
    let user: Usuario | undefined = undefined
    try {
      user = await getUserInfo()
    } catch (e) {
      cleanUserParams()
    }
    if (user !== undefined) {
      setUserParams(user)
    } else {
      cleanUserParams()
    }
    setCompletedLoad(()=>true)
  }

  const setUserParams = async (user: Usuario) => {
    if (user !== undefined) {
      setIsAuth(() => true)
      setDni(() => user.numeroDocumento)
      setId(() => user.id)
      setRol(() => user.rol)
    }
  }

  const cleanUserParams = async () => {
    setIsAuth(() => false)
    setDni(() => undefined)
    setId(() => undefined)
    setRol(() => undefined)
  }


  const value: AuthContext = {
    isAuth: isAuth,
    dni: dni,
    rol: rol,
    id: id,
    setIsAuth: setIsAuth,
    completedLoad: completedLoad
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

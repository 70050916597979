import React, {  } from 'react';

import { SelectOption } from '../../types/types';
import { useRecoilState } from 'recoil';
import {consultaModo} from '../../atoms/mainAtoms';

export function DiscapacidadField({ id, label, value, setValue, reduced, disabled}: {
    id: string, label: string,
    value?: number, setValue?: React.Dispatch<React.SetStateAction<number>>,
    reduced?:boolean, disabled?:boolean
}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    

    const options:SelectOption[] = [{label:'0%', value:0},{label:'33%', value:33},{label:'65%', value:65},{label:'75%', value:75}]

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '10%' : '20%' }} disabled={modoConsulta || !setValue ? true : false} className='selectField' value={value}
                    onChange={setValue ? (e) => setValue(parseInt(e?.target.value,10)) : undefined} >
                    {options.map((option) =>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { confirmationModal, ConfirmationModalProps, convocatoriaConSolSelected, reloadConvocatorias } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Convocatoria, ConvocatoriaConSolicitud } from '../../types/entities';
import { SolicitudEstado } from '../atom/SolicitudEstado';
import './../../index.css';
import { conf } from '../../conf'
import { EstadoSolicitud } from '../../types/types';
import { useAuth } from '../../hooks/useAuth';

export function ConvocatoriaCard({ convocatoriaConSol }: { convocatoriaConSol: ConvocatoriaConSolicitud }) {
    const navigate = useNavigate()
    const setConvocatoriaConSolSelected = useSetRecoilState<ConvocatoriaConSolicitud | undefined>(convocatoriaConSolSelected)
    const { createSolicitud, renunciar, enviarAlegaciones } = useApi()
    const [convocatoriasReload, setConvocatoriasReload] = useRecoilState<boolean>(reloadConvocatorias)
    const [confirmationModalInfo, setConfirmationModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)
    const {id} = useAuth()


    const onSelectConvocatoriaConSol = async () => {
        setConvocatoriaConSolSelected(() => convocatoriaConSol)
        if (convocatoriaConSol.solicitudId) {
            navigate(`/editorSolicitud/${convocatoriaConSol.solicitudId}`)
        } else {
            if (convocatoriaConSol?.convocatoria?.id && id) {
                const newSolicitud = await createSolicitud(convocatoriaConSol.convocatoria.id, id)
                navigate(`/editorSolicitud/${newSolicitud.id}`)    
            }
        }
    }

    const onRenunciar = async()=> {
        await renunciar(convocatoriaConSol.solicitudId)
        setConvocatoriasReload((old)=>!old)
    }

    const onConfirmarRenunciar = ()=> {
        setConfirmationModalInfo(()=>{return {visible:true, goFunction: onRenunciar, 
            text:'Se procederá a la renuncia de la beca, ¿lo confirma?'}})
    }


    return (
        <div className='convocatoriaCard' onClick={onSelectConvocatoriaConSol}>
            <p className='convocatoriaTitle'>{convocatoriaConSol.convocatoria.nombre}</p>
            <p className='convocatoriaDesc'>{convocatoriaConSol.convocatoria.descripcion}</p>
            <div className='estadoConBoton'>
                <SolicitudEstado estado={convocatoriaConSol.solicitudEstado} />
                {convocatoriaConSol.solicitudEstado === EstadoSolicitud.APROBADA && convocatoriaConSol.puedeRenunciar ?
                    <button className='renunciaBut' onClick={(e)=>{e.stopPropagation(); onConfirmarRenunciar()}}>Renunciar</button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { useApi } from '../../hooks/useApi';
import { SelectOption } from '../../types/types';

export function GestorField({ id, label, value, setFilterPropValue, reduced }:
    {
        id: string, label: string, value: string | undefined,
        setFilterPropValue?: (value: string | boolean | undefined, property: string) => void,
        reduced?: boolean
    }) {

    const [gestorList, setGestorList] = useState<SelectOption[]>([])
    const { getGestores } = useApi()


    useEffect(() => {
        onGetGestores()
    }, [])

    const onGetGestores = async () => {
        const gestores = await getGestores()
        const gestoresForSelect: SelectOption[] = []
        gestores.map((gestor) => {
            gestoresForSelect.push({ value: gestor.id, label: `${gestor.nombre} ${gestor.apellido1} ${gestor.apellido2}` })
        })
        setGestorList(() => gestoresForSelect)
    }

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '60%' : '100%' }} className='selectField' value={value}
                    onChange={setFilterPropValue ? (e) => setFilterPropValue(e?.target.value === '0' ? undefined : e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar gestor'}</option>
                    {gestorList.map((gestor) =>
                        <option value={gestor.value}>{gestor.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue(undefined, id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}
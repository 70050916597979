import React, { createContext, ReactNode, useContext } from 'react';
import { isBuffer } from 'util';
import { conf } from '../conf';
import { Beca, CalculoResultado, CentroEstudios, Convocatoria, ConvocatoriaConSolicitud, Familiar, Municipio, Notificacion, NotificacionYPlazo, Remesa, RequiredDocs, Rol, Solicitud, SolicitudCsvInfo, SolicitudFiltro, Usuario } from '../types/entities';
import { Page } from '../types/types';

export interface ApiContext {
    getConvocatorias: (page: number) => Promise<Page<Convocatoria>>
    getConvocatoriasAbiertas: (page: number) => Promise<Page<Convocatoria>>
    getUserInfo: () => Promise<Usuario>
    getMunicipios: () => Promise<Municipio[]>,
    getCentrosEstudios: () => Promise<CentroEstudios[]>,
    getCentrosEstudiosByTipo: (tipo: string) => Promise<CentroEstudios[]>,
    getRoles: () => Promise<Rol[]>,
    getBecas: () => Promise<Beca[]>
    getConvocatoriasUsuario: (page: number) => Promise<Page<ConvocatoriaConSolicitud>>
    getSolicitud: (solicitudId: string) => Promise<Solicitud>
    createSolicitud: (convocatoriaId: string, userId: string) => Promise<Solicitud>
    updateSolicitud: (solicitud: Solicitud) => Promise<Solicitud>
    makeSolicitudCopy: (solicitudId: string) => Promise<Solicitud>
    createConvocatoria: (convocatoria: Convocatoria) => Promise<Convocatoria>
    updateConvocatoria: (convocatoria: Convocatoria) => Promise<Convocatoria>
    deleteConvocatoria: (convocatoriaId: string) => void
    getConvocatoria: (convocatoriaId: string) => Promise<Convocatoria>
    createFamiliar: (solicitudId: string, familiar: Familiar) => Promise<Familiar>
    updateFamiliar: (familiar: Familiar) => Promise<Familiar>
    getFamiliaresSolicitud: (solicitudId: string) => Promise<Familiar[]>
    getFamiliar: (familiarId: string) => Promise<Familiar>
    getSolicitudesFiltro: (filtro: SolicitudFiltro, page: number) => Promise<Page<Solicitud>>
    getNotificacion: (id: string, codigo: string) => Promise<Notificacion>
    getRemesasCerradas: (esLocal: boolean, esConcedida: boolean, page: number) => Promise<Page<Remesa>>
    getRemesaAbierta: (esLocal: boolean, esConcedida: boolean) => Promise<Remesa>
    closeRemesa: (esLocal: boolean, esConcedida: boolean) => Promise<Remesa>
    getRemesaSolicitudes: (remesaId: string, page: number) => Promise<Page<Solicitud>>
    login: (user: string, password: string) => void
    registerUser: (user: Usuario) => Promise<Usuario>
    validateAccount: (email: string, code: string) => void
    changePassword: (email: string, code: string, password: string) => void
    startChangePassword: (email: string) => void
    sendNotas: (solicitudId: string, nota: string) => Promise<Solicitud>
    corregirSolicitud: (solicitudId: string) => Promise<Solicitud>
    getGestores: () => Promise<Usuario[]>
    actualizaSolicitudGestor: (solicitud: Solicitud) => Promise<boolean>
    deleteFamiliar: (familiarId: string) => void
    logout: () => void
    renunciar: (solicitudId: string) => void
    enviarAlegaciones: (solicitudId: string, alegacion: string) => void
    getRemesaCsv: (remesaId: string, fields: string[]) => Promise<SolicitudCsvInfo[]>
    enviarSolicitud: (solicitudId: string) => void
    confirmarEnvioSolicitud: (solicitudId: string, codigo: string) => void
    calcularConvocatoria: (convocatoriaId: string) => Promise<CalculoResultado[] | undefined>
    // aprobarSolicitud: (solicitudI    d: string) => void
    // denegarSolicitud: (solicitudId: string, estado: string, causa?: string) => void
    confirmarDenegacion: (solicitudId: string) => void
    saveFiles: (solicitudId: string, files: Array<File>) => Promise<boolean>,
    downloadSolicitudFile: (fileId: string) => Promise<Blob>,
    getFileName: (solicitudId: string, fileType: string) => Promise<string>,
    mailSolicitud: (solicitudId: string, messageBody: string) => void,
    getUsuariosAdmin: (page: number) => Promise<Page<Usuario>>
    updateUsuario: (usuario: Usuario) => Promise<Usuario>
    deleteUsuario: (usuarioId: string) => void
    getUsuario: (usuarioId: string) => Promise<Usuario>
    getRequiredDocs: (solicitudId: string) => Promise<RequiredDocs>
    getNotificacionPdf: (notificacionId: string) => void
    firmaDocumento: (file: any) => Promise<Notificacion>
    sendRentas: (convocatoriaId: string, file: File) => Promise<boolean>,
    getLastNotificationByType: (solicitudId: string, type: number) => Promise<NotificacionYPlazo>
}

const ApiContext = createContext<ApiContext>({} as any)

export const useApi = () => {
    const ctx = useContext(ApiContext)
    if (ctx === null) {
        throw new Error('useApi() can only be used on the descendants of ApiProvider')
    } else {
        return ctx
    }
}

const actualizaSolicitudGestor = async (solicitud: Solicitud): Promise<boolean> => {
    const url = `${conf.mainApiUrl}public/actualizagestor`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(solicitud),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al validar la cuenta')
    }
    return result
}

const getGestores = async (): Promise<Usuario[]> => {
    const url = `${conf.mainApiUrl}public/gestores`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result: any[] = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las convocatorias')
    }
    return result
}

const getConvocatorias = async (page: number): Promise<Page<Convocatoria>> => {
    const url = `${conf.mainApiUrl}private/convocatorias?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las convocatorias')
    }
    return result
}

const getConvocatoriasAbiertas = async (page: number): Promise<Page<Convocatoria>> => {
    const url = `${conf.mainApiUrl}private/convocatorias/abiertas?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las convocatorias abiertas')
    }
    return result
}


const getBecas = async (): Promise<Beca[]> => {
    const url = `${conf.mainApiUrl}private/becas`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las becas')
    }
    return result
}


const getUserInfo = async (): Promise<Usuario> => {
    const url = `${conf.mainApiUrl}public/myuser`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la info del usuario')
    }
    return result
}


const getMunicipios = async (): Promise<Municipio[]> => {
    const url = `${conf.mainApiUrl}public/municipios`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los municipios')
    }
    return result
}

const getCentrosEstudios = async (): Promise<CentroEstudios[]> => {
    const url = `${conf.mainApiUrl}public/centrosEstudios`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los centros de estudios')
    }
    return result
}

const getCentrosEstudiosByTipo = async (tipo: string): Promise<CentroEstudios[]> => {
    const url = `${conf.mainApiUrl}public/centroEstudios/tipo?tipo=${tipo}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los centros de estudios')
    }
    return result
}

const getRoles = async (): Promise<Rol[]> => {
    const url = `${conf.mainApiUrl}private/roles`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los roles')
    }
    return result
}



const getConvocatoriasUsuario = async (page: number): Promise<Page<ConvocatoriaConSolicitud>> => {
    const url = `${conf.mainApiUrl}private/convocatorias/abiertas/usuario?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las convocatorias')
    }
    return result
}


const getSolicitud = async (solicitudId: string): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}public/solicitud?id=${solicitudId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la solicitud')
    }
    return result
}

const getUsuario = async (usuarioId: string): Promise<Usuario> => {
    const url = `${conf.mainApiUrl}private/user/${usuarioId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener el usuario')
    }
    return result
}



const createSolicitud = async (convocatoriaId: string, userId: string): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}private/convocatoria/${convocatoriaId}/solicitud?userId=${userId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al crear la solicitud')
    }
    return result
}

const updateSolicitud = async (solicitud: Solicitud): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}private/solicitud/editar`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(solicitud),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al actualizar la solicitud')
    }
    return result
}

const makeSolicitudCopy = async (solicitudId: string): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}private/solicitud/copia/${solicitudId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al realizar la copia de la solicitud')
    }
    return result
}



const createConvocatoria = async (convocatoria: Convocatoria): Promise<Convocatoria> => {
    const url = `${conf.mainApiUrl}private/convocatoria`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(convocatoria),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al crear la convocatoria')
    }
    return result
}

const updateConvocatoria = async (convocatoria: Convocatoria): Promise<Convocatoria> => {
    const url = `${conf.mainApiUrl}private/convocatoria`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(convocatoria),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al actualizar la convocatoria')
    }
    return result
}


const deleteConvocatoria = async (convocatoriaId: string) => {
    const url = `${conf.mainApiUrl}private/convocatoria/${convocatoriaId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'DELETE',
        headers: new Headers({
            'content-type': 'application/json',
        }),
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al borrar la convocatoria')
    }
}

const getConvocatoria = async (convocatoriaId: string): Promise<Convocatoria> => {
    const url = `${conf.mainApiUrl}private/convocatoria?id=${convocatoriaId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la convocatoria')
    }
    return result
}


const createFamiliar = async (solicitudId: string, familiar: Familiar): Promise<Familiar> => {
    const url = `${conf.mainApiUrl}public/solicitud/${solicitudId}/familiares`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        }),
        body: JSON.stringify(familiar)
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al crear el familiar')
    }
    return result
}

const updateFamiliar = async (familiar: Familiar): Promise<Familiar> => {
    const url = `${conf.mainApiUrl}public/familiar`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(familiar),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al actualizar el familiar')
    }
    return result
}


const deleteFamiliar = async (familiarId: string) => {
    const url = `${conf.mainApiUrl}public/familiar/${familiarId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'DELETE',
        headers: new Headers({
            'content-type': 'application/json',
        }),
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al borrar el familiar')
    }
}



const getFamiliaresSolicitud = async (solicitudId: string): Promise<Familiar[]> => {
    const url = `${conf.mainApiUrl}public/solicitud/${solicitudId}/familiares`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los familiares')
    }
    return result
}

const getFamiliar = async (familiarId: string): Promise<Familiar> => {
    const url = `${conf.mainApiUrl}public/familiar/${familiarId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener el familiar')
    }
    return result
}

const getSolicitudesFiltro = async (filtro: SolicitudFiltro, page: number): Promise<Page<Solicitud>> => {
    const url = `${conf.mainApiUrl}public/solicitud/filtro?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(filtro),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las solicitudes filtradas')
    }
    return result
}

const getNotificacion = async (id: string, codigo: string): Promise<Notificacion> => {
    const url = `${conf.mainApiUrl}public/solicitud/notificacion/${id}?codigo=${codigo}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la notificación')
    }
    return result
}

const getRemesasCerradas = async (esLocal: boolean, esConcedida: boolean, page: number): Promise<Page<Remesa>> => {
    const url = `${conf.mainApiUrl}public/remesa/cerradas?esLocal=${esLocal}&esConcedida=${esConcedida}&page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las remesas cerradas')
    }
    return result
}

const getRemesaAbierta = async (esLocal: boolean, esConcedida: boolean): Promise<Remesa> => {
    const url = `${conf.mainApiUrl}public/remesa/abierta?esLocal=${esLocal}&esConcedida=${esConcedida}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la remesa abierta')
    }
    return result
}

const closeRemesa = async (esLocal: boolean, esConcedida: boolean): Promise<Remesa> => {
    const url = `${conf.mainApiUrl}public/remesa/cerrar?esLocal=${esLocal}&esConcedida=${esConcedida}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al cerrar la remesa')
    }
    return result
}

const getRemesaSolicitudes = async (remesaId: string, page: number): Promise<Page<Solicitud>> => {
    const url = `${conf.mainApiUrl}public/remesa/${remesaId}/solicitudes?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener las solicitudes de la remesa')
    }
    return result
}

const login = async (user: string, password: string) => {
    const url = `${conf.mainApiUrl}public/user?user=${user}&password=${password}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al realizar el login')
    }
    return result
}


const registerUser = async (user: Usuario): Promise<Usuario> => {
    const url = `${conf.mainApiUrl}public/user/registro`
    const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al registrar el usuario')
    }
    return result
}

const validateAccount = async (email: string, code: string) => {
    const url = `${conf.mainApiUrl}public/user/validacion/${code}/${email}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al validar la cuenta')
    }
}

const startChangePassword = async (email: string) => {
    const url = `${conf.mainApiUrl}public/user/password?email=${email}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al enviar la petición de cambio de contraseña')
    }
}

const changePassword = async (email: string, code: string, password: string) => {
    const url = `${conf.mainApiUrl}public/user/cambioPassword/${email}/${code}/${password}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al cambiar la contraseña')
    }
}

const sendNotas = async (solicitudId: string, nota: string): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}public/solicitud/${solicitudId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PATCH',
        body: nota
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al guardar las notas')
    }
    return result
}

const cambiarEstadoSolicitud = async (solicitudId: string, estado: string, causa?: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/cambiarEstado`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PATCH',
        body: JSON.stringify({ estado: estado, causa: causa }),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al actualizar el estado')
    }
}


const corregirSolicitud = async (solicitudId: string): Promise<Solicitud> => {
    const url = `${conf.mainApiUrl}public/solicitud/${solicitudId}/corregir`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PATCH',
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al corregir la solicitud')
    }
    return result
}

const logout = async () => {
    const url = `${conf.mainApiUrl}public/user/logout`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al cerrar sesión')
    }
}

const renunciar = async (solicitudId: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/renuncia`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al renunciar la beca')
    }
}

const enviarAlegaciones = async (solicitudId: string, alegacion: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/alegacion`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        body: alegacion,
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al presentar alegaciones')
    }
}

const getRemesaCsv = async (remesaId: string, fields: string[]): Promise<SolicitudCsvInfo[]> => {
    const url = `${conf.mainApiUrl}public/solicitud/${remesaId}/csv`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(fields),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener el CSV de la remesa')
    }
    return result
}

const enviarSolicitud = async (solicitudId: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/firma`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    const res = await fetch(url, options)
    if (!res.ok) {
        if (res.status === 400) {
            const response = await res.json()
            const e = new Error(response); 
            e.name = 'invalidField'
            throw e
        }
        throw Error('Error al enviar la solicitud')
    }
}

const confirmarEnvioSolicitud = async (solicitudId: string, codigo: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/confirmarFirma/${codigo}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al confirmar la solicitud')
    }
}

const calcularConvocatoria = async (convocatoriaId: string): Promise<CalculoResultado[] | undefined> => {
    const url = `${conf.mainApiUrl}private/convocatoria/${convocatoriaId}/calculo`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resultado = await res.json()
        return resultado;
    } catch (e) {
        return undefined
    }
}




// const aprobarSolicitud = async (solicitudId: string) => {
//     const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/aprobacion`
//     const options: RequestInit = {
//         credentials: 'include',
//         method: 'POST',
//         headers: new Headers({
//             'content-type': 'application/json',
//         })
//     }
//     try {
//         const res = await fetch(url, options)
//         if (!res.ok) {
//             throw new Error(JSON.stringify(res))
//         }
//     } catch (e) {
//         throw Error('Error al aprobar la solicitud')
//     }
// }

// const denegarSolicitud = async (solicitudId: string, causa: string) => {
//     const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/denegacion`
//     const options: RequestInit = {
//         credentials: 'include',
//         method: 'POST',
//         headers: new Headers({
//             'content-type': 'application/json',
//         body: causa
//         })
//     }
//     try {
//         const res = await fetch(url, options)
//         if (!res.ok) {
//             throw new Error(JSON.stringify(res))
//         }
//     } catch (e) {
//         throw Error('Error al denegar la solicitud')
//     }
// }

const confirmarDenegacion = async (solicitudId: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/confirmarDenegacion`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al denegar la solicitud')
    }
}


const firmaDocumento = async (file: File): Promise<Notificacion> => {

    const url = `${conf.mainApiUrl}private/solicitud/notificacion/pruebaJose`
    let body = new FormData();
    if (file != null)
        body.append('file', file);



    const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: new Headers({
        })
    }

    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        console.log("11111")
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la notificación')
    }
    return result
}

const saveFiles = async (solicitudId: string, files: Array<File>): Promise<boolean> => {
    const url = `${conf.mainApiUrl}private/solicitud`
    let body = new FormData();

    files.map((file, index) => {
        body.append('files', file);
    })
    body.append("solicitudId", solicitudId)

    const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: new Headers({
        })
    }
    console.log(url)
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al guardar los archivos de la solicitud')
    }
    return true
}



const downloadSolicitudFile = async (fileId: string): Promise<Blob> => {
    const url = `${conf.mainApiUrl}private/archivo?fileId=${fileId}`
    const options = {
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/pdf',
        })
    }
    console.log(url)
    let result
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        result = await res.blob()
        let urlNew = window.URL.createObjectURL(result);
        let a = document.createElement('a');
        a.href = urlNew;
        a.download = fileId;
        a.click();

    } catch (e) {
        throw Error('Error al obtener el archivo')
    }
    return result
}

const getFileName = async (solicitudId: string, fileType: string): Promise<string> => {
    const url = `${conf.mainApiUrl}private/archivo/nombre?fileId=${`${solicitudId}_${fileType}`}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/text',
        })
    }
    let result
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.text()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener el nombre del archivo')
    }
    return result
}

const mailSolicitud = async (solicitudId: string, messageBody: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/mail`
    const options: RequestInit = {
        credentials: 'include',
        method: 'POST',
        body: messageBody,
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al enviar el mail sobre la solicitud')
    }
}

const getUsuariosAdmin = async (page: number): Promise<Page<Usuario>> => {
    const url = `${conf.mainApiUrl}private/users?page=${page}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los usuarios')
    }
    return result
}

const updateUsuario = async (usuario: Usuario): Promise<Usuario> => {
    const url = `${conf.mainApiUrl}private/users?id`
    const options: RequestInit = {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(usuario),
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al actualizar el usuario')
    }
    return result
}

const deleteUsuario = async (usuarioId: string) => {
    const url = `${conf.mainApiUrl}private/users/${usuarioId}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'DELETE',
        headers: new Headers({
            'content-type': 'application/json',
        }),
    }
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al borrar el usuario')
    }
}

const getRequiredDocs = async (solicitudId: string): Promise<RequiredDocs> => {
    const url = `${conf.mainApiUrl}private/solicitud/${solicitudId}/required`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener los documentos requeridos por la solicitud')
    }
    return result
}

const getNotificacionPdf = async (notificacionId: string) => {
    const url = `${conf.mainApiUrl}private/solicitud/notificacion/${notificacionId}/pdf`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        result = await res.blob()
        let urlNew = window.URL.createObjectURL(result);
        let a = document.createElement('a');
        a.href = urlNew;
        a.download = "fileId";
        a.click();
    } catch (e) {
        throw Error('Error al obtener el pdf de la notificación')
    }
}

const sendRentas = async (convocatoriaId: string, file: File): Promise<boolean> => {
    const url = `${conf.mainApiUrl}private/convocatoria/rentas`
    let body = new FormData();

    body.append('file', file);
    body.append("convocatoriaId", convocatoriaId)

    const options: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: body,
        headers: new Headers({
        })
    }
    console.log(url)
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
    } catch (e) {
        throw Error('Error al guardar las rentas de la convocatoria')
    }
    return true
}

const getLastNotificationByType = async (solicitudId: string, type: number): Promise<NotificacionYPlazo> => {
    const url = `${conf.mainApiUrl}private/notificacion?solicitudId=${solicitudId}&type=${type}`
    const options: RequestInit = {
        credentials: 'include',
        method: 'GET',
        headers: new Headers({
            'content-type': 'application/json',
        })
    }
    let result = []
    try {
        const res = await fetch(url, options)
        if (!res.ok) {
            throw new Error(JSON.stringify(res))
        }
        const resObject = await res.json()
        result = resObject
    } catch (e) {
        throw Error('Error al obtener la notificacion')
    }
    return result

}




export const ApiProvider = ({ children }: { children: ReactNode }) => {

    const value: ApiContext = {
        getConvocatorias: getConvocatorias,
        getConvocatoriasAbiertas: getConvocatoriasAbiertas,
        getUserInfo: getUserInfo,
        getMunicipios: getMunicipios,
        getCentrosEstudios: getCentrosEstudios,
        getCentrosEstudiosByTipo: getCentrosEstudiosByTipo,
        getRoles: getRoles,
        getBecas: getBecas,
        getConvocatoriasUsuario: getConvocatoriasUsuario,
        getSolicitud: getSolicitud,
        createSolicitud: createSolicitud,
        updateSolicitud: updateSolicitud,
        makeSolicitudCopy: makeSolicitudCopy,
        createConvocatoria: createConvocatoria,
        updateConvocatoria: updateConvocatoria,
        deleteConvocatoria: deleteConvocatoria,
        getConvocatoria: getConvocatoria,
        createFamiliar: createFamiliar,
        updateFamiliar: updateFamiliar,
        getFamiliar: getFamiliar,
        getFamiliaresSolicitud: getFamiliaresSolicitud,
        getSolicitudesFiltro: getSolicitudesFiltro,
        getNotificacion: getNotificacion,
        getRemesasCerradas: getRemesasCerradas,
        getRemesaAbierta: getRemesaAbierta,
        closeRemesa: closeRemesa,
        getRemesaSolicitudes: getRemesaSolicitudes,
        login: login,
        registerUser: registerUser,
        validateAccount: validateAccount,
        changePassword: changePassword,
        startChangePassword: startChangePassword,
        sendNotas: sendNotas,
        corregirSolicitud: corregirSolicitud,
        getGestores: getGestores,
        actualizaSolicitudGestor: actualizaSolicitudGestor,
        deleteFamiliar: deleteFamiliar,
        logout: logout,
        renunciar: renunciar,
        enviarAlegaciones: enviarAlegaciones,
        getRemesaCsv: getRemesaCsv,
        enviarSolicitud: enviarSolicitud,
        confirmarEnvioSolicitud: confirmarEnvioSolicitud,
        calcularConvocatoria: calcularConvocatoria,
        // aprobarSolicitud: aprobarSolicitud,
        // denegarSolicitud: denegarSolicitud,
        confirmarDenegacion: confirmarDenegacion,
        saveFiles: saveFiles,
        downloadSolicitudFile: downloadSolicitudFile,
        getFileName: getFileName,
        mailSolicitud: mailSolicitud,
        getUsuariosAdmin: getUsuariosAdmin,
        updateUsuario: updateUsuario,
        deleteUsuario: deleteUsuario,
        getUsuario: getUsuario,
        getRequiredDocs: getRequiredDocs,
        getNotificacionPdf: getNotificacionPdf,
        firmaDocumento: firmaDocumento,
        sendRentas: sendRentas,
        getLastNotificationByType: getLastNotificationByType
    }

    return (
        <ApiContext.Provider value={value}>
            {children}
        </ApiContext.Provider>
    )
}

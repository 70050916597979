import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { convocatoriaModal, ConvocatoriaModalProps, isLoading, reloadConvoAdmin } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineClose, AiOutlineSave } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { Convocatoria } from '../../types/entities';
import { EstadoRemesa, SizeEnum } from '../../types/types';
import { MdErrorOutline } from 'react-icons/md';
import { BecaField } from '../atom/BecaField';
import { TextAreaField } from '../atom/TextAreaField';
import { NumericField } from '../atom/NumericField';


export function ConvocatoriaModal() {

    const [modalInfo, setModalInfo] = useRecoilState<ConvocatoriaModalProps>(convocatoriaModal)

    const [nombre, setNombre] = useState<string | undefined>(undefined)
    const [descripcion, setDescripcion] = useState<string | undefined>(undefined)
    const [becaId, setBecaId] = useState<string | undefined>(undefined)
    const [estado, setEstado] = useState<string | undefined>(undefined)
    const [cuantia, setCuantia] = useState<number | undefined>(undefined)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const [convoAdminReload, setConvoAdminReload] = useRecoilState<boolean>(reloadConvoAdmin);
    const [warning, setWarning] = useState<string | undefined>(undefined)


    const { getConvocatoria, updateConvocatoria, createConvocatoria } = useApi()

    useEffect(() => {
        if (modalInfo.convocatoriaId) {
            onGetConvocatoria(modalInfo.convocatoriaId)
        }
    }, [modalInfo])


    const onSave = async () => {
        setIsLoadingState(() => true)
        setWarning(() => undefined)
        try {
            if (nombre && descripcion && becaId) {
                if (modalInfo.convocatoriaId) {
                    const updatedConvocatoria: Convocatoria = {
                        id: modalInfo.convocatoriaId, nombre: nombre, descripcion: descripcion,
                        estado: estado
                    }
                    await updateConvocatoria(updatedConvocatoria)

                } else {
                    if (becaId) {
                        const newConvocatoria: Convocatoria = {
                            nombre: nombre, descripcion: descripcion,
                            estado: EstadoRemesa.CERRADA, cuantia: cuantia, beca: { id: becaId }
                        }
                        await createConvocatoria(newConvocatoria)
                    }
                }
                setConvoAdminReload((old) => !old)
                onClear()
                setModalInfo(() => { return { visible: false } })
            } else {
                setWarning(() => 'Faltan datos por rellenar')
            }
        } catch { }
        finally {
            setIsLoadingState(() => false)
        }
    }

    const onGetConvocatoria = async (convocatoriaId: string) => {
        const convocatoria = await getConvocatoria(convocatoriaId)
        setNombre(() => convocatoria.nombre)
        setDescripcion(() => convocatoria.descripcion)
        setEstado(() => convocatoria.estado)
        setCuantia(() => convocatoria.cuantia)
        setBecaId(() => convocatoria.beca?.id)
    }

    const onClear = () => {
        setNombre(() => undefined)
        setDescripcion(() => undefined)
        setEstado(() => undefined)
        setCuantia(() => undefined)
        setBecaId(() => undefined)
        setWarning(() => undefined)
    }


    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.M}>
                <>
                    <div id='modalHeader'>
                        <h1>{modalInfo.convocatoriaId ? 'Editar convocatoria' : 'Añadir convocatoria'}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear(); setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalForm'>
                        <div className='formConvoModal'>
                            <TextField id='nombre' label='Nombre' value={nombre} setValue={setNombre} size={SizeEnum.VB}></TextField>
                            <TextAreaField id='descripcion' label='Descripción' value={descripcion} setValue={setDescripcion} maxCar={300} size={SizeEnum.VB}></TextAreaField>
                            <NumericField id='cuantia' label='Cuantia' value={cuantia} setValue={modalInfo.convocatoriaId ? undefined : setCuantia}/>
                            <BecaField id='beca' label='Beca' value={becaId} setValue={modalInfo.convocatoriaId ? undefined : setBecaId} reduced={true} />
                        </div>
                        {warning ?
                            <p className='warningMsg'><MdErrorOutline /> {warning}</p>
                            :
                            undefined
                        }
                        <div id='saveButModal'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div>
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
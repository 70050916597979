import React, { useEffect, useState } from 'react';
import { RiDeleteBack2Line } from 'react-icons/ri';

import { useApi } from '../../hooks/useApi';
import { SelectOption } from '../../types/types';

export function BecaField({ id, label, value, idValue, setValue, setFilterPropValue, reduced }: {
    id: string, label: string,
    value?: string, idValue?: string,
    setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void,
    reduced?: boolean
}) {

    const [becasList, setBecasList] = useState<SelectOption[]>([])
    const { getBecas } = useApi()

    useEffect(() => {
        onGetBecas()
    }, [])

    const onGetBecas = async () => {
        const becas = await getBecas()
        const becasForSelect: SelectOption[] = []
        becas.map((beca) => {
            becasForSelect.push({ value: beca.id, label: beca.nombre })
        })
        setBecasList(() => becasForSelect)
    }

    const getOptionFromValue = (id: string | undefined): SelectOption => {
        const beca = becasList.find((beca) => beca.value === id)
        return { value: beca?.value, label: beca?.label }
    }

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '60%' : '100%' }} disabled={setValue || setFilterPropValue ? false : true} className='selectField' value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e?.target.value) : 
                    setFilterPropValue ? (e) => setFilterPropValue(e?.target.value === '0' ? undefined : e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar beca'}</option>
                    {becasList.map((beca) =>
                        <option value={beca.value}>{beca.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue("0", id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

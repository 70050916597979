import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { convocatoriaConSolSelected, reloadCuadroMandos, isLoading, MailModalProps, mailModal, isEditAtom, isOriginalAtom, DenegacionModalProps, denegacionModal, confirmationModal, ConfirmationModalProps } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { ConvocatoriaConSolicitud, Notificacion, NotificacionYPlazo, Solicitud, Usuario } from '../../types/entities';
import './../../index.css';
import { utilMethods } from '../../utils/utilMethods';
import { useEffect, useState } from 'react';
import { EstadoSolicitud, NotificacionType } from '../../types/types';
import { stylesVars } from '../../utils/stylesVars';
import { AiOutlineMail } from 'react-icons/ai';
import { IoMdWarning } from 'react-icons/io';
import { MdOutlineModeEditOutline, MdOutlineFileCopy } from 'react-icons/md';
import { SolicitudEstado } from '../atom/SolicitudEstado';


export function SolicitudCard({ solicitud, gestores }: { solicitud: Solicitud, gestores: Usuario[] }) {
    const navigate = useNavigate()
    const [notas, setNotas] = useState<string | undefined>(solicitud.notas);
    const { sendNotas, actualizaSolicitudGestor, confirmarDenegacion,
        makeSolicitudCopy, getLastNotificationByType } = useApi()
    const [cuadroMandosReload, setCuadroMandosReload] = useRecoilState<boolean>(reloadCuadroMandos)
    const [mailModalInfo, setMailModalInfo] = useRecoilState<MailModalProps>(mailModal)
    const [confirmationModalInfo, setConfirmationModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)
    const [denegacionModalInfo, setDenegacionModalInfo] = useRecoilState<DenegacionModalProps>(denegacionModal)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const setIsEdit = useSetRecoilState<boolean>(isEditAtom)
    const setIsOriginal = useSetRecoilState<boolean>(isOriginalAtom)
    const [showCausa, setShowCausa] = useState<boolean>(false);
    const [notificacionDenegacion, setNotificacionDenegacion] = useState<NotificacionYPlazo | undefined>(undefined)

    useEffect(() => {
        if (solicitud.estado === EstadoSolicitud.PENDIENTE_DESCARTAR) {
            onLoadDenyNotification()
        }
    }, [])

    const onLoadDenyNotification = async () => {
        if (solicitud.id) {
            const denyNotification = await getLastNotificationByType(solicitud.id, NotificacionType.DENEGACION)
            setNotificacionDenegacion(() => denyNotification)
        }
    }

    const onReloadCuadro = () => {
        setCuadroMandosReload((old) => !old)
    }

    const onSelectConvocatoriaConSol = async () => {
        navigate(`/editorSolicitud/${solicitud.id}`)
    }

    const onEditSolicitud = async () => {
        setIsEdit(() => true)
        if (solicitud.original) {
            navigate(`/editorSolicitud/${solicitud.id}`)
        } else {
            if (solicitud.id) {
                const newSolicitud = await makeSolicitudCopy(solicitud.id)
                setIsOriginal(() => true)
                navigate(`/editorSolicitud/${newSolicitud.id}`)
            }
        }
    }

    const onViewOriginal = async () => {
        setIsOriginal(() => true)
        if (solicitud.original) {
            navigate(`/editorSolicitud/${solicitud.original.id}`)
        }
    }

    const onUpdateNotes = async () => {
        if ((solicitud?.id !== undefined) && (notas !== undefined)) {
            setIsLoadingState(() => true)
            try {
                await sendNotas(solicitud.id, notas)
            } catch (e) { }
            onReloadCuadro()
            setIsLoadingState(() => false)
        }
    }

    // const onAprobarBeca = async () => {
    //     if (solicitud?.id !== undefined) {
    //         setIsLoadingState(() => true)
    //         try {
    //             await aprobarSolicitud(solicitud.id)
    //         } catch (e) { }
    //         onReloadCuadro()
    //         setIsLoadingState(() => false)
    //     }
    // }

    // const onConfirmarAprobarBeca = ()=> {
    //     setConfirmationModalInfo(()=>{return {visible:true, goFunction: onAprobarBeca, 
    //         text:'Se procederá a la concesión de la solicitud, ¿lo confirma?'}})
    // }

    // const onDenegarBeca = async () => {
    //     if (solicitud?.id !== undefined) {
    //         setIsLoadingState(() => true)
    //         try {
    //             setDenegacionModalInfo(() => { return { visible: true, solicitudId: solicitud.id } })
    //         } catch (e) { }
    //         onReloadCuadro()
    //         setIsLoadingState(() => false)
    //     }
    // }

    // const onConfirmarDenegarBeca = ()=> {
    //     setConfirmationModalInfo(()=>{return {visible:true, goFunction: onDenegarBeca, 
    //         text:'Se procederá a la denegación de la solicitud, ¿lo confirma?'}})
    // }


    const onDenegarDefinitivaBeca = async () => {
        if (solicitud?.id !== undefined) {
            setIsLoadingState(() => true)
            try {
                await confirmarDenegacion(solicitud.id)
            } catch (e) { }
            onReloadCuadro()
            setIsLoadingState(() => false)
        }
    }

    const onConfirmarDenegarDefinitivaBeca = () => {
        setConfirmationModalInfo(() => {
            return {
                visible: true, goFunction: onDenegarDefinitivaBeca,
                text: 'Se procederá a la denegación definitiva de la solicitud, ¿lo confirma?'
            }
        })
    }


    const actualizaGestor = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setIsLoadingState(true)
        solicitud.gestor = e.target.value;
        try {
            await actualizaSolicitudGestor(solicitud);
        } catch { }
        finally {
            setIsLoadingState(false)
        }
    }

    const onOpenMailModal = () => {
        setMailModalInfo(() => { return { visible: true, solicitudId: solicitud.id } })
    }



    const estadoStyle = utilMethods.getIcon(solicitud?.estado)

    return (
        <div className='solicitudCardAndTooltip'>
            <div className='solicitudCard' onClick={onSelectConvocatoriaConSol}>
                <div className='convocNombre_solCard'>{solicitud?.convocatoria?.nombre}</div>
                <div className='userData_solCard'>
                    <div className='userDni_solCard'>{solicitud?.usuario?.numeroDocumento}</div>
                    <div className='userNombre_solCard'>{`${solicitud?.usuario?.nombre} ${solicitud?.usuario?.apellido1}
                    ${solicitud?.usuario?.apellido2}`}
                    </div>
                </div>
                <div className='estadoYButton' onClick={(e) => { e.stopPropagation(); }}>
                    <div className='estado_solCard'>
                        {estadoStyle?.icono}{`${estadoStyle?.name}`}
                    </div>
                    {/* {solicitud.estado === EstadoSolicitud.COMPROBADA ?
                    <button style={{ background: stylesVars.colors.main }} onClick={(e) => { e.stopPropagation(); (onConfirmarAprobarBeca()) }}>Conceder</button>
                    :
                    <></>
                }
                {solicitud.estado === EstadoSolicitud.COMPROBADA ?
                    <button style={{ background: stylesVars.colors.contrast }} onClick={(e) => { e.stopPropagation(); (onConfirmarDenegarBeca()) }}>Denegar</button>
                    :
                    <></>
                } */}
                    {solicitud.estado === EstadoSolicitud.PENDIENTE_DESCARTAR ?
                        <div className='buttonWithTooltip'>
                            <IoMdWarning onMouseEnter={() => setShowCausa(() => true)} onMouseLeave={() => setShowCausa(() => false)} />
                            <button style={{ background: stylesVars.colors.contrast }} onClick={(e) => { e.stopPropagation(); (onConfirmarDenegarDefinitivaBeca()) }}>Confirmar denegación</button>
                        </div>
                        :
                        <></>
                    }

                </div>
                <div className='tipoEstudios_solCard'>{utilMethods.getTipoEstudiosLabel(solicitud?.tipoEstudios)}</div>

                <div className='notes_solCard' onClick={(e) => { e.stopPropagation(); }}>
                    <textarea onChange={(e) => setNotas(e.target.value)} value={notas} maxLength={150} rows={4}></textarea>
                    <button onClick={onUpdateNotes}>Guardar</button>
                </div>
                <div className='gestor_solCard' onClick={(e) => { e.stopPropagation(); }}>
                    <p>Gestor</p>
                    <select onChange={actualizaGestor}>
                        <option value="">Seleccione...</option>
                        {gestores.map((gestor, i) =>
                            <option key={i} selected={gestor.id === solicitud.gestor} value={gestor.id}>{gestor.nombre} {gestor.apellido1}</option>
                        )}
                    </select>
                </div>
                <div className='icon_solCard' onClick={(e) => { e.stopPropagation(); }}>
                    <button onClick={onOpenMailModal}><AiOutlineMail /></button>
                </div>
                <div className='icon_solCard' onClick={(e) => { e.stopPropagation(); }}>
                    <button onClick={onEditSolicitud}><MdOutlineModeEditOutline /></button>
                    {solicitud.original ?
                        <button onClick={onViewOriginal}><MdOutlineFileCopy /></button>
                        :
                        undefined}
                </div>
                {/* <SolicitudEstado estado={convocatoriaConSol.solicitudEstado}/> */}
            </div>
            {
                showCausa && notificacionDenegacion ?
                    <>
                        <div className='tooltip'>
                            <p>{`Fecha de notificación: ${notificacionDenegacion?.notificacion.createdAt ? 
                                utilMethods.getStringFromDate(notificacionDenegacion.notificacion.createdAt) : ''}`}</p>
                            <p>{`Fecha de apertura: ${notificacionDenegacion?.notificacion.fechaApertura ? 
                                utilMethods.getStringFromDate(notificacionDenegacion.notificacion.fechaApertura) : ''}`}</p>
                            <p>{`Alegaciones: ${notificacionDenegacion?.plazo < 10 ? `Quedan ${10-notificacionDenegacion?.plazo} días para alegaciones` : 'El plazo de alegaciones se ha agotado'}`}</p>
                            <p>{`Causa: ${solicitud.causaDenegacion ? solicitud.causaDenegacion : 'Sin causa establecida'}`}</p>
                        </div>
                    </>
                    :
                    undefined
            }


        </div>
    )
}
import React, { useRef } from "react";
import {RiDeleteBack2Line} from 'react-icons/ri'

export function SubirRentasField({id, label, setFile, reduced}:{id:string, label:string, 
    setFile: React.Dispatch<React.SetStateAction<File | undefined >>, reduced?:boolean}){

    const fileInputRef = useRef<HTMLInputElement>(null)

    const onFileChange = (e:React.ChangeEvent<HTMLInputElement>) => { 
        if (e?.target?.files!==null) { 
            let file:File =  new File([e.target.files[0]], `${id}.json`, 
                {type: 'application/json'}); 
            setFile(file);
        } else {
            setFile(undefined);
        } 
    }

    const onReset = async()=> {
        if (fileInputRef?.current) {
            fileInputRef.current.value = ''
        }
    }
    
    return(
         <div style={{width: reduced? '30%': '100%'}} className={'fileFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className='fieldAndBut'>
                <input id={id} type={'file'} onChange={onFileChange} ref={fileInputRef}></input>
                <button className="fileButton" onClick={onReset}><RiDeleteBack2Line/></button>
            </div>
        </div>
    )

}
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useApi } from '../hooks/useApi';
import { Notificacion } from '../types/entities';
import { isLoading } from '../atoms/mainAtoms'
import { useParams } from 'react-router-dom';
import { NotificacionType } from '../types/types';
import { BsFileEarmarkCheck, BsFileEarmarkExcel } from 'react-icons/bs'
import { stylesVars } from '../utils/stylesVars';

export function NotificacionScreen({ }: {}) {

    const [codigo, setCodigo] = useState<string>('')
    const [codigoIncorrecto, setCodigoIncorrecto] = useState<boolean>(false)
    const [notificacion, setNotificacion] = useState<Notificacion | undefined>(undefined)
    const { getNotificacion } = useApi()
    const notificacionId = useParams().notificacionId
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const onGetNotificacion = async () => {
        setIsLoadingState(() => true)
        if (notificacionId) {
            let notificacion: Notificacion | undefined
            try {
                notificacion = await getNotificacion(notificacionId, codigo)
            } catch (e) {
                setCodigoIncorrecto(() => true)
                setIsLoadingState(() => false)
            }
            setNotificacion(() => notificacion)
        } else {
            setNotificacion(() => undefined)
        }
        setIsLoadingState(() => false)
    }

    const getMensajeNotificacion = (): string | undefined => {
        switch (notificacion?.tipo) {
            case NotificacionType.CONCESION:
                return 'Su solicitud ha sido concedida. Se le ha enviado al email información adicional.'
            case NotificacionType.DENEGACION:
                return 'Su solicitud ha sido denegada. Se le ha enviado al email información adicional.'
            case NotificacionType.FIRMA:
                return 'Se ha enviado correctamente su solicitud'
            case NotificacionType.REQUERIMIENTO:
                return 'Se requieren documentos para continuar su solicitud.  Se le ha enviado al email información adicional.'

            default:
                return undefined
        }
    }


    const getIconNotificacion = (): JSX.Element | undefined => {
        switch (notificacion?.tipo) {
            case NotificacionType.FIRMA:
            case NotificacionType.CONCESION:
                return <BsFileEarmarkCheck color={stylesVars.colors.main} />
            case NotificacionType.DENEGACION:
                return <BsFileEarmarkExcel color={stylesVars.colors.contrast} />
            default:
                return undefined;
        }
    }

    return (
        <div id='notificacionBox'>
            <div id='notificacionHeader'>
                <h1>
                    {'Notificación'}
                </h1>
            </div>
            {codigoIncorrecto ?
                <div id='code_notificacion'>
                    <p>{'No se ha podido abrir la notifiación. Compruebe el código'}</p>
                    <button onClick={() => setCodigoIncorrecto(() => false)}>{'Reintentar'}</button>
                </div>
                :
                notificacion === undefined ?
                    <div id='code_notificacion'>
                        <p>Escriba el código para abrir su notificación:</p>
                        <input type='text' value={codigo} onChange={(e) => setCodigo(() => e.target.value)} />
                        <button onClick={() => onGetNotificacion()}>Abrir</button>
                    </div>
                    :
                    <div id='notificacion_notificacion'>
                        <p id='mensaje_notificacion'>
                            {getIconNotificacion()}
                            {getMensajeNotificacion()}
                        </p>
                        <div id='info_notificacion'>
                            <p id='convo_info_notificacion'>{notificacion.solicitud?.convocatoria?.nombre}</p>
                            <p id='user_info_notificacion'>
                                {`${notificacion.solicitud?.usuario?.nombre} ${notificacion.solicitud?.usuario?.apellido1} 
                        ${notificacion.solicitud?.usuario?.apellido2}`}</p>
                        </div>
                    </div>
            }
        </div>
    )
}
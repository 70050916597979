import { useApi } from "../hooks/useApi";
import { FiUserCheck } from "react-icons/fi"
import { useRecoilState } from "recoil";
import { isLoading } from "../atoms/mainAtoms";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export function CuentaValidadaScreen({}: {}) {

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [isValidated, setIsValidated] = useState<boolean>(false)
    const mail = useParams().mail
    const code = useParams().code

    const { validateAccount } = useApi()

    useEffect(() => {
        onValidate()
    }, [])
    

    const onValidate = async () => {
        setIsLoadingState(() => true)
        if (mail !== undefined && code !== undefined) {
            try {
                await validateAccount(mail, code)
                setIsValidated(()=>true)
            } catch (e) {
                setIsLoadingState(() => false)
            }
        }
        setIsLoadingState(() => false)
    }

    return (
        <div id='registroBox'>
            <div id='registroHeader'>
                <h1>{'Validación usuario'}</h1>
            </div>
            {!isValidated ?
                <div id='regCompletedBox'>
                    <p id='titleRegCompleted'>
                        Validando usuario...
                    </p>
                </div>
                :
                <div id='regCompletedBox'>
                    <p id='titleRegCompleted'>
                        <FiUserCheck />
                        ¡Has validado tu cuenta!
                    </p>
                    <p id='messageRegCompleted'>
                        Ahora ya puedes iniciar sesión
                    </p>
                </div>
            }
    </div>
    )
}
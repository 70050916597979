import React, { useEffect, useState } from 'react';
import { EstadoSolicitud, EstadoStyle } from '../../types/types';
import {FiCheckSquare, FiFileText, FiSend, FiFilePlus} from 'react-icons/fi'
import {ImCancelCircle} from 'react-icons/im'
import {utilMethods} from '../../utils/utilMethods'

export function SolicitudEstado ({estado}:{estado:string}) {

    const [estadoStyle, setEstadoStyle] = useState<EstadoStyle|undefined>(undefined)

    useEffect(() => {
        getIcon()
    }, [estado])
    


    const getIcon = ()=> {
        setEstadoStyle(()=>utilMethods.getIcon(estado))
    }


    return (
        <div className='solicitudEstado'>
            <p>{estadoStyle?.mensaje}</p>{estadoStyle?.icono}
        </div>
)
}
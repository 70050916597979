import { useRecoilState } from 'recoil';
import { isLoading, validationErrorModal, ValidationErrorModalProps } from '../../atoms/mainAtoms';
import { ModalBase } from './ModalBase';
import { AiOutlineClose } from 'react-icons/ai'
import { SizeEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';


export function ValidationErrorModal() {

    const [modalInfo, setModalInfo] = useRecoilState<ValidationErrorModalProps>(validationErrorModal)

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Errores de validación'}</h1>
                        <button id='modalCloseBut' onClick={() => { setModalInfo({ visible: false, validationErrors: undefined }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalForm'>
                        <p className='validationSubtitle'>{'Los siguientes campos están incompletos o incorrectos:'}</p>
                        {modalInfo.validationErrors ?
                            <div className='validationErrorList'>
                                {modalInfo.validationErrors.map((validationError) =>
                                    <>
                                        <p>{utilMethods.getValidationFieldLabel(validationError)}</p>
                                    </>
                                )}
                            </div>
                            :
                            <p>{'Ha ocurrido un error al realizar la validación'}</p>
                        }
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React, { } from 'react';
import { ClipLoader, DotLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { isLoading } from '../atoms/mainAtoms';
import { stylesVars } from '../utils/stylesVars';
import cabildoLogo from './../resources/cabildo_logo.jpg';

export function LoadingScreen() {
    return (
        <div id='loadingScreen'>
            <img src={cabildoLogo} alt='logo cabildo fuerteventura' style={{ cursor: 'pointer' }}></img>
            <DotLoader color={'white'} loading={true} size={120} />
        </div>
    )
}
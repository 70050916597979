import { useEffect, useState } from "react";
import { TextField } from "../components/atom/TextField";
import { ContrasenaField } from "../components/atom/ContrasenaField";
import { DateField } from "../components/atom/DateField";
import { useApi } from "../hooks/useApi";
import { Municipio, Usuario } from "../types/entities";
import { MunicipioField } from "../components/atom/MunicipioField";
import { FiUserCheck } from "react-icons/fi"
import { FaFlagCheckered } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { autorizacionDatosModal, AutorizacionDatosModalProps, isLoading } from "../atoms/mainAtoms";
import { TipoDocumentoField } from "../components/atom/TipoDocumentoField";
import { SizeEnum, TipoDocumentoEnum } from "../types/types";
import { MdErrorOutline } from 'react-icons/md';
import { CheckBoxField } from "../components/atom/CheckBoxField";
import { AutorizacionDatosModal } from "../components/organism/AutorizacionDatosModal";
import { SiNoField } from "../components/atom/SiNoField";
import { utilMethods } from "../utils/utilMethods";
import { PhoneField } from "../components/atom/PhoneField";

export function RegistroScreen() {

    const [nombre, setNombre] = useState<string | undefined>()
    const [apellido1, setApellido1] = useState<string | undefined>()
    const [apellido2, setApellido2] = useState<string | undefined>()
    const [fechaNacimiento, setFechaNacimiento] = useState<Date>()
    const [telefono, setTelefono] = useState<string | undefined>()
    const [email, setEmail] = useState<string | undefined>()
    const [contrasena, setContrasena] = useState<string | undefined>()
    const [confirmaContrasena, setConfirmaContrasena] = useState<string | undefined>()
    const [direccion, setDireccion] = useState<string | undefined>()
    const [municipioActual, setMunicipioActual] = useState<string | undefined>()
    const [hasMunicipioAntiguo, setHasMunicipioAntiguo] = useState<boolean>(false)
    const [municipioAntiguo, setMunicipioAntiguo] = useState<string | undefined>()
    const [violenciaGenero, setViolenciaGenero] = useState<boolean>(false)
    const [codigoPostal, setCodigoPostal] = useState<string | undefined>()
    const [numeroDocumento, setNumeroDocumento] = useState<string | undefined>()
    const [numeroSoporteDocumento, setNumeroSoporteDocumento] = useState<string | undefined>()
    const [tipoDocumento, setTipoDocumento] = useState<string | undefined>(TipoDocumentoEnum.DNI.toString())
    const [consentimientoDatos, setConsentimientoDatos] = useState<boolean>(false)
    const [autorizacionDatosModalInfo, setAutorizacionDatosModalInfo] = useRecoilState<AutorizacionDatosModalProps>(autorizacionDatosModal)
    const [errorFields, setErrorFields] = useState<string[]>([])

    const [isNumeroDocumentoError, setIsNumeroDocumentoError] = useState<boolean>(false)
    const [isNumeroSoporteError, setIsNumeroSoporteError] = useState<boolean>(false)


    const [warning, setWarning] = useState<string | undefined>(undefined)
    const [isRegistered, setIsRegistered] = useState<boolean>(false)


    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const checkFormat = (finalCheck?: boolean): boolean => {

        const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
        const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

        const soporteDniRegex = /^[A-Z]{3}[0-9]{6}$/i
        const soporteNieRegex = /^[E][0-9]{8}$/i;

        let result = true

        if (!numeroDocumento) {
            setIsNumeroDocumentoError(() => false)
        } else {
            if (tipoDocumento === TipoDocumentoEnum.DNI) {
                if (dniRegex.test(numeroDocumento)) {
                    if (utilMethods.checkDniLetter(numeroDocumento)) {
                        setIsNumeroDocumentoError(() => false)
                    } else {
                        setIsNumeroDocumentoError(() => true)
                        result = false;
                    }
                } else {
                    setIsNumeroDocumentoError(() => true)
                    result = false;
                }
            } else {
                if (nieRegex.test(numeroDocumento)) {
                    setIsNumeroDocumentoError(() => false)
                } else {
                    setIsNumeroDocumentoError(() => true)
                    result = false;
                }
            }
        }

        if (!numeroSoporteDocumento) {
            setIsNumeroSoporteError(() => false)
        } else {
            if (tipoDocumento === TipoDocumentoEnum.DNI) {
                if (soporteDniRegex.test(numeroSoporteDocumento)) {
                    setIsNumeroSoporteError(() => false)
                } else {
                    setIsNumeroSoporteError(() => true)
                    result = false;
                }
            } else {
                if (soporteNieRegex.test(numeroSoporteDocumento)) {
                    setIsNumeroSoporteError(() => false)
                } else {
                    setIsNumeroSoporteError(() => true)
                    result = false;
                }
            }
        }

        if (finalCheck && !result) setWarning(() => 'Hay campos con formato erróneo')
        return result
    }

    useEffect(() => {
        checkFormat()
    }, [numeroDocumento, tipoDocumento, numeroSoporteDocumento])



    const { registerUser, getMunicipios } = useApi()

    useEffect(() => {
        if (!hasMunicipioAntiguo) {
            setMunicipioAntiguo(() => '0')
        }
    }, [hasMunicipioAntiguo])


    const checkFields = () => {
        const errorFields: string[] = []
        if (!nombre) {
            errorFields.push('nombre')
        }
        if (!apellido1) {
            errorFields.push('apellido1')
        }
        if (!apellido2) {
            errorFields.push('apellido2')
        }

        if (!fechaNacimiento) {
            errorFields.push('fechaNacimiento')

        } else {
            const fechaNacimientoDate = new Date(fechaNacimiento)
            if ((isNaN(fechaNacimientoDate.getTime()))) {
                errorFields.push('fechaNacimiento')
            }
        }
        if (!telefono) {
            errorFields.push('telefono')
        }
        if (!email) {
            errorFields.push('email')
        }
        if (!contrasena) {
            errorFields.push('contrasena')
        }
        if (!confirmaContrasena) {
            errorFields.push('confirmaContrasena')
        }
        if (!direccion) {
            errorFields.push('direccion')
        }
        if (!codigoPostal) {
            errorFields.push('codigoPostal')
        }
        if (!municipioActual || municipioActual === '0') {
            errorFields.push('municipioActual')
        }
        if (hasMunicipioAntiguo && (municipioAntiguo === undefined || municipioAntiguo === '0')) {
            errorFields.push('municipioAntiguo')
        }
        if (!consentimientoDatos) {
            errorFields.push('consentimientoDatos')
        }
        if (!tipoDocumento) {
            errorFields.push('tipoDocumento')
        }
        if (!numeroDocumento) {
            errorFields.push('numeroDocumento')
        }
        if (!numeroSoporteDocumento) {
            errorFields.push('numeroSoporteDocumento')
        }
        setErrorFields(() => errorFields)
        return errorFields.length === 0
    }

    const isError = (id: string) => {
        if (errorFields.find((field) => field === id)) {
            return true;
        } else {
            return false;
        }
    }


    const onRegister = async () => {
        setIsLoadingState(() => true)
        setWarning(() => undefined)
        if (checkFields()) {
            if (contrasena === confirmaContrasena) {
                const municipios = await getMunicipios()
                const municipioActualObj = municipios.find((mun) => mun.id === municipioActual)
                const municipioAntiguoObj = municipioAntiguo ? municipios.find((mun) => mun.id === municipioAntiguo) : undefined
                const user: Usuario = {
                    nombre: nombre, apellido1: apellido1, apellido2: apellido2, fechaNacimiento: fechaNacimiento,
                    telefono: telefono, email: email, contrasena: contrasena, direccion: direccion, codigoPostal: codigoPostal,
                    municipioActual: municipioActualObj as Municipio, municipioAntiguo: municipioAntiguoObj, numeroDocumento: numeroDocumento,
                    numeroSoporteDocumento: numeroSoporteDocumento, tipoDocumento: tipoDocumento, consentimientoDatos: consentimientoDatos,
                    violenciaGenero: violenciaGenero
                }
                try {
                    await registerUser(user)
                    setIsRegistered(() => true)
                } catch (e) {
                    setIsLoadingState(() => false)
                }
            } else {
                setWarning(() => 'Las contraseñas no coinciden')
            }

        } else {
            setWarning(() => 'Faltan campos por cubrir')
        }
        setIsLoadingState(() => false)
    }

    return (
        <>
            <div id='registroBox'>
                <div id='registroHeader'>
                    <h1>{'Registro usuario'}</h1>
                </div>
                {!isRegistered ?
                    <>
                        <div className='formBox'>
                            <div className='formElement'>
                                <TipoDocumentoField id="tipoDocumento" label="Tipo documento" value={tipoDocumento} setValue={setTipoDocumento} isError={isError('tipoDocumento')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="numeroDocumento" label="Número documento" value={numeroDocumento} setValue={setNumeroDocumento} size={SizeEnum.M} isUpper={true} formatError={isNumeroDocumentoError} isError={isError('numeroDocumento')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="numeroSoporteDocumento" label="Número soporte documento" value={numeroSoporteDocumento}
                                    setValue={setNumeroSoporteDocumento} size={SizeEnum.M} isUpper={true} formatError={isNumeroSoporteError}
                                    helpLink={'https://sede.educacion.gob.es/dam/jcr:328d4bc3-ea5c-4d78-99a1-93ab4c2d669a/ayuda_identidad.pdf'} isError={isError('numeroSoporteDocumento')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="nombre" label="Nombre" value={nombre} setValue={setNombre} isError={isError('nombre')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="apellido1" label="Primer apellido" value={apellido1} setValue={setApellido1} isError={isError('apellido1')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="apellido2" label="Segundo apellido" value={apellido2} setValue={setApellido2} isError={isError('apellido2')} />
                            </div>
                            <div className='formElement'>
                                <DateField id="fechaNacimiento" label="Fecha de nacimiento" value={fechaNacimiento} setValue={setFechaNacimiento} isError={isError('fechaNacimiento')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="email" label="Email" value={email} setValue={setEmail} isError={isError('email')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="direccion" label="Direccion" value={direccion} setValue={setDireccion} isError={isError('direccion')} />
                            </div>
                            <div className='formElement'>
                                <TextField id="codigoPostal" label="Código Postal" value={codigoPostal} setValue={setCodigoPostal} isError={isError('codigoPostal')} />
                            </div>
                            <div className='formElement'>
                                <PhoneField id="telefono" label="Telefono" value={telefono} setValue={setTelefono} size={SizeEnum.M} 
                                    isError={isError('telefono')} />
                            </div>
                            <div className='formElement'>
                                <MunicipioField id='municipioActual' label='Municipio' value={municipioActual} setValue={setMunicipioActual} reduced={true} isError={isError('municipioActual')} />
                            </div>
                            <div className='formElement'>
                                <CheckBoxField id="hasMunicipioAntiguo" label="He residido en un municipio distinto al actual en el último año"
                                    value={hasMunicipioAntiguo} setValue={setHasMunicipioAntiguo} />
                            </div>
                            <div className='formElement'>
                                <MunicipioField id='municipioAntiguo' label='Municipio antiguo' value={municipioAntiguo} setValue={hasMunicipioAntiguo ? setMunicipioAntiguo : undefined} reduced={true} isError={isError('municipioAntiguo')} />
                            </div>
                            <div className='formElement'>
                                <SiNoField id='violenciaGenero' label='Víctima de violencia de género' value={violenciaGenero} setValue={setViolenciaGenero} />
                            </div>
                            <div className='formElement'>
                                <ContrasenaField id="contrasena" label="Contraseña" value={contrasena} setValue={setContrasena} isError={isError('contrasena')} />
                            </div>
                            <div className='formElement'>
                                <ContrasenaField id="confirmarContrasena" label="Repite la contraseña" value={confirmaContrasena} setValue={setConfirmaContrasena} isError={isError('confirmaContrasena')} />
                            </div>
                            <div className='formElement'>
                                <CheckBoxField id="consentimientoDatos" label="Autorizo el tratamiento de mis datos personales o de terceros y declaro su veracidad" value={consentimientoDatos} setValue={setConsentimientoDatos} isError={isError('consentimientoDatos')} />
                                <button className="lightBut" onClick={() => setAutorizacionDatosModalInfo(() => { return { visible: true } })}>{'Leer más'}</button>
                            </div>
                            <div className='formElement'>
                                {warning ?
                                    <p className='warningMsg'><MdErrorOutline /> {warning}</p>
                                    :
                                    undefined
                                }
                            </div>
                            <div id='registerButBox'>
                                <div className='divCenteredElement'><button id='registerBut' onClick={onRegister}>{'Registrarse'}</button></div>
                            </div>
                        </div>
                    </>
                    :
                    <div id='regCompletedBox'>
                        <p id='titleRegCompleted'>
                            <FiUserCheck />
                            ¡Te has registrado!
                        </p>
                        <p id='messageRegCompleted'>
                            Para validar tu cuenta, pincha en el enlace que hemos mandado a tu correo
                        </p>
                    </div>


                }
            </div>
            <AutorizacionDatosModal />
        </>
    )

}
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useRecoilState } from 'recoil';
import { consultaModo, reloadFiles, solicitudInfo } from '../../atoms/mainAtoms';
import { useApi } from "../../hooks/useApi";
import { Solicitud } from "../../types/entities";
import { FiDownload } from 'react-icons/fi'
import { RiDeleteBack2Line } from 'react-icons/ri'
import { EstadoSolicitud, RolEnum } from "../../types/types";
import { useAuth } from "../../hooks/useAuth";

export function SubirArchivoField({ id, label, setFile, reduced }: { id: string, label: string, setFile: React.Dispatch<React.SetStateAction<File | undefined>>, reduced?: boolean }) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)
    const [fileName, setFileName] = useState<string | undefined>(undefined)
    const [filesReload, setFilesReload] = useRecoilState<boolean>(reloadFiles)
    const [warning, setWarning] = useState<boolean>(false)

    const fileInputRef = useRef<HTMLInputElement>(null)

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    const { getFileName, downloadSolicitudFile } = useApi()
    const { rol } = useAuth()

    useEffect(() => {
        onGetFileName()
    }, [filesReload])



    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWarning(()=>false)
        if (e?.target?.files !== null && solicitud) {
            const document = e.target.files[0];
            let file: File
            if (document.name.endsWith('.pdf')) {
                file = new File([e.target.files[0]], `${id}.pdf`, { type: 'application/pdf' });
            } else {
                if (document.name.endsWith('.doc')) {
                    file = new File([e.target.files[0]], `${id}.doc`, { type: 'application/msword' });
                } else {
                    setWarning(()=>true)
                    onReset()
                    return
                }
            }
            setFile(file);
        } else {
            setFile(undefined);
        }
    }

    const onGetFileName = async () => {
        let fileName: string | undefined = undefined
        if (solicitud?.id) {
            fileName = await getFileName(solicitud?.id, id)
        }
        setFileName(() => fileName)
    }

    const onFileDownload = async () => {
        if (fileName) {
            await downloadSolicitudFile(fileName)
        }
    }

    const onReset = async () => {
        if (fileInputRef?.current) {
            fileInputRef.current.value = ''
        }
        setFile(()=>undefined)
    }

    return (
        <div style={{ width: reduced ? '30%' : '70%' }} className={'fileFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className='fieldAndBut'>
                <input id={id} disabled={(modoConsulta && solicitud?.estado !== EstadoSolicitud.SUBSANACION) || (rol?.nombre !== RolEnum.USUARIO) || (!setFile) ? true : false}
                    type={'file'} onChange={onFileChange} ref={fileInputRef}></input>
                <button className="fileButton" onClick={onReset}><RiDeleteBack2Line /></button>
            </div>
            {fileName ?
                <div className="uploadedFileBox">
                    <p>{fileName}</p>
                    <button className="fileButton" onClick={onFileDownload}><FiDownload /></button>
                </div>
                :
                <></>
            }
            {warning ?
                <p className="warningMsgSmall">{'Sólo está permitido subir documentos en formato .pdf o .doc'}</p>
                :
                undefined
            }

        </div>
    )

}
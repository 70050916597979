import { useEffect, useState } from 'react';
import { Paginacion } from '../components/molecule/Paginacion';
import { useApi } from '../hooks/useApi';
import { Convocatoria, Usuario } from '../types/entities';
import { convocatoriaModal, ConvocatoriaModalProps, isLoading, reloadConvoAdmin, reloadUserAdmin, usuarioModal, UsuarioModalProps } from '../atoms/mainAtoms'
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth } from '../hooks/useAuth';
import { RolEnum } from '../types/types';
import { IoMdAdd } from 'react-icons/io'
import { ConvocatoriaAdminCard } from '../components/molecule/ConvocatoriaAdminCard';
import { ConvocatoriaModal } from '../components/organism/ConvocatoriaModal';
import { UsuarioAdminCard } from '../components/molecule/UsuarioAdminCard';
import { UsuarioModal } from '../components/organism/UsuarioModal';


export function UsuariosAdmin({ }: {}) {

    const [usuariosList, setUsuariosList] = useState<Usuario[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const userAdminReload = useRecoilValue<boolean>(reloadUserAdmin)
    const [modalInfo, setModalInfo] = useRecoilState<UsuarioModalProps>(usuarioModal)



    const { getUsuariosAdmin } = useApi()
    const { rol } = useAuth()

    useEffect(() => {
        onGetUsuarios()
    }, [page, userAdminReload])

    const onGetUsuarios = async () => {
        setIsLoadingState(() => true)
        try {
            const usuariosList = await getUsuariosAdmin(page)
            setTotalPages(() => usuariosList.paginasTotales)
            setUsuariosList(() => usuariosList.content)
        } catch { }
        finally {
            setIsLoadingState(() => false)
        }
    }

    const onCreateUsuario = () => {
        setModalInfo(() => { return { usuario:undefined, visible: true } })
    }

    return (
        (rol && rol.nombre === RolEnum.ADMIN) ?
            <div id='cuadroMandos'>
                <h1>{`Administración de usuarios`}</h1>
                <div id='convocatoriasAdminTable'>
                    <div id='convocatoriasAdminList'>
                        {usuariosList.map((usuario) =>
                            <UsuarioAdminCard usuario={usuario} />
                        )}
                        <button id='addConvoButton' onClick={onCreateUsuario}><IoMdAdd />Añadir</button>
                    </div>
                    <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'3vh'} />
                </div>
                <UsuarioModal />
            </div>
            :
            <></>
    )
}

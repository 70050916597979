import { useApi } from "../hooks/useApi";
import { FiUserCheck } from "react-icons/fi"
import { useRecoilState } from "recoil";
import { isLoading } from "../atoms/mainAtoms";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { TextField } from "../components/atom/TextField";
import { SizeEnum } from "../types/types";

export function CambioContrasenaScreen() {

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [password, setPassword] = useState<string | undefined>(undefined)
    const [repeatPassword, setRepeatPassword] = useState<string | undefined>(undefined)
    const [notMatch, setNotMatch] = useState<boolean>(false)

    const mail = useParams().mail
    const code = useParams().code

    const { changePassword } = useApi()


    const onChangePassword = async () => {
        setIsLoadingState(() => true)
        setNotMatch(() => false)
        if (mail && code && password && repeatPassword) {
            if (password === repeatPassword) {
                try {
                    await changePassword(mail, code, password)
                    setIsChanged(() => true)
                } catch (e) {
                    setIsLoadingState(() => false)
                }
            } else {
                setNotMatch(() => true)
            }
        }
        setIsLoadingState(() => false)
    }

    return (
        <div id='changePassBox'>
            <div id='changePassHeader'>
                <h1>{'Cambio contraseña'}</h1>
            </div>

            {isChanged ?
                <div id='regCompletedBox'>
                    <p className='changePassTitle'>
                        <FiUserCheck />
                        Has cambiado tu contraseña satisfactoriamente
                    </p>
                    <p className='changePassMessage'>
                        Ahora ya puedes iniciar sesión
                    </p>
                </div>
                :
                <div id='changePassFormBox'>
                    <TextField id='password' label='Contraseña' value={password} setValue={setPassword}
                        isPass={true} size={SizeEnum.VB}/>
                    <TextField id='repeatPassword' label='Repetir contraseña' value={repeatPassword} setValue={setRepeatPassword}
                        isPass={true} size={SizeEnum.VB}/>
                    <div id='saveButModal'>
                        <button onClick={onChangePassword}>{'Cambiar'}</button>
                    </div>
                    {notMatch ?
                        <p className="warningMsg">
                            Las contraseñas no coinciden
                        </p>
                        :
                        undefined
                    }

                </div>
            }
        </div>
    )
}
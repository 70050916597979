import { Convocatoria } from '../../types/entities';
import './../../index.css';

export function PublicConvocatoriaCard({ convocatoria }: { convocatoria: Convocatoria }) {
   

    return (
        <div className='publicConvocatoriaCard'>
            <p className='convocatoriaTitle'>{convocatoria.nombre}</p>
            <p className='convocatoriaDesc'>{convocatoria.descripcion}</p>
        </div>
    )
}
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoading, sendRentasModal, SendRentasModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { SizeEnum } from '../../types/types';
import { SubirArchivoField } from '../atom/SubirArchivoField';
import { SubirRentasField } from '../atom/SubirRentasField';


export function SendRentasModal() {

    const [modalInfo, setModalInfo] = useRecoilState<SendRentasModalProps>(sendRentasModal)

    const [docRentas, setDocRentas] = useState<File | undefined>()
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [completed, setCompleted] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const { sendRentas } = useApi()

    const onSend = async () => {
        setIsLoadingState(() => true)
        setError(() => false)
        if (docRentas && modalInfo.convocatoriaId) {
            try {
                await sendRentas(modalInfo.convocatoriaId, docRentas)
                setCompleted(() => true)
            } catch (e) {
                setError(() => true)
            }
            finally {
                setIsLoadingState(() => false)
            }
        }
        setIsLoadingState(() => false)
    }


    const onClear = () => {
        setDocRentas(() => undefined)
        setError(() => false)
        setCompleted(() => false)
        setModalInfo({ visible: false })
    }


    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Enviar archivo de rentas de la convocatoria'}</h1>
                        <button id='modalCloseBut' onClick={onClear}><AiOutlineClose /></button>
                    </div>
                    {completed ?
                        <p>{'Se han guardado las rentas correctamente'}</p>
                        :
                        <>
                            <div id='modalForm'>
                                <div className='formConvoModal'>
                                    <div className="autorizations">
                                        <SubirRentasField id='docRentas' label='Documento .json con las rentas de la convocatoria' 
                                            setFile={setDocRentas}/>
                                    </div>
                                </div>
                            </div>
                            <div id='saveButModal'><button onClick={onSend}><AiOutlineSend />{'Enviar'}</button></div>
                            {error ?
                                <p>{'Ha ocurrido un error al guardar las rentas. Compruebe su fichero .json'}</p>
                                :
                                undefined
                            }
                        </>
                    }
                </>
            </ModalBase>
            :
            <></>
    )
}
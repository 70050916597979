import { useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { consultaModo, isLoading, isOriginalAtom, reloadFiles, solicitudInfo } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { RequiredDocs, Solicitud } from '../../types/entities';
import { EstadoSolicitud, RolEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { SubirArchivoField } from '../atom/SubirArchivoField';
import { InfoChecker } from '../molecule/InfoChecker';

export function SolicitudStepDocumentos({ }: {}) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)
    const isOriginal = useRecoilValue<boolean>(isOriginalAtom)


    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const [docIdentidad, setDocIdentidad] = useState<File | undefined>()
    const [docEmpadronamiento, setDocEmpadronamiento] = useState<File | undefined>()
    const [docMatricula, setDocMatricula] = useState<File | undefined>()
    const [docRenta, setDocRenta] = useState<File | undefined>()
    const [docMatriculaUniversidad, setDocMatriculaUniversidad] = useState<File | undefined>()
    const [docExpedienteUni, setDocExpedienteUni] = useState<File | undefined>()
    const [docTitulos, setDocTitulos] = useState<File | undefined>()
    const [docDiscapacidad, setDocDiscapacidad] = useState<File | undefined>()
    const [docFamiliaNumerosa, setDocFamiliaNumerosa] = useState<File | undefined>()
    const [docPrestaciones, setDocPrestaciones] = useState<File | undefined>()
    const [docViolencia, setDocViolencia] = useState<File | undefined>()
    const [docHospitalizado, setDocHospitalizado] = useState<File | undefined>()
    const [docFpNoImpartido, setDocFpNoImpartido] = useState<File | undefined>()
    const [docFederado, setDocFederado] = useState<File | undefined>()
    const [docEmancipado, setDocEmancipado] = useState<File | undefined>()
    const [docUniversitario, setDocUniversitario] = useState<File | undefined>()
    const [docAbuelos, setDocAbuelos] = useState<File | undefined>()

    const [requiredDocs, setRequiredDocs] = useState<RequiredDocs | undefined>(undefined);


    const [matricula, setMatricula] = useState<File | undefined>()
    const [certificacionAcademica, setCertificacionAcademica] = useState<File | undefined>()

    const modoConsulta = useRecoilValue<boolean>(consultaModo)

    const [filesRelaod, setFilesReload] = useRecoilState<boolean>(reloadFiles)

    const [errorFields, setErrorFields] = useState([''])

    const { updateSolicitud, getSolicitud, saveFiles, getRequiredDocs } = useApi()

    const { rol } = useAuth()
    const [visible, setVisible] = useState(false)
    const isPresent = utilMethods.isPresent


    useEffect(() => {
        onGetRequiredDocs()
    }, [])

    const onGetRequiredDocs = async () => {
        if (solicitud?.id) {
            const requiredDocs = await getRequiredDocs(solicitud?.id)
            setRequiredDocs(() => requiredDocs)
        }
    }


    const onSave = async () => {
        if (solicitud) {
            setIsLoadingState(() => true)
            try {
                const files: File[] = []
                if (certificacionAcademica) {
                    files.push(certificacionAcademica)
                }
                if (matricula) {
                    files.push(matricula)
                }
                if (docIdentidad) {
                    files.push(docIdentidad)
                }
                if (docEmpadronamiento) {
                    files.push(docEmpadronamiento)
                }
                if (docMatricula) {
                    files.push(docMatricula)
                }
                if (docDiscapacidad) {
                    files.push(docDiscapacidad)
                }
                if (docEmpadronamiento) {
                    files.push(docEmpadronamiento)
                }
                if (docFamiliaNumerosa) {
                    files.push(docFamiliaNumerosa)
                }
                if (docMatriculaUniversidad) {
                    files.push(docMatriculaUniversidad)
                }
                if (docExpedienteUni) {
                    files.push(docExpedienteUni)
                }
                if (docPrestaciones) {
                    files.push(docPrestaciones)
                }
                if (docRenta) {
                    files.push(docRenta)
                }
                if (docTitulos) {
                    files.push(docTitulos)
                }
                if (docViolencia) {
                    files.push(docViolencia)
                }
                if (docHospitalizado) {
                    files.push(docHospitalizado)
                }
                if (docFederado) {
                    files.push(docFederado)
                }
                if (docFpNoImpartido) {
                    files.push(docFpNoImpartido)
                }
                if (docEmancipado) {
                    files.push(docEmancipado)
                }
                if (docUniversitario) {
                    files.push(docUniversitario)
                }
                if (docAbuelos) {
                    files.push(docAbuelos)
                }
                await saveFiles(solicitud.id as string, files)
                setFilesReload((old) => !old)
                setIsLoadingState(() => false)
            } catch { }
            setIsLoadingState(() => false)
        } else {
            setVisible(true)
        }
    }

    return (
        solicitud !== undefined ?
            <div id='stepBox'>
                <div id='stepHeader'>
                    <h1>{'Documentación'}</h1>
                </div>
                <div id='docsAndChecksBox'>
                    <div id="docsForm">
                        {/* <div className="autorizations">
                            <SubirArchivoField id='certificacionAcademica' label='Certificado de calificaciones finales de 3º ESO, año escolar 21/22' setFile={setCertificacionAcademica}  />
                        </div> */}
                        {/* <div className="autorizations">
                            <SubirArchivoField id='matricula' label='Matrícula de 4º de ESO, año escolar 22/23' setFile={setMatricula}  />
                        </div> */}

                        {!solicitud?.consultaIdentidad ?
                            <div className="autorizations">
                                <SubirArchivoField id='docIdentidad' label='Consulta sobre datos de identidad a la Dirección General de Policía' setFile={setDocIdentidad} />
                            </div>
                            :
                            undefined
                        }
                        {!solicitud?.consultaEmpadronamiento ?
                            <div className="autorizations">
                                <SubirArchivoField id='docEmpadronamiento' label='Consulta sobre datos de empadronamiento al INE' setFile={setDocEmpadronamiento} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.matriculaCalificaciones && !solicitud?.consultaMatricula && isPresent('consultaMatricula', solicitud) ?
                            <div className="autorizations">
                                <SubirArchivoField id='docMatricula' label='Consulta de datos de matrícula y calificaciones, al Gobierno de Canarias' setFile={setDocMatricula} />
                            </div>
                            :
                            undefined
                        }

                        {!solicitud?.consultaRenta ?
                            <div className="autorizations">
                                <SubirArchivoField id='docRenta' label='Consulta sobre nivel de renta para becas a la Agencia Tributaria' setFile={setDocRenta} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.matriculaUniversitaria && !solicitud?.consultaMatriculaUniversidad ?
                            <>
                                <div className="autorizations">
                                    <SubirArchivoField id='docMatriculaUniversidad' label='Matrícula universidad curso actual' setFile={setDocMatriculaUniversidad} />
                                </div>
                                <div className="autorizations">
                                    <SubirArchivoField id='docExpedienteUni' label='Extracto de expediente del curso actual, donde figuren los datos identificativos del solicitante, 
                                    créditos matriculados del curso actual y créditos matriculados, superados y no superados del curso pasado' setFile={setDocExpedienteUni} />
                                </div>
                            </>
                            :
                            undefined
                        }

                        {requiredDocs?.prestaciones && !solicitud?.consultaPrestaciones ?
                            <div className="autorizations">
                                <SubirArchivoField id='docPrestaciones' label='Consulta sobre datos de prestaciones, al SEPE' setFile={setDocPrestaciones} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.titulos && !solicitud?.consultaTitulos && isPresent('consultaTitulos', solicitud) ?
                            <div className="autorizations">
                                <SubirArchivoField id='docTitulos' label='Consulta sobre datos de títulos universitarios y no universitarios, al Ministerio de Educación' setFile={setDocTitulos} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.discapacidad && !solicitud?.consultaDiscapacidad ?
                            <div className="autorizations">
                                <SubirArchivoField id='docDiscapacidad' label='Consulta sobre datos discapacidad, al MINHAP' setFile={setDocDiscapacidad} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.familiaNumerosa && !solicitud?.consultaFamiliaNumerosa ?
                            <div className="autorizations">
                                <SubirArchivoField id='docFamiliaNumerosa' label='Consulta sobre datos de familia numerosa, al MINHAP' setFile={setDocFamiliaNumerosa} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.violenciaGenero && !solicitud?.consultaViolencia ?
                            <div className="autorizations">
                                <SubirArchivoField id='docViolencia' label='Consulta beneficiario de derechos por ser víctima de violencia de género' setFile={setDocViolencia} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.hospitalizado ?
                            <div className="autorizations">
                                <SubirArchivoField id='docHospitalizado'
                                    label='Documento justificativo de la hospitalización del solicitante o uno de sus familiares'
                                    setFile={setDocHospitalizado} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.federado ?
                            <div className="autorizations">
                                <SubirArchivoField id='docFederado'
                                    label='Documento justificativo de si el solicitante está federado en algún club deportivo con sede fuera de la isla'
                                    setFile={setDocFederado} />
                            </div>
                            :
                            undefined
                        }

                        {requiredDocs?.fpNoImpartido ?
                            <div className="autorizations">
                                <SubirArchivoField id='docFpNoImpartido'
                                    label='Documento justificativo de si el FP requerido por el solicitante no se imparte en la isla o no se ha obtenido plaza en el impartido'
                                    setFile={setDocFpNoImpartido} />
                            </div>
                            :
                            undefined
                        }
                        {requiredDocs?.emancipado ?
                            <div className="autorizations">
                                <SubirArchivoField id='docEmancipado'
                                    label='Documento justificativo de la independencia económica del solicitante'
                                    setFile={setDocEmancipado} />
                            </div>
                            :
                            undefined
                        }
                        {requiredDocs?.familiarUniversitario ?
                            <div className="autorizations">
                                <SubirArchivoField id='docUniversitario'
                                    label='Documento justificativo de familiares estudiando en la universidad'
                                    setFile={setDocUniversitario} />
                            </div>
                            :
                            undefined
                        }
                        {requiredDocs?.abuelos ?
                            <div className="autorizations">
                                <SubirArchivoField id='docAbuelos'
                                    label='Acreditación de la tutela de abuelos'
                                    setFile={setDocAbuelos} />
                            </div>
                            :
                            undefined
                        }

                        {/* {solicitud?.consultaIdentidad && solicitud?.consultaMatricula && solicitud.consultaEmpadronamiento ?
                            <p>No se requiere ningún documento adicional</p>
                            :
                            undefined
                        } */}
                    </div>
                    {!isOriginal && ((solicitud.estado !== EstadoSolicitud.BORRADOR && solicitud.estado !== EstadoSolicitud.PENDIENTE_ENVIO) || (rol && rol.nombre !== RolEnum.USUARIO)) ?
                        <InfoChecker requiredDocs={requiredDocs} />
                        :
                        undefined}
                </div>

                {(!modoConsulta || solicitud.estado === EstadoSolicitud.SUBSANACION) && (rol?.nombre === RolEnum.USUARIO) ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}

            </div>
            :
            <></>

    )

}

import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoading, mailModal, MailModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { TextAreaField } from '../atom/TextAreaField';
import { SizeEnum } from '../../types/types';


export function MailModal() {

    const [modalInfo, setModalInfo] = useRecoilState<MailModalProps>(mailModal)

    const [mailText, setMailText] = useState<string | undefined>(undefined)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const { mailSolicitud } = useApi()

    const onSend = async () => {
        setIsLoadingState(() => true)
        if (mailText && modalInfo.solicitudId) {
            try {
                await mailSolicitud(modalInfo.solicitudId, mailText)
            } catch (e) {
            }
            finally {
                onClear()
                setModalInfo(() => { return { visible: false } })
            }
        }
        setIsLoadingState(() => false)
    }


    const onClear = () => {
        setMailText(() => undefined)
    }


    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Enviar email'}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear(); setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalForm'>
                        <TextAreaField id='Mensaje' label='Mensaje' value={mailText} setValue={setMailText} maxCar={300} size={SizeEnum.VB} cols={8} />
                    </div>
                    <div id='saveButModal'><button onClick={onSend}><AiOutlineSend />{'Enviar'}</button></div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { SelectOption, ParentescoEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';

export function ParentescoField({ id, label, value, setValue, setFilterPropValue, reduced, isError }:
    { id: string, label: string, value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, setFilterPropValue?: (value: string | boolean | undefined, property: string) => void, 
        reduced?: boolean, isError?:boolean }) {


    const getOptionFromValue = (value: string): SelectOption => {
        return { value: value, label: utilMethods.getParentescoLabel(value) }
    }


    const getOptions = () => {
        const options: SelectOption[] = []
        Object.values(ParentescoEnum).forEach((option) => {
            options.push(getOptionFromValue(option))
        })
        return options
    }

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    const options = getOptions()

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '60%' : '100%' }} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className={`selectField ${isError ? 'errorField' : ''}`} value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e?.target.value) : 
                    setFilterPropValue ? (e) => setFilterPropValue(e?.target.value === '0' ? undefined : e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar parentesco'}</option>
                    {options.map((option) =>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue("0", id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div >
    )
}
import React, {  } from 'react';
import { SelectOption, FamiliaNumerosaEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';

export function FamiliaNumerosaField({ id, label, value, setValue, disabled}:
    { id: string, label: string, value: string | undefined, setValue?: React.Dispatch<React.SetStateAction<string | undefined>>, reduced?: boolean, disabled?:boolean}) {


    const getOptionFromValue = (value: string): SelectOption => {
        return { value: value, label: utilMethods.getFamiliaNumerosaLabel(value) }
    }


    const getOptions = () => {
        const options: SelectOption[] = []
        Object.values(FamiliaNumerosaEnum).forEach((option) => {
            options.push(getOptionFromValue(option))
        })
        return options
    }

    const options = getOptions()

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: '60%'}} disabled={modoConsulta || !setValue ? true : false}  className='selectField' value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e?.target.value) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccione tipo'}</option>
                    {options.map((option) =>
                        <option value={option.value}>{option.label}</option>
                    )}
                </select>
            </div>
        </div >
    )
}
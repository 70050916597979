// const mainApiUrl = 'http://localhost:8080/api/'
const mainApiUrl = 'https://cabildofuerteventura.notacool.com/api/'

const dateTimeFormat = 'DD/MM/yyyy HH:mm'
const dateFormat = 'DD/MM/yyyy'
const dateInputFormat = 'YYYY-MM-DD'



export const conf = {mainApiUrl, dateFormat, dateInputFormat, dateTimeFormat}

import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { convocatoriaConSolSelected, solicitudInfo, userInfo } from '../../atoms/mainAtoms'
import { Convocatoria, Solicitud } from '../../types/entities';
import { EstadoSolicitud, SizeEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { CheckBoxField } from '../atom/CheckBoxField';
import { MunicipioField } from '../atom/MunicipioField';
import { SiNoField } from '../atom/SiNoField';
import { TextField } from '../atom/TextField';

export function SolicitudStepPersonales({ }: {}) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)

    // const [name, setName] = useState<string|undefined>(undefined);
    // const [apellido1, setApellido1] = useState<string|undefined>(undefined);
    // const [apellido2, setApellido2] = useState<string|undefined>(undefined);
    // const [numeroDocumento, setNumeroDocumento] = useState<string|undefined>(undefined);
    // const [numeroSoporteDocumento, setNumeroSoporteDocumento] = useState<string|undefined>(undefined);
    // const [fechaNacimiento, setFechaNacimiento] = useState<Date|undefined>(undefined);
    // const [direccion, setDireccion] = useState<string|undefined>(undefined);
    // const [municipioActualId, setMunicipioActualId] = useState<string|undefined>(undefined);
    // const [municipioAnteriorId, setMunicipioAnteriorId] = useState<string|undefined>(undefined);
    // const [codigoPostal, setCodigoPostal] = useState<string|undefined>(undefined);
    // const [dni, setDni] = useState<string|undefined>(undefined);
    // const [dni, setDni] = useState<string|undefined>(undefined);
    // const [dni, setDni] = useState<string|undefined>(undefined);
    // const [dni, setDni] = useState<string|undefined>(undefined);
    // const [dni, setDni] = useState<string|undefined>(undefined);
    // const [name, setName] = useState<string|undefined>(undefined);

    const isPresent = utilMethods.isPresent

    const isBorrador = solicitud?.estado === EstadoSolicitud.BORRADOR


    return (
        solicitud !== undefined ?
            <div id='stepBox'>
                <div id='stepHeader'>
                    <h1>{'Datos personales'}</h1>
                </div>
                <div className='formBox'>
                    <div className='formElement'>
                        <TextField id='numeroDocumento' label='DNI' value={isBorrador ? solicitud.usuario?.numeroDocumento : solicitud.numeroDocumento} size={SizeEnum.M} />
                    </div>
                    <div className='formElement'>
                        <TextField id='numeroSoporteDocumento' label='Número soporte del DNI' value={isBorrador ? solicitud.usuario?.numeroSoporteDocumento : solicitud.numeroSoporteDocumento} size={SizeEnum.M} />
                    </div>
                    <div className='formElement'>
                        <TextField id='nombre' label='Nombre' value={isBorrador ? solicitud.usuario?.nombre : solicitud.nombre} />
                    </div>
                    <div className='formElement'>
                        <TextField id='apellido1' label='Apellido 1' value={isBorrador ? solicitud.usuario?.apellido1 : solicitud.apellido1} />
                    </div>
                    <div className='formElement'>
                        <TextField id='apellido2' label='Apellido 2' value={isBorrador ? solicitud.usuario?.apellido2 : solicitud.apellido2} />
                    </div>

                    <div className='formElement'>
                        <TextField id='direccion' label='Dirección' value={isBorrador ? solicitud.usuario?.direccion : solicitud.direccion} />
                    </div>

                    <div className='formElement'>
                        <MunicipioField id='municipioActual' label='Municipio actual' value={isBorrador ? solicitud.usuario?.municipioActual?.id : solicitud.municipioActual?.id} reduced={true} />
                    </div>
                    {solicitud.usuario?.municipioAntiguo ?
                        <div className='formElement'>
                            <MunicipioField id='municipioAntiguo' label='Municipio antiguo' value={isBorrador ? solicitud.usuario?.municipioAntiguo.id : solicitud.municipioAntiguo?.id} reduced={true} />
                        </div>
                        :
                        <></>
                    }
                    <div className='formElement'>
                        <SiNoField id='violenciaGenero' label='¿Ha sido víctima de violencia de género?'
                            value={isBorrador ? (solicitud.usuario?.violenciaGenero ? solicitud.usuario?.violenciaGenero : false) : (solicitud.violenciaGenero ? solicitud.violenciaGenero : false)} />
                    </div>
                </div>
            </div>
            :
            <div id='stepBox'></div>
    )
}
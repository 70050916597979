import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { confirmationModal, ConfirmationModalProps, csvModal, CsvModalProps, isLoading, reloadRemesas } from '../../atoms/mainAtoms';
import { Remesa } from '../../types/entities';
import './../../index.css';
import { IoMdSend } from 'react-icons/io'
import { RiFileListLine } from 'react-icons/ri'
import { EstadoRemesa } from '../../types/types';
import { useApi } from '../../hooks/useApi';
import { utilMethods } from '../../utils/utilMethods'

export function RemesaCard({ remesa }: { remesa: Remesa }) {
    const navigate = useNavigate()
    const { closeRemesa, getRemesaCsv } = useApi()
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [reloadRemesasState, setReloadRemesasState] = useRecoilState<boolean>(reloadRemesas)
    const [csvModalInfo, setCsvModalInfo] = useRecoilState<CsvModalProps>(csvModal)
    const [confirmationModalInfo, setConfirmationModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)

    const onCloseRemesa = async () => {
        setIsLoadingState(() => true)
        if (remesa.estado === EstadoRemesa.ABIERTA) {
            await closeRemesa(remesa.esLocal, remesa.esConcedida)
        }
        setReloadRemesasState((oldVal) => !oldVal)
        setIsLoadingState(() => false)
    }

    const onCloseSend = ()=> {
        setConfirmationModalInfo(()=>{return {visible:true, goFunction: onCloseRemesa, 
            text:'Se procederá al cierre de la remesa, ¿lo confirma?'}})
    }

    const onOpenCsvModal = ()=> {
        setCsvModalInfo(()=>{return {visible: true, remesaId: remesa.id}})
    }

    return (
        <div className='remesaCard'>
            <div className='remesaName'>{`Remesa ${utilMethods.getStringFromDate(remesa.createdAt)}`}</div>
            <div className='remesaButtons'>
                {remesa.estado === EstadoRemesa.ABIERTA ?
                    <button id='closeRemesaBut' onClick={onCloseSend}><IoMdSend /></button>
                    :
                    <></>
                }
                <button id='downloadCsvBut' onClick={onOpenCsvModal}><RiFileListLine /></button>
            </div>
        </div>
    )
}
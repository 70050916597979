import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { changePasswordModal, ChangePasswordModalProps, isLoading, mailModal, MailModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { TextAreaField } from '../atom/TextAreaField';
import { SizeEnum } from '../../types/types';


export function ChangePasswordModal() {

    const [modalInfo, setModalInfo] = useRecoilState<ChangePasswordModalProps>(changePasswordModal)

    const [mail, setMail] = useState<string | undefined>(undefined)
    const [wrongMail, setWrongMail] = useState<boolean>(false)
    const [sentMail, setSentMail] = useState<boolean>(false)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const { startChangePassword } = useApi()

    const onSend = async () => {
        setIsLoadingState(() => true)
        setWrongMail(() => false)
        if (mail) {
            try {
                await startChangePassword(mail)
                onClear()
                setSentMail(() => true)
                setModalInfo(() => { return { visible: false } })
            } catch (e) {
                setWrongMail(() => true)
            }
            finally {
                setIsLoadingState(() => false)
            }
        }
        setIsLoadingState(() => false)
    }


    const onClear = () => {
        setMail(() => undefined)
    }


    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Cambiar contraseña'}</h1>
                        <button id='modalCloseBut' onClick={() => { onClear(); setSentMail(() => false); setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    {sentMail ?
                        <p>{'Se ha enviado un correo a la dirección proporcionada para continuar el proceso'}</p>
                        :
                        <>
                            <div id='modalForm'>
                                <div className='formConvoModal'>
                                    <TextField id='mail' label='Email' value={mail} setValue={setMail} size={SizeEnum.VB} />
                                </div>
                            </div>
                            <div id='saveButModal'><button onClick={onSend}><AiOutlineSend />{'Enviar'}</button></div>
                            {wrongMail ?
                                <p>{'No se ha encontrado ningún usuario con ese email'}</p>
                                :
                                undefined
                            }
                        </>
                    }
                </>
            </ModalBase>
            :
            <></>
    )
}
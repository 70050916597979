import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';
import { useAuth } from "../../hooks/useAuth";

export function CheckBoxField({ label, id, value, setValue, setFilterPropValue, isCheckField, isError}: {
    label: string, id: string, value: boolean, setValue?: React.Dispatch<React.SetStateAction<boolean>>,
    setFilterPropValue?: (value: boolean, property: string) => void, isCheckField?: boolean, isError?:boolean
}) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    
    const { rol } = useAuth()


    return (
        <div className={'checkBoxFieldBox ' + (isError ? 'errorCheckbox' : '')}>
            <div className='fieldAndBut'>
                <input type={'checkbox'} id={id} checked={value} disabled={(modoConsulta && !isCheckField) || (!setValue && !setFilterPropValue) ? true : false}
                    onChange={setValue ? (setFilterPropValue ? (e) => {setFilterPropValue(e.target.checked, id); setValue(() => e.target.checked); } : (e) => setValue(() => e.target.checked)) : setFilterPropValue ?
                        (e) => setFilterPropValue(e.target.checked, id) : undefined} />
                <label>{label}</label>
            </div>
        </div>
    )

}
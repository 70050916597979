import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Loading } from './components/atom/Loading';
import { ConfirmationModal } from './components/organism/ConfirmationModal';
import { Header } from './components/organism/Header';
import { useAuth } from './hooks/useAuth';
import { CambioContrasenaScreen } from './routes/CambioContrasenaScreen';
import { ConvocatoriasAdmin } from './routes/ConvocatoriasAdmin';
import { ConvocatoriasGestor } from './routes/ConvocatoriasGestor';
import { CreadorSolicitud } from './routes/CreadorSolicitud';
import { CuadroMandos } from './routes/CuadroMandos';
import { CuentaValidadaScreen } from './routes/CuentaValidadaScreen';
import { Inicio } from './routes/Inicio';
import { LoadingScreen } from './routes/LoadingScreen';
import { NotificacionScreen } from './routes/NotificacionScreen';
import { RegistroScreen } from './routes/RegistroScreen';
import { RemesasScreen } from './routes/RemesasScreen';
import { UsuariosAdmin } from './routes/UsuariosAdmin';
import { RolEnum } from './types/types';

function Body() {

  const authInfo = useAuth()

  return (
    <>
      <BrowserRouter>
        {authInfo.completedLoad === true ?
          <>
            <Header />
            <Routes>
              <Route path="/" element={authInfo.isAuth ? authInfo.rol?.nombre === RolEnum.USUARIO ? <ConvocatoriasGestor /> : <CuadroMandos /> : <Inicio />} />
              <Route path="registro" element={<RegistroScreen />} />
              <Route path="convocatorias" element={<ConvocatoriasGestor />} />
              <Route path="cuadroMandos" element={<CuadroMandos />} />
              <Route path="remesas" element={<RemesasScreen />} />
              <Route path="convocatoriasAdmin" element={<ConvocatoriasAdmin />} />
              <Route path="usuariosAdmin" element={<UsuariosAdmin />} />
              <Route path="/editorSolicitud/:solicitudId" element={<CreadorSolicitud />} />
              <Route path="/notificacion/:notificacionId" element={<NotificacionScreen />} />
              <Route path="/validacion/:mail/:code" element={<CuentaValidadaScreen />} />
              <Route path="/cambioPassword/:mail/:code" element={<CambioContrasenaScreen />} />
            </Routes>
            <Loading />
            <ConfirmationModal/>
          </>
          :
          <LoadingScreen/>
        }

      </BrowserRouter>
    </>

  );
}

export default Body;

import React, {  } from 'react';
import moment from 'moment';
import { conf } from '../../conf';
import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';

export function DateField({ id, label, value, setValue, isError}: { id: string, label: string, value: Date | undefined, 
    setValue?: React.Dispatch<React.SetStateAction<Date | undefined>>, isError?:boolean }) {

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)
    


    return (
        <div className={'dateFieldBox'}>
            <label htmlFor={id}>{label}</label>
            <div className={`fieldAndBut`}>
                <input id={id} type={'date'} className={isError ? 'errorField' : ''} value={value ? moment(value).format(conf.dateInputFormat) : undefined} 
                    disabled={modoConsulta || !setValue ? true : false} 
                    onChange={setValue ? (e) => setValue(moment(e.target.value, conf.dateInputFormat).toDate()) : undefined} />
            </div>
        </div>
    )

}
import { useEffect, useState } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { solicitudInfo, reloadFamiliares, isLoading, consultaModo, familiarModal, FamiliarModalProps } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Familiar } from '../../types/entities';
import { FamiliarCard } from '../atom/FamiliarCard';

export function FamiliarList() {

    const [familiarList, setFamiliarList] = useState<Familiar[]>([])
    const [solicitud, setSolicitud] = useRecoilState(solicitudInfo);
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [modalInfo, setModalInfo] = useRecoilState<FamiliarModalProps>(familiarModal)
    const modoConsulta = useRecoilValue<boolean>(consultaModo)

    const { getFamiliaresSolicitud, deleteFamiliar } = useApi()

    const [familiaresReload, setFamiliaresReload] = useRecoilState<boolean>(reloadFamiliares);



    useEffect(() => {
        onGetFamiliares()
    }, [solicitud, familiaresReload])


    const onGetFamiliares = async () => {
        if (solicitud?.id) {
            setIsLoadingState(() => true)
            try {
                const familiarList = await getFamiliaresSolicitud(solicitud?.id)
                setFamiliarList(() => familiarList)
            } catch (e) { }
            setIsLoadingState(() => false)

        }
    }

    const onDeleteFamiliar = async (familiarId: string | undefined) => {
        if (familiarId) {
            setIsLoadingState(() => true)
            try {
                await deleteFamiliar(familiarId)
                setFamiliaresReload((old) => !old)
            } catch (e) { }
            setIsLoadingState(() => false)
        }
    }



    return (
        <div id='familiarList'>
            <h2>{'Familiares'}</h2>
            {modoConsulta ?
                <></>
                :
                <button id='addFamBut' disabled={modoConsulta} onClick={() => setModalInfo(() => { return { visible: true } })}><AiOutlineUserAdd />{'Añadir familiar'}</button>
            }

            {familiarList.length > 0 ?
                familiarList.map((familiar) => <FamiliarCard familiar={familiar} onDelete={() => onDeleteFamiliar(familiar.id)} />)
                :
                <p id='familiaresEmpty'>{'No hay familiares'}</p>
            }
        </div>
    )
}
import { useRecoilState } from 'recoil';
import { autorizacionDatosModal, AutorizacionDatosModalProps } from '../../atoms/mainAtoms';
import { ModalBase } from './ModalBase';
import { AiOutlineClose } from 'react-icons/ai'
import { SizeEnum } from '../../types/types';


export function AutorizacionDatosModal() {

    const [modalInfo, setModalInfo] = useRecoilState<AutorizacionDatosModalProps>(autorizacionDatosModal)

    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Información acerca de los datos y su veracidad'}</h1>
                        <button id='modalCloseBut' onClick={() => { setModalInfo({ visible: false }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalAutorizacion'>
                        <p>Con la presentación de esta solicitud y de acuerdo con el artículo 28.2 de la Ley 39/2015, de 1 de
                            octubre, de Procedimiento Administrativo Común de las Administraciones Públicas, en relación con el
                            derecho a no aportar documentos al procedimiento, se autoriza la consulta u obtención de aquella
                            documentación que haya sido elaborada por cualquier Administración salvo que conste en el
                            procedimiento su oposición expresa. En este sentido, el CABILDO INSULAR DE FUERTEVENTURA
                            consultará, a través de las plataformas de intermediación de datos u otros sistemas electrónicos
                            habilitados al efecto, los documentos necesarios para la resolución del procedimiento al que se refiere
                            el presente formulario y que se citan a continuación:
                        </p>
                        <ul>
                            <li>A la Dirección General de Policía, los datos de identidad.</li>
                            <li>Al Instituto Nacional de Estadística, los datos de empadronamiento.</li>
                        </ul>

                        <p>
                            Puede ejercer su derecho de oposición a través de becas.cabildofuer.es. En todo caso, si ejerce el
                            derecho de oposición, deberá aportar todos los datos y documentos requeridos en el procedimiento.
                        </p>
                        <ul>
                            <li>Declaro que los datos que figuran en la solicitud de registro son ciertos, asumiendo en caso
                                contrario, las responsabilidades que pudieran derivarse de su inexactitud.</li>
                            <li>Doy mi consentimiento</li>
                        </ul>
                        <b>Información importante</b>
                        <p>
                            Al firmar esta petición queda establecido que usted presta su consentimiento expreso al tratamiento
                            de sus datos personales. Lea, antes de firmar, la información básica.
                        </p>
                        <b>Información básica</b>
                        <p>
                            Los datos personales recogidos serán incorporados en el registro de actividades de tratamiento del
                            Cabildo de Fuerteventura en cumplimiento del Reglamento General de Protección de Datos:
                            <ul>

                                <li><b>Responsable del tratamiento:</b> Excmo. Cabildo Insular de Fuerteventura. Calle Primero de Mayo,
                                    39. 35600 Puerto del Rosario.</li>

                                <li><b>Delegada de Protección de Datos:</b> Correo electrónico de contacto: dpd@cabildofuer.es</li>
                                <li><b>Finalidad del tratamiento:</b> Gestión y tramitación de su solicitud.</li>
                                <li><b>Legitimación del tratamiento:</b> RGPD: 6.1.c) Tratamiento necesario para el cumplimiento de una
                                    obligación legal aplicable al responsable del tratamiento. RGPD: 6.1.e) Tratamiento necesario para el
                                    cumplimiento de una misión realizada en interés público o en el ejercicio de poderes públicos
                                    conferidos al responsable del tratamiento. Ley 38/2003, de 17 de noviembre, General de
                                    Subvenciones.</li>
                                <li><b>Destinatarios:</b> No están previstas comunicaciones de datos.</li>
                                <li><b>Transferencias internacionales:</b> No hay transferencia internacional de datos prevista.</li>
                                <li><b>Plazos de conservación:</b> Los plazos legalmente previstos en la normativa vigente / en el caso de
                                    consentimiento mientras no se revoque el mismo.</li>
                                <li><b>Ejercicio de derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación
                                    del tratamiento: </b>Mediante la presentación de un escrito firmado electrónicamente o con copia del
                                    DNI o documento similar dirigidos al Cabildo Insular de Fuerteventura, sito en la calle Primero de
                                    Mayo, nº 39, CP 35600, Puerto del Rosario o a la dirección de correo electrónico dpd@cabildofuer.es.
                                    En el caso que considere que sus derechos no han sido debidamente atendidos, puede presentar una
                                    reclamación ante la Agencia Española de Protección de Datos.</li>
                            </ul>
                        </p>

                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React, { useEffect, useState } from 'react';
import { SolicitudFiltro } from '../../types/entities';
import { MunicipioField } from '../atom/MunicipioField';
import { EstadoField } from '../atom/EstadoField';
import { EstadoCivilField } from '../atom/EstadoCivilField';
import { CheckBoxField } from '../atom/CheckBoxField';
import { PresencialidadField } from '../atom/PresencialidadField';
import { TextField } from '../atom/TextField';
import { GestorField } from '../atom/GestorField';
import { BecaField } from '../atom/BecaField';
import { SiNoNeutroField } from '../atom/SiNoNeutroField';

export function SolicitudFiltroComp({ solicitudFiltro, setSolicitudFiltro }:
    { solicitudFiltro: SolicitudFiltro, setSolicitudFiltro: React.Dispatch<React.SetStateAction<SolicitudFiltro>> }) {

    const [hasCheck, setHasCheck] = useState(false)


    const setFilterPropValue = (value: string | boolean | undefined, property: string) => {
        setSolicitudFiltro((oldFiltro) => {
            const newFiltro: SolicitudFiltro = { ...oldFiltro }
            const newValue = value === '' ? undefined : value
            newFiltro[property as keyof SolicitudFiltro] = newValue as any
            return newFiltro
        })
    }

    return (
        <div id='filtros'>
            <TextField id='nombre' label='Nombre' value={solicitudFiltro.nombre} setFilterPropValue={setFilterPropValue} />
            <TextField id='apellido1' label='Primer apellido' value={solicitudFiltro.apellido1} setFilterPropValue={setFilterPropValue} />
            <TextField id='apellido2' label='Segundo apellido' value={solicitudFiltro.apellido2} setFilterPropValue={setFilterPropValue} />
            <TextField id='numeroDocumento' label='DNI' value={solicitudFiltro.numeroDocumento} setFilterPropValue={setFilterPropValue} />
            {/* <Selec id='gestor' label='Gestor' value={solicitudFiltro.gestor} setFilterPropValue={setFilterPropValue}/> */}
            {/* <TextField id='tipoDeBeca' label='Tipo de beca' value={solicitudFiltro.tipoDeBeca} setFilterPropValue={setFilterPropValue}/> */}
            <SiNoNeutroField id='primerAno' label='¿Es estudiante de primer año?' value={solicitudFiltro.primerAno} setFilterPropValue={setFilterPropValue}/>
            <PresencialidadField id='presencialidad' label='Presencialidad' value={solicitudFiltro.presencialidad} setFilterPropValue={setFilterPropValue} reduced={true} />
            <EstadoField id='estadoExpediente' label='Estado del expediente' value={solicitudFiltro.estadoExpediente} setFilterPropValue={setFilterPropValue} reduced={true} />
            <MunicipioField id='municipio' label='Municipio' value={solicitudFiltro.municipio} setFilterPropValue={setFilterPropValue} reduced={true} />
            <GestorField id='gestor' label='Gestor' value={solicitudFiltro.gestor} setFilterPropValue={setFilterPropValue} reduced={true} />
            <BecaField id='tipoDeBeca' label='Tipo de beca' value={solicitudFiltro.tipoDeBeca} setFilterPropValue={setFilterPropValue} reduced={true} />
            <CheckBoxField id='hasCheck' label='Filtrar por validaciones no superadas' value={hasCheck} setFilterPropValue={setFilterPropValue} setValue={setHasCheck} isCheckField={true} />
            {hasCheck ?
                <>
                    <CheckBoxField id='checkDiscapacidad' label='Consulta sobre datos discapacidad, al MINHAP' value={solicitudFiltro?.checkDiscapacidad ? solicitudFiltro.checkDiscapacidad : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkEmpadronamiento' label='Consulta sobre datos de empadronamiento al INE' value={solicitudFiltro?.checkEmpadronamiento ? solicitudFiltro.checkEmpadronamiento : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkFamiliaNumerosa' label='Consulta sobre datos de familia numerosa, al MINHAP' value={solicitudFiltro?.checkFamiliaNumerosa ? solicitudFiltro.checkFamiliaNumerosa : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkIdentidad' label='Consulta sobre datos de identidad a la Dirección General de Policía' value={solicitudFiltro?.checkIdentidad ? solicitudFiltro.checkIdentidad : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkMatricula' label='Consulta de datos de matrícula y calificaciones, al Gobierno de Canarias' value={solicitudFiltro?.checkMatricula ? solicitudFiltro.checkMatricula : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkMatriculaUniversidad' label='Consulta de datos matrícula universitaria, a la CRUE' value={solicitudFiltro?.checkMatriculaUniversidad ? solicitudFiltro.checkMatriculaUniversidad : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkPrestaciones' label='Consulta sobre datos de prestaciones, al SEPE' value={solicitudFiltro?.checkPrestaciones ? solicitudFiltro.checkPrestaciones : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkRenta' label='Consulta sobre nivel de renta para becas a la Agencia Tributaria' value={solicitudFiltro?.checkRenta ? solicitudFiltro.checkRenta : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkTitulos' label='Consulta sobre datos de títulos universitarios y no universitarios, al Ministerio de Educación' value={solicitudFiltro?.checkTitulos ? solicitudFiltro.checkTitulos : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                    <CheckBoxField id='checkViolencia' label='Consulta beneficiario de derechos por ser víctima de violencia de género' value={solicitudFiltro?.checkViolencia ? solicitudFiltro.checkViolencia : false} setFilterPropValue={setFilterPropValue} isCheckField={true} />
                </>
                :
                undefined
            }
            {/* <TextField id='numeroResolucion' label='Número de resolución' value={solicitudFiltro.numeroResolucion} setFilterPropValue={setFilterPropValue}/> */}
            {/* <CheckBoxField id='padresDivorciados' label='Padres divorciados' value={solicitudFiltro.padresDivorciados} setFilterPropValue={setFilterPropValue}/> */}
        </div>
    )
}

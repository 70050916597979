import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { consultaModo, convocatoriaConSolSelected, familiarModal, FamiliarModalProps } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { Convocatoria, ConvocatoriaConSolicitud, Familiar } from '../../types/entities';
import { SolicitudEstado } from './SolicitudEstado';
import './../../index.css';
import {conf} from '../../conf'
import {RiDeleteBack2Line} from 'react-icons/ri'

export function FamiliarCard ({familiar, onDelete}:{familiar:Familiar, onDelete: ()=>void}) {

    const modoConsulta = useRecoilValue<boolean>(consultaModo)

    const [modalInfo, setModalInfo] = useRecoilState<FamiliarModalProps>(familiarModal)

    const onEditFamiliar = async()=> {
        setModalInfo(()=>{return {visible:true, familiarId:familiar.id}})
    }

    return (
        <div className='familiarCard' onClick={onEditFamiliar}>
            <p className='familiarName'>{`${familiar.nombre} ${familiar.apellido1} ${familiar.apellido2}`}</p>
            {modoConsulta? <></>:<button id='deleteFamiliarBut' onClick={(e)=>{e.stopPropagation();onDelete()}}><RiDeleteBack2Line/></button>}
        </div>
)
}
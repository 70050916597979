import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { useApi } from '../../hooks/useApi';
import { SelectOption, EstadoCivilEnum, RolEnum } from '../../types/types';

export function RolField({ id, label, value, setValue, reduced, isError }:
    {
        id: string, label: string, value: string | undefined,
        setValue?: React.Dispatch<React.SetStateAction<string | undefined>>
        reduced?: boolean, 
        isError?: boolean
    }) {

    const [rolesList, setRolesList] = useState<SelectOption[]>([])
    const { getRoles } = useApi()


    useEffect(() => {
        onGetRoles()
    }, [])

    const onGetRoles = async () => {
        const roles = await getRoles()
        const rolesForSelect: SelectOption[] = []
        roles.map((rol) => {
            rolesForSelect.push({ value: rol.id, label: rol.nombre })
        })
        setRolesList(() => rolesForSelect)
    }

    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '60%' : '100%' }} disabled={!setValue ? true : false} className={'selectField ' + (isError ? 'errorField' : '')} value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e.target.value) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar rol'}</option>
                    {rolesList.map((rol) =>
                        <option value={rol.value}>{rol.label}</option>
                    )}
                </select>
            </div>
        </div>
    )
}
import { useEffect, useState } from 'react';
import { Paginacion } from '../components/molecule/Paginacion';
import { useApi } from '../hooks/useApi';
import { Convocatoria } from '../types/entities';
import { convocatoriaModal, ConvocatoriaModalProps, isLoading, reloadConvoAdmin } from '../atoms/mainAtoms'
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuth } from '../hooks/useAuth';
import { RolEnum } from '../types/types';
import { IoMdAdd } from 'react-icons/io'
import { ConvocatoriaAdminCard } from '../components/molecule/ConvocatoriaAdminCard';
import { ConvocatoriaModal } from '../components/organism/ConvocatoriaModal';
import { SendRentasModal } from '../components/organism/SendRentasModal';
import { CalculationResultModal } from '../components/organism/CalculationResultModal';


export function ConvocatoriasAdmin({ }: {}) {

    const [convocatoriasList, setConvocatoriasList] = useState<Convocatoria[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const convoAdminReload = useRecoilValue<boolean>(reloadConvoAdmin)
    const [modalInfo, setModalInfo] = useRecoilState<ConvocatoriaModalProps>(convocatoriaModal)



    const { getConvocatorias } = useApi()
    const { rol } = useAuth()

    useEffect(() => {
        onGetConvocatorias()
    }, [page, convoAdminReload])

    const onGetConvocatorias = async () => {
        setIsLoadingState(() => true)
        try {
            const solicitudesList = await getConvocatorias(page)
            setTotalPages(() => solicitudesList.paginasTotales)
            setConvocatoriasList(() => solicitudesList.content)
        } catch { }
        finally {
            setIsLoadingState(() => false)
        }
    }

    const onCreateConvocatoria = () => {
        setModalInfo(() => { return { visible: true } })
    }

    return (
        (rol && rol.nombre === RolEnum.ADMIN) ?
            <div id='cuadroMandos'>
                <h1>Administración de convocatorias</h1>
                <div id='convocatoriasAdminTable'>
                    <div id='convocatoriasAdminList'>
                        {convocatoriasList.map((convocatoria) =>
                            <ConvocatoriaAdminCard convocatoria={convocatoria} />
                        )}
                        <button id='addConvoButton' onClick={onCreateConvocatoria}><IoMdAdd />Añadir</button>
                    </div>
                    <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'3vh'} />
                </div>
                <ConvocatoriaModal />
                <SendRentasModal/>
                <CalculationResultModal/>
            </div>
            :
            <></>
    )
}

import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { RiDeleteBack2Line } from 'react-icons/ri';

import { useApi } from '../../hooks/useApi';
import { Municipio } from '../../types/entities';
import { SelectOption } from '../../types/types';
import { useRecoilState } from 'recoil';
import { consultaModo} from '../../atoms/mainAtoms';

export function MunicipioField({ id, label, value, idValue, setValue, setFilterPropValue, reduced, isError}: {
    id: string, label: string,
    value?: string, idValue?: string,
    setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void,
    reduced?: boolean, isError?:boolean
}) {

    const [municipiosList, setMunicipiosList] = useState<SelectOption[]>([])
    const { getMunicipios } = useApi()

    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    useEffect(() => {
        onGetMunicipios()
    }, [])

    const onGetMunicipios = async () => {
        const municipios = await getMunicipios()
        const municipiosForSelect: SelectOption[] = []
        municipios.map((municipio) => {
            municipiosForSelect.push({ value: municipio.id, label: municipio.nombre })
        })
        setMunicipiosList(() => municipiosForSelect)
    }


    return (
        <div className={'selectFieldBox'}>
            <label>{label}</label>
            <div className='filterAndBut'>
                <select style={{ width: reduced ? '80%' : '100%' }} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className={'selectField ' + (isError ? ' errorField' : '')} value={value} 
                    onChange={setValue ? (e) => setValue(e?.target.value === '0' ? undefined : e?.target.value) : 
                    setFilterPropValue ? (e) => setFilterPropValue(e?.target.value === '0' ? undefined : e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar municipio'}</option>
                    {municipiosList.map((municipio) =>
                        <option value={municipio.value}>{municipio.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue("0", id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

import React from 'react';
import { SizeEnum } from '../../types/types';


export function ModalBase({ children, size }: { children: JSX.Element, size?: SizeEnum }) {

    const getSize = () => {
        switch (size) {
            case SizeEnum.B:
                return 'modalInsideBox'
            case SizeEnum.M:
                return 'modalInsideBox modalInsideBoxReduced'
            case SizeEnum.S:
                return 'modalInsideBox modalInsideBoxVeryReduced'
            case SizeEnum.XS:
                return 'modalInsideBox modalInsideBoxMinimal'
            default:
                return 'modalInsideBox'
        }
    }


    return (
        <div id='modal'>
            <div className={getSize()}>
                {children}
            </div>
        </div>
    )
}
import { useEffect, useState } from 'react';
import { AiOutlineSave } from 'react-icons/ai';
import { useRecoilState, useRecoilValue } from 'recoil';
import { consultaModo, isLoading, reloadFiles, solicitudInfo} from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { RequiredDocs, Solicitud } from '../../types/entities';
import { RolEnum } from '../../types/types';
import { utilMethods } from '../../utils/utilMethods';
import { SiNoField } from '../atom/SiNoField';
import { SiNoNeutroField } from '../atom/SiNoNeutroField';

export function SolicitudStepAutorizaciones({ }: {}) {

    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    const [consultaIdentidad, setConsultaIdentidad] = useState<boolean | undefined>(solicitud?.consultaIdentidad);
    const [consultaEmpadronamiento, setConsultaEmpadronamiento] = useState<boolean | undefined>(solicitud?.consultaEmpadronamiento);
    const [consultaMatricula, setConsultaMatricula] = useState<boolean | undefined>(solicitud?.consultaMatricula);
    const [consultaDiscapacidad, setConsultaDiscapacidad] = useState<boolean | undefined>(solicitud?.consultaDiscapacidad);
    const [consultaFamiliaNumerosa, setConsultaFamiliaNumerosa] = useState<boolean | undefined>(solicitud?.consultaFamiliaNumerosa);
    const [consultaMatriculaUniversidad, setConsultaMatriculaUniversidad] = useState<boolean | undefined>(solicitud?.consultaMatriculaUniversidad);
    const [consultaPrestaciones, setConsultaPrestaciones] = useState<boolean | undefined>(solicitud?.consultaPrestaciones);
    const [consultaRenta, setConsultaRenta] = useState<boolean | undefined>(solicitud?.consultaRenta);
    const [consultaTitulos, setConsultaTitulos] = useState<boolean | undefined>(solicitud?.consultaTitulos);
    const [consultaViolencia, setConsultaViolencia] = useState<boolean | undefined>(solicitud?.consultaViolencia);

    const [requiredDocs, setRequiredDocs] = useState<RequiredDocs | undefined>(undefined);


    const [motivo, setMotivo] = useState<string | undefined>(solicitud?.motivo);

    const modoConsulta = useRecoilValue<boolean | undefined>(consultaModo)

    const isPresent = utilMethods.isPresent

    const { updateSolicitud, getRequiredDocs } = useApi()

    const { rol } = useAuth()
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        onGetRequiredDocs()
    }, [])

    const onGetRequiredDocs = async () => {
        if (solicitud?.id) {
            const requiredDocs = await getRequiredDocs(solicitud?.id)
            setRequiredDocs(() => requiredDocs)
        }
    }

    const onSave = async () => {
        if (solicitud) {
            setIsLoadingState(() => true)
            const newSolicitud: Solicitud = { ...solicitud }
            newSolicitud.consultaIdentidad = consultaIdentidad;
            newSolicitud.consultaEmpadronamiento = consultaEmpadronamiento;
            newSolicitud.consultaMatricula = consultaMatricula;
            newSolicitud.consultaDiscapacidad = consultaDiscapacidad;
            newSolicitud.consultaFamiliaNumerosa = consultaFamiliaNumerosa;
            newSolicitud.consultaMatriculaUniversidad = consultaMatriculaUniversidad;
            newSolicitud.consultaPrestaciones = consultaPrestaciones;
            newSolicitud.consultaRenta = consultaRenta;
            newSolicitud.consultaTitulos = consultaTitulos;
            newSolicitud.consultaViolencia = consultaViolencia;
            newSolicitud.motivo = motivo;

            try {
                setVisible(false)
                const updatedSolicitud = await updateSolicitud(newSolicitud)
                setSolicitud(() => updatedSolicitud)
                setIsLoadingState(() => false)
            } catch { }
            setIsLoadingState(() => false)
        }
    }



    return (
        solicitud !== undefined ?

            <div id='stepBox'>
                <div id='stepHeader'>
                    <h1>{'Autorizaciones'}</h1>
                </div>
                <div id="centralForm">
                    <label>Con la presentación de esta solicitud y de acuerdo con el artículo 28.2 de la Ley 39/2015, de 1 de octubre, de Procedimiento Administrativo Común de las Administraciones Públicas, en relación con el derecho a no aportar documentos al procedimiento, se autoriza la consulta u obtención de aquella documentación que haya sido elaborada por cualquier Administración salvo que conste en el procedimiento su oposición expresa.
                        En este sentido, el CABILDO INSULAR DE FUERTEVENTURA consultará, a través de las plataformas de intermediación de datos u otros sistemas electrónicos habilitados al efecto, los documentos necesarios para la resolución del procedimiento al que se refiere el presente formulario y que se citan a continuación:
                        Puede ejercer su derecho de oposición a través de becas.cabildofuer.es. En todo caso, si ejerce el derecho de oposición, deberá aportar todos los datos y documentos requeridos en el procedimiento.</label>
                    <ul>
                        <li>A la Dirección General de Policía, los datos de identidad.</li>
                        <li>Al Instuto Nacional de Estadística, los datos de empadronamiento.</li>
                        <li>Al Gobierno de Canarias, los datos de matrícula y calificaciones.</li>
                    </ul>
                    <label>Puede ejercer su derecho de oposición a través de becas.cabildofuer.es. En todo caso, si ejerce el derecho de oposición, deberá aportar todos los datos y documentos requeridos en el procedimiento.</label>

                    <div className="autorizations">
                        <p>Consulta sobre datos de identidad a la Dirección General de Policía</p>
                        <div id='aceptoBoxAuto'>
                            <SiNoNeutroField id='consultaIdentidad' label='Acepto' value={consultaIdentidad} setValue={setConsultaIdentidad} />
                        </div>
                    </div>

                    <div className="autorizations">
                        <p>Consulta sobre datos de empadronamiento al INE</p>
                        <div id='aceptoBoxAuto'>
                            <SiNoNeutroField id='consultaEmpadronamiento' label='Acepto' value={consultaEmpadronamiento} setValue={setConsultaEmpadronamiento} />
                        </div>
                    </div>

                    {requiredDocs?.matriculaCalificaciones && isPresent('consultaMatricula', solicitud) ?
                        <div className="autorizations">
                            <p>Consulta de datos de matrícula y calificaciones, al Gobierno de Canarias.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaMatricula' label='Acepto' value={consultaMatricula} setValue={setConsultaMatricula} />
                            </div>
                        </div>

                        : undefined
                    }

                    <div className="autorizations">
                        <p>Consulta sobre nivel de renta para becas a la Agencia Tributaria.</p>
                        <div id='aceptoBoxAuto'>
                            <SiNoNeutroField id='consultaRenta' label='Acepto' value={consultaRenta} setValue={setConsultaRenta} />
                        </div>
                    </div>


                    {requiredDocs?.matriculaUniversitaria && isPresent('consultaMatriculaUniversidad', solicitud) ?
                        <div className="autorizations">
                            <p>Consulta de datos matrícula universitaria, a la CRUE.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaMatriculaUniversidad' label='Acepto' value={consultaMatriculaUniversidad} setValue={setConsultaMatriculaUniversidad} />
                            </div>
                        </div>

                        : undefined
                    }

                    {requiredDocs?.titulos && isPresent('consultaTitulos', solicitud) ?
                        <div className="autorizations">
                            <p>Consulta sobre datos de títulos universitarios, al Ministerio de Educación.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaTitulos' label='Acepto' value={consultaTitulos} setValue={setConsultaTitulos} />
                            </div>
                        </div>

                        : undefined
                    }

                    {requiredDocs?.discapacidad ?
                        <div className="autorizations">
                            <p>Consulta sobre datos discapacidad, al MINHAP.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaDiscapacidad' label='Acepto' value={consultaDiscapacidad} setValue={setConsultaDiscapacidad} />
                            </div>
                        </div>

                        : undefined
                    }

                    {requiredDocs?.familiaNumerosa ?
                        <div className="autorizations">
                            <p>Consulta sobre datos de familia numerosa, al MINHAP.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaFamiliaNumerosa' label='Acepto' value={consultaFamiliaNumerosa} setValue={setConsultaFamiliaNumerosa} />
                            </div>
                        </div>

                        : undefined
                    }

                    {requiredDocs?.prestaciones ?
                        <div className="autorizations">
                            <p>Consulta sobre datos de prestaciones, al SEPE.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaPrestaciones' label='Acepto' value={consultaPrestaciones} setValue={setConsultaPrestaciones} />
                            </div>
                        </div>

                        : undefined
                    }

                    {requiredDocs?.violenciaGenero ?
                        <div className="autorizations">
                            <p>Consulta beneficiario de derechos por ser víctima de violencia de género.</p>
                            <div id='aceptoBoxAuto'>
                                <SiNoNeutroField id='consultaViolencia' label='Acepto' value={consultaViolencia} setValue={setConsultaViolencia} />
                            </div>
                        </div>

                        : undefined
                    }

                    {(requiredDocs?.discapacidad && !consultaDiscapacidad)
                        || !consultaEmpadronamiento
                        || (requiredDocs?.familiaNumerosa && !consultaFamiliaNumerosa)
                        || !consultaIdentidad
                        || (requiredDocs?.matriculaCalificaciones && isPresent('consultaMatricula', solicitud) && !consultaMatricula)
                        || (requiredDocs?.matriculaUniversitaria && isPresent('consultaMatriculaUniversidad', solicitud) && !consultaMatriculaUniversidad)
                        || (requiredDocs?.prestaciones && isPresent('consultaPrestaciones', solicitud) && !consultaPrestaciones)
                        || !consultaRenta
                        || (requiredDocs?.titulos && isPresent('consultaTitulos', solicitud) && !consultaTitulos)
                        || (requiredDocs?.violenciaGenero && !consultaViolencia) ?
                        <div className="autorizations">
                            <label>Motivo de la oposición</label>
                            <textarea disabled={modoConsulta} value={motivo} onChange={(e) => setMotivo(e.target.value)} />
                        </div>
                        :
                        <></>
                    }
                </div>

                {!modoConsulta  ? <div id='saveBut'><button onClick={() => onSave()}><AiOutlineSave />{'Guardar'}</button></div> : <></>}

            </div>
            : <></>
    )

}

import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { calculationResultModal, CalculationResultModalProps, isLoading, mailModal, MailModalProps } from '../../atoms/mainAtoms';
import { TextField } from '../atom/TextField';
import { ModalBase } from './ModalBase';
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { useApi } from '../../hooks/useApi';
import { TextAreaField } from '../atom/TextAreaField';
import { SizeEnum } from '../../types/types';
import { CalculoResultado } from '../../types/entities';


export function CalculationResultModal() {

    const [modalInfo, setModalInfo] = useRecoilState<CalculationResultModalProps>(calculationResultModal)

    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)

    return (
        modalInfo.visible ?
            <ModalBase size={SizeEnum.S}>
                <>
                    <div id='modalHeader'>
                        <h1>{'Resultado de cálculo'}</h1>
                        <button id='modalCloseBut' onClick={() => { setModalInfo({ visible: false, calcuResult: undefined }) }}><AiOutlineClose /></button>
                    </div>
                    <div id='modalForm'>
                        {modalInfo.calcuResult ?
                            modalInfo.calcuResult.length > 0 ?
                                <div className='calcResultTable'>
                                    <div className='calcResultRow'>
                                        <div className='calcResultId header'>
                                            {'ID solicitud'}
                                        </div>
                                        <div className={'calcResultResult header'}>
                                            {'Resultado'}
                                        </div>
                                        <div className='calcResultPuntos header'>
                                            {'Puntuación'}
                                        </div>
                                        <div className='calcResultCuantia header'>
                                            {'Cuantía'}
                                        </div>
                                    </div>
                                    {modalInfo.calcuResult.map((calcResult) =>

                                        <div className='calcResultRow'>
                                            <div className='calcResultId'>
                                                {`${calcResult.id}`}
                                            </div>
                                            <div className={`calcResultResult ${calcResult.aprobada ? 'calcCorrecto' : 'calcErroneo'}`}>
                                                {calcResult.aprobada}
                                                {calcResult.aprobada ? 'Correcta' : 'Errónea'}
                                            </div>
                                            <div className='calcResultPuntos'>
                                                {calcResult.puntuacion ? `${calcResult.puntuacion} puntos` : '-'}
                                            </div>
                                            <div className='calcResultCuantia'>
                                                {calcResult.cuantia ? `${calcResult.cuantia} €` : '-'}
                                            </div>
                                        </div>)
                                    }
                                </div>
                                :
                                <p>{'La convocatoria no tiene ninguna solicitud pendiente de calcular'}</p>
                            :
                            <p>{'Ha ocurrido un error al realizar el cálculo de las solicitudes'}</p>
                        }
                    </div>
                </>
            </ModalBase>
            :
            <></>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineSave } from 'react-icons/ai';
import { IoMdSend } from 'react-icons/io';
import { MdOutlineEditNote } from 'react-icons/md';
import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoading, solicitudInfo, reloadSolicitud, confirmationModal, ConfirmationModalProps, ValidationErrorModalProps, validationErrorModal } from '../../atoms/mainAtoms';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { Solicitud } from '../../types/entities';
import { EstadoSolicitud, RolEnum, SolicitudStep } from '../../types/types';
import { SolicitudEstado } from '../atom/SolicitudEstado';
import { ValidationErrorModal } from '../organism/ValidationErrorModal';

export function StepControl({ step, setStep }: { step: number, setStep: React.Dispatch<React.SetStateAction<number>> }) {

    const [prevStep, setPrevStep] = useState<string | undefined>(undefined)
    const [nextStep, setNextStep] = useState<string | undefined>(undefined)
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const [solicitud, setSolicitud] = useRecoilState<Solicitud | undefined>(solicitudInfo)
    const [solicitudReload, setSolicitudReload] = useRecoilState<boolean>(reloadSolicitud)
    const [confirmationModalInfo, setConfirmationModalInfo] = useRecoilState<ConfirmationModalProps>(confirmationModal)
    const [validationErrorModalInfo, setValidationErrorModalInfo] = useRecoilState<ValidationErrorModalProps>(validationErrorModal)
    const [visible, setVisible] = useState(false)
    const [advertencia, setAdvertencia] = useState('')
    const envioMsgRef = useRef<null | HTMLDivElement>(null)


    const { updateSolicitud, corregirSolicitud, enviarSolicitud } = useApi()
    const { rol } = useAuth()


    useEffect(() => {
        getPrevAndNextSteps()
    }, [step])


    const onCorregir = async () => {
        if (solicitud?.id) {
            setIsLoadingState(() => true)
            try {
                await corregirSolicitud(solicitud.id)
                setSolicitudReload((old) => !old)
            } catch { }
            setIsLoadingState(() => false)
        }
    }

    const onCorregirSend = ()=> {
        setConfirmationModalInfo(()=>{return {visible:true, goFunction: onCorregir, text:'Se procederá a la corrección de la solicitud, ¿lo confirma?'}})
    }


    const onSend = async () => {
        if (solicitud?.id) {
            setIsLoadingState(() => true)
            const advert = verificarCamposObligatorios()

            if (!advert) {
                setVisible(() => false)
                try {
                    await enviarSolicitud(solicitud.id)
                    setSolicitudReload((old) => !old)
                } catch (e) {
                    if (e instanceof Error && e.name === 'invalid') {
                        const errorList:string[] = e.message.split(',')
                        setValidationErrorModalInfo(()=>{return {visible:true, validationErrors:errorList}})
                    } else {
                        throw e;
                    }
                }
            } else {
                setVisible(() => true)
            }
            setIsLoadingState(() => false)
            setTimeout(() => {
                if (envioMsgRef.current) {
                    envioMsgRef.current.scrollIntoView()
                }
            }, 1000);
        }
    }

    const onConfirmSend = ()=> {
        setConfirmationModalInfo(()=>{return {visible:true, goFunction: onSend, text:'Se procederá a enviar su solicitud, ¿lo confirma?'}})
    }

    const verificarCamposObligatorios = () => {
        let errorFields = []

        if (solicitud?.usuario?.nombre === undefined) {
            errorFields.push('Nombre')
        }
        if (solicitud?.usuario?.apellido1 === undefined) {
            errorFields.push('Primer apellido')
        }
        if (solicitud?.usuario?.apellido2 === undefined) {
            errorFields.push('Segundo apellido')
        }
        if (solicitud?.usuario?.numeroDocumento === undefined) {
            errorFields.push('Número del documento de identidad')
        }
        if (solicitud?.usuario?.numeroSoporteDocumento === undefined) {
            errorFields.push('Numero de soporte de documento')
        }
        if (solicitud?.usuario?.municipioAntiguo === undefined) {
            errorFields.push('Municipio antiguo')
        }
        if (solicitud?.usuario?.municipioActual === undefined) {
            errorFields.push('Municipio actual')
        }
        if (solicitud?.centroEstudios === undefined) {
            errorFields.push('Centro de estudios')
        }
        if (solicitud?.numeroCial === undefined || solicitud?.numeroCial === '') {
            errorFields.push('Numero cial')
        }
        if (solicitud?.declaracion === undefined) {
            errorFields.push('Declaración jurada')
        }
        if (solicitud?.consultaEmpadronamiento === undefined) {
            errorFields.push('Autorización de consulta sobre datos de empadronamiento')
        }
        if (solicitud?.consultaIdentidad === undefined) {
            errorFields.push('Autorización de consulta sobre datos de identidad')
        }
        if (solicitud?.consultaMatricula === undefined) {
            errorFields.push('Autorización de consulta de datos de matricula')
        }

        if (errorFields.length > 0) {
            let advertencia = errorFields.join(" / ")
            setAdvertencia(() => advertencia)
            return advertencia
        }
    }

    const getPrevAndNextSteps = () => {
        switch (step) {
            case SolicitudStep.PERSONALES:
                setPrevStep(() => undefined)
                setNextStep(() => 'Datos académicos')
                break;
            case SolicitudStep.ACADEMICOS:
                setPrevStep(() => 'Datos personales')
                setNextStep(() => 'Datos económicos')
                break;
            case SolicitudStep.ECONOMICOS:
                setPrevStep(() => 'Datos académicos')
                setNextStep(() => 'Declaración jurada')
                break;
            case SolicitudStep.DEDUCCIONES:
                setPrevStep(() => 'Datos económicos')
                setNextStep(() => 'Declaraciones')
                break;
            case SolicitudStep.DECLARACIONES:
                setPrevStep(() => 'Datos económicos')
                setNextStep(() => 'Autorizaciones')
                break;
            case SolicitudStep.AUTORIZACIONES:
                setPrevStep(() => 'Declaración jurada')
                setNextStep(() => 'Documentación')
                break;
            case SolicitudStep.DOCUMENTOS:
                setPrevStep(() => 'Autorizaciones')
                setNextStep(() => undefined)
                break;

        }
    }


    return (

        <>
            {prevStep ? <button className='stepButton stepLeft' onClick={() => setStep((oldStep) => oldStep - 1)}><AiOutlineCaretLeft />{prevStep}</button> : <></>}
            {nextStep ?
                <button className='stepButton stepRight' onClick={() => setStep((oldStep) => oldStep + 1)}>{nextStep}<AiOutlineCaretRight /></button>
                :
                solicitud?.estado === EstadoSolicitud.ENVIADA || solicitud?.estado === EstadoSolicitud.SUBSANACION ?
                    rol?.nombre === RolEnum.USUARIO ?
                        <></>
                        :
                        <button className='stepButton stepRight' onClick={() => onCorregirSend()}><MdOutlineEditNote />{'Corregir'}</button>
                    :
                    rol?.nombre === RolEnum.USUARIO && solicitud?.estado === EstadoSolicitud.BORRADOR ?
                        <>
                            <button className='stepButton stepRight' onClick={onConfirmSend}><IoMdSend />{'Finalizar'}</button>
                            {visible ?
                                <div className='advertencia'>Faltan por cubrir los campos de {advertencia}</div>
                                :
                                null}
                        </>

                        :
                        rol?.nombre === RolEnum.USUARIO && solicitud?.estado === EstadoSolicitud.PENDIENTE_ENVIO ?
                            <div ref={envioMsgRef} className='envioMsg'>
                                <p>{'Su envío necesita confirmación.'}</p>
                                <p>{'Se le ha enviado un email con los pasos a seguir'}</p>
                            </div>
                            :
                            <></>
            }
            <ValidationErrorModal/>
        </>
    )
}

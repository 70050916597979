import { useEffect, useState } from 'react';
import { Paginacion } from '../components/molecule/Paginacion';
import { useApi } from '../hooks/useApi';
import { SolicitudFiltro, Solicitud, Usuario } from '../types/entities';
import { isLoading, reloadCuadroMandos, userInfo } from '../atoms/mainAtoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SolicitudCard } from '../components/molecule/SolicitudCard';
import { SolicitudFiltroComp } from '../components/molecule/SolicitudFiltroComp';
import { useAuth } from '../hooks/useAuth';
import { RolEnum } from '../types/types';
import { MailModal } from '../components/organism/MailModal';


export function CuadroMandos({ }: {}) {

    const [solicitudesList, setSolicitudesList] = useState<Solicitud[]>([])
    const [page, setPage] = useState<number>(0)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [solicitudFiltro, setSolicitudFiltro] = useState<SolicitudFiltro>({})
    const [isLoadingState, setIsLoadingState] = useRecoilState<boolean>(isLoading)
    const cuadroMandosReload = useRecoilValue<boolean>(reloadCuadroMandos)
    const [gestores, setGestores] = useState<Usuario[]>([]);



    const { getSolicitudesFiltro, getGestores } = useApi()
    const { rol } = useAuth()
    const setUserInfo = useSetRecoilState<Usuario | undefined>(userInfo)


    useEffect(() => {
        onGetGestores()
    }, [])

    useEffect(() => {
        onGetSolicitudes()
    }, [page, solicitudFiltro, cuadroMandosReload])

    const onGetSolicitudes = async () => {
        setIsLoadingState(() => true)
        try {
            if (solicitudFiltro.municipio === '0') {
                solicitudFiltro.municipio = undefined
            }
            if (solicitudFiltro.estadoExpediente === '0') {
                solicitudFiltro.estadoExpediente = undefined
            }
            if (solicitudFiltro.presencialidad === '0') {
                solicitudFiltro.presencialidad = undefined
            }
            if (solicitudFiltro.gestor === '0') {
                solicitudFiltro.gestor = undefined
            }
            const solicitudesList = await getSolicitudesFiltro(solicitudFiltro, page)
            setTotalPages(() => solicitudesList.paginasTotales)
            setSolicitudesList(() => solicitudesList.content)
        } catch { }
        setIsLoadingState(() => false)
    }

    const onGetGestores = async () => {
        const gest = await getGestores();
        setGestores(gest);
    }


    return (
        (rol && rol.nombre !== RolEnum.USUARIO) ?
            <div id='cuadroMandos'>
                <h1>Cuadro de mandos</h1>
                <div id='filtrosYTabla'>
                    <SolicitudFiltroComp solicitudFiltro={solicitudFiltro} setSolicitudFiltro={setSolicitudFiltro} />
                    <div id='solicitudesTable'>
                        <div id='solicitudList'>
                            {solicitudesList.map((solicitud) =>
                                <SolicitudCard solicitud={solicitud} gestores={gestores} />
                            )}
                        </div>
                        <Paginacion page={page} setPage={setPage} totalPages={totalPages} marginTop={'3vh'} />
                    </div>
                </div>
                <MailModal />
            </div>
            :
            <></>
    )
}

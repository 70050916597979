import React, { useEffect, useState } from 'react';
import { RiDeleteBack2Line } from 'react-icons/ri';
import { HiOutlineSearch } from 'react-icons/hi';
import { useApi } from '../../hooks/useApi';
import { SelectOption, TipoCentroEstudiosEnum, TipoEstudiosEnum } from '../../types/types';
import { useRecoilState } from 'recoil';
import { consultaModo } from '../../atoms/mainAtoms';

export function CentroEstudiosField({ id, label, value, idValue, setValue, setFilterPropValue, reduced, tipoEstudios }: {
    id: string, label: string,
    value?: string, idValue?: string,
    setValue?: React.Dispatch<React.SetStateAction<string | undefined>>,
    setFilterPropValue?: (value: string | boolean | undefined, property: string) => void,
    reduced?: boolean,
    tipoEstudios?: string
}) {

    const [centrosList, setCentrosList] = useState<SelectOption[]>([])
    const { getCentrosEstudios, getCentrosEstudiosByTipo } = useApi()
    const [searchText, setSearchText] = useState<string>('')
    const [textVisible, setTextVisible] = useState(false)
    const [modoConsulta, setModoConsulta] = useRecoilState<boolean>(consultaModo)

    useEffect(() => {
        onGetCentros()
    }, [tipoEstudios, searchText])

    useEffect(() => {
        if (setValue) {
            setValue(() => undefined)
        }
    }, [tipoEstudios])


    const onGetCentros = async () => {
        let centros = []
        if (tipoEstudios !== undefined) {
            if (tipoEstudios === TipoEstudiosEnum.ESO || tipoEstudios === TipoEstudiosEnum.BACH || tipoEstudios === TipoEstudiosEnum.FP
                || tipoEstudios === TipoEstudiosEnum.ACCESO_FP) {
                centros = await getCentrosEstudiosByTipo(TipoCentroEstudiosEnum.INSTITUTO)
            } else {
                centros = await getCentrosEstudiosByTipo(TipoCentroEstudiosEnum.UNIVERSIDAD)
            }
            const centrosForSelect: SelectOption[] = []
            centros.forEach((centro) => {
                centrosForSelect.push({ value: centro.nombre, label: centro.nombre })
            })
            const regex = new RegExp(searchText);
            const filteredCentros = centrosForSelect.filter((centro) => regex.test(centro?.label ? centro.label.toLowerCase() : ''))
            filteredCentros.push({ value: 'Otro', label: 'Otro' })
            if (setValue) {
                setValue(() => undefined)
            }
            setCentrosList(() => filteredCentros)
        } else {
            setCentrosList(() => [])
        }
    }



    return (
        <div className={'selectFieldBox'}>
            <div className='centroBox'>
                <label>{label}</label>
                <div className='searchCentroBox'>
                    <HiOutlineSearch />
                    <input type={'text'} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false}
                        className={'searchCentroInput'} onChange={(e) => (setSearchText(() => e.target.value))}></input>
                </div>
            </div>
            <div className='filterAndBut centroBox'>
                <select style={{ width: reduced ? '60%' : '100%' }} disabled={modoConsulta || (!setValue && !setFilterPropValue) ? true : false} className='selectField' value={value}
                    onChange={setValue ? (e) => setValue(e?.target.value) : setFilterPropValue ? (e) => setFilterPropValue(e?.target.value, id) : undefined} >
                    <option selected={true} value={'0'}>{'Seleccionar centro'}</option>
                    {centrosList.map((centro) =>
                        <option selected={centro.value === value} value={centro.value}>{centro.label}</option>
                    )}
                </select>
                {setFilterPropValue ?
                    <button className='deleteFilterBut' onClick={() => setFilterPropValue("0", id)}><RiDeleteBack2Line /></button>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

import { RecoilRoot } from 'recoil';
import Body from './Body';
import { Loading } from './components/atom/Loading';
import { ApiProvider } from './hooks/useApi';
import { AuthProvider } from './hooks/useAuth';




function App() {
  return (
    <RecoilRoot>
      <ApiProvider>
        <AuthProvider>
          <Loading/>
          <Body />
        </AuthProvider>
      </ApiProvider>
    </RecoilRoot>
  );
}

export default App;

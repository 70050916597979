
export function CsvField({ label, id, fieldList, setFieldList}: {
    label: string, id: string, fieldList: string[], setFieldList: React.Dispatch<React.SetStateAction<string[]>>}) {

    const isChecked = ()=> {
        return fieldList.includes(id)
    }

    const onToogleCheckbox = (checked:boolean)=> {
        const oldFieldList = [...fieldList]
        let newFieldList:string[] = []
        if (checked) {
            newFieldList = [id, ...fieldList]
        } else {
            newFieldList = oldFieldList.filter((field)=>field !== id)
        }
        setFieldList(()=>newFieldList)
    }



    return (
        <div className={'checkBoxFieldBoxCsv'}>
            <div className='fieldAndBut'>
                <input type={'checkbox'} id={id} checked={isChecked()} onChange={(e) => onToogleCheckbox(e.target.checked)} />
                <label>{label}</label>
            </div>
        </div>
    )

}